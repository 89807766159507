import { Container } from '@mui/material'
import React, { useEffect } from 'react'

type Props = {
  children: React.ReactNode,
  title?: string,
  showNSFW?: boolean,
}

// function MainBody(props: React.PropsWithChildren, title?: string) {
// function MainBody({ children, title }: {children: React.PropsWithChildren<Record<never, never>>, title?: string}) {
function MainBody(props: Props) {
  useEffect(() => {
    let indicator = "";
    if(props.showNSFW) {
      indicator = " [*]"
    }
    if(props.title && props.title?.toString().length > 0 && !props.title.toString().includes("undefined")) {
      document.title = props.title  + indicator + " | AstraBun Art Gallery" || "AstraBun Art Gallery";
    }
  }, [props.title, props.showNSFW]);
  return (
    <Container>
      {props.children}
    </Container>
  )
}

export default MainBody