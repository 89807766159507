import { Delete } from '@mui/icons-material';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, ListItem, ListItemButton, ListItemText } from '@mui/material';
import dayjs from 'dayjs';
import React, { useState } from 'react'
import UpdateEditor from './UpdateEditor';
import { CommissionDataRow } from '../interfaces';
import { collection, deleteDoc, doc } from 'firebase/firestore';
import { db } from '../firebase-config';

type Props = {
  update: any,
  commission: CommissionDataRow,
  runUpdateData: any, // func
}

function UpdatesListItem(props: Props) {
  const { update, commission, runUpdateData } = props;
  const [editorOpen, setEditorOpen] = useState<boolean>(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState<boolean>(false);
  const deleteUpdate = () => {
    setConfirmDeleteOpen(false);
    const commUpdatesCollectionRef = collection(db, "commissionHistoryItems");
    deleteDoc(doc(commUpdatesCollectionRef, update.id))
    runUpdateData()
  };
  return (
    <>
      <ListItem
        secondaryAction={
          <IconButton edge="end" aria-label='delete' onClick={() => { setConfirmDeleteOpen(true) }}>
            <Delete />
          </IconButton>
        }
      >
        <ListItemButton onClick={() => { setEditorOpen(true) }}>
          <ListItemText
            primary={dayjs(update.ts.seconds * 1000).format()}
            secondary={update.note}
          />
        </ListItemButton>
      </ListItem>
      <Dialog
        open={confirmDeleteOpen}
        onClose={() => {setConfirmDeleteOpen(false)}}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm update deletion?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you wish to remove the update?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setConfirmDeleteOpen(false)}}>Cancel</Button>
          <Button onClick={() => { deleteUpdate() }} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <UpdateEditor open={editorOpen} onClose={() => { setEditorOpen(false); }} existingUpdate={update} commission={commission} runUpdateData={runUpdateData} />
    </>
  )
}

export default UpdatesListItem