import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { CharacterColor } from "../interfaces";
import { SketchPicker } from 'react-color';

function EditColor({ open, setOpen, oldColor, handleEditColor }: {open: boolean, setOpen: Function, oldColor: CharacterColor, handleEditColor: Function}) {
  const [label, setLabel] = useState<string>(oldColor.label);
  const [hex, setHex] = useState<string>(oldColor.hex);
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveAndClose = () => {
    let newColor: CharacterColor = {
      label: label,
      hex: hex
    };
    handleEditColor(oldColor, newColor);
    handleClose();
  };

  useEffect(() => {
    setLabel(oldColor.label);
    setHex(oldColor.hex);
  }, [oldColor.label, oldColor.hex, open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Edit Color"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Edit Color
          </DialogContentText>
          <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <TextField
              required
              id="new-color-label"
              label="Color Label"
              placeholder='Body'
              defaultValue={oldColor.label}
              onChange={(event) => {setLabel(event.target.value)}}
            />
            {/* <TextField
              required
              id="new-color-hex"
              label="Hex"
              placeholder='#000000'
              defaultValue={oldColor.hex}
              onChange={(event) => {setHex(event.target.value)}}
            /> */}
            <SketchPicker
              color={ hex }
              onChangeComplete={ (c) => {setHex(c.hex)} }
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveAndClose} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EditColor