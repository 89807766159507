import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Autocomplete, TextField } from '@mui/material';
import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { db } from '../firebase-config';

function SelectArtistDialog({ open, setOpen, showNSFW, localArtists, setLocalArtists }: { open: boolean, setOpen: Function, showNSFW: boolean, localArtists: Array<any>, setLocalArtists: Function }) {
  const [selected, setSelected] = useState<any>(null);
  const [artists, setArtists] = useState<Array<any>>([]);
  const artistsCollectionRef = collection(db, "artists");

  const runUpdate = () => {
    const getArtists = async () => {
      if(showNSFW){
        const data = await getDocs(artistsCollectionRef);
        // console.log(data);
        setArtists(data.docs.map((doc) => ({ ...doc.data(), id: doc.id})));
      } else { // filter out docs where onlyShowIfNsfw is true
        const q = query(artistsCollectionRef, where("onlyShowIfNsfw", "==", false));
        const querySnapshot = await getDocs(q);
        setArtists(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id})));
      }
    };
    getArtists();
  };

  useEffect(() => {
    runUpdate();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClose = () => {
    setOpen(false);
  };
  const handleSaveAndClose = () => {
    let newLocalArtists = [...localArtists];
    newLocalArtists.push(`/artists/${selected.id}`)
    setLocalArtists(newLocalArtists);
    setSelected(null);
    handleClose();
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Select Artist to Add"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Select an artist from the database to add to this artwork
        </DialogContentText>   
        {/* {JSON.stringify(artists)}    */}
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={artists}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Artist" />}
          getOptionLabel={opt => opt.artistPrimaryName}
          value={selected}
          onChange={(event: any, newValue: any | null) => {
            setSelected(newValue);
          }}
        />
        {/* {JSON.stringify(selected)} */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSaveAndClose} autoFocus disabled={selected===null}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectArtistDialog