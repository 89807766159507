import { ListItem, ListItemText } from '@mui/material'
import { getDoc, doc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { db } from '../firebase-config';

function ArtworkArtistListItem({ idx, artistDocRef }: { idx: number, artistDocRef: any }) {
  const [artistData, setArtistData] = useState<any>("Loading...");

  useEffect(() => {
    try {
      getDoc(artistDocRef).then(res => {
        let artist: any = res.data();
        setArtistData(artist);
      });
    } catch {
      getDoc(doc(db, artistDocRef)).then(res => {
        let artist: any = res.data();
        setArtistData(artist);
      });
    }
  }, [artistDocRef, idx])

  return (
    <ListItem key={`artist-link-item-${idx}`} button component={"a"} href={artistData.artistPrimaryLink} target="_blank" rel="noreferrer">
      <ListItemText
        primary={artistData.artistPrimaryName}
      />
    </ListItem>
  )
}

export default ArtworkArtistListItem