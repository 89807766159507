import {
  Button,
  Chip,
  Container,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Fab,
  List,
  ListItem,
  ListItemText,
  Paper,
  TextField,
  Tooltip,
  Typography,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import FileUploadIcon from "@mui/icons-material/FileUpload";
import { Box } from "@mui/system";
import React, { useEffect, useState } from "react";
import DataTable, {
  TableColumn,
  ExpanderComponentProps,
} from "react-data-table-component";
import MainBody from "./MainBody";
import SortIcon from "@mui/icons-material/ArrowDownward";
import AddIcon from "@mui/icons-material/Add";

import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";

import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

import {
  getDocs,
  collection,
  deleteDoc,
  doc,
  setDoc,
  getDoc,
} from "firebase/firestore";
import { db, storage } from "../firebase-config";

import { v4 as uuidv4 } from "uuid";

import {
  ArtistDataRow,
  CharacterColor,
  CharacterDataRow,
  CharacterNSFWAnatomy,
  CharacterRefSheet,
} from "../interfaces";
import CreateCharacter from "./CreateCharacter";

import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Switch from "@mui/material/Switch";

import { Dayjs } from "dayjs";
import dayjs from "dayjs";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import RelativeTime from "dayjs/plugin/relativeTime";
import EditPronouns from "../components/EditPronouns";
import EditColors from "../components/EditColors";
import EditTraits from "../components/EditTraits";
import { getDownloadURL, ref, uploadBytes } from "firebase/storage";
import EditAnatomyNSFW from "../components/EditAnatomyNSFW";
dayjs.extend(RelativeTime);

const fabStyle = {
  position: "fixed",
  bottom: 16,
  right: 16,
};

const deleteCharacterHandler = async (characterId: string, cb: Function) => {
  const characterDoc = doc(db, "characters", characterId);
  await deleteDoc(characterDoc);
  cb();
};

const editCharacterHandler = async (data: CharacterDataRow, cb: Function) => {
  const characterDoc = doc(db, "characters", data.id);
  setDoc(characterDoc, data).then(() => {
    cb();
  });
};

const columns: TableColumn<any>[] = [
  {
    name: "Character",
    selector: (r) => r.characterName,
    sortable: true,
  },
];

interface CustomExpanderComponentProps
  extends ExpanderComponentProps<CharacterDataRow> {
  // currently, props that extend ExpanderComponentProps must be set to optional.
  isAuth?: boolean;
  runUpdate?: any; // doesn't like being Function
  characters?: Array<any>;
  setCharacters?: any; // doesn't like being Function
  showNSFW?: boolean;
}

const ExpandedComponent: React.FC<CustomExpanderComponentProps> = ({
  data,
  isAuth,
  characters,
  setCharacters,
  runUpdate,
  showNSFW,
}) => {
  const [removeConfirmOpen, setRemoveConfirmOpen] = useState<boolean>(false);

  const [refArtistData, setRefArtistData] = useState<any>("");
  const [altRefArtistData, setAltRefArtistData] = useState<any>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [thumbUrl, setThumbUrl] = useState<any>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [refUrl, setRefUrl] = useState<any>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [altRefUrl, setAltRefUrl] = useState<any>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [refArtist, setRefArtist] = useState<any>("");

  const [allArtists, setAllArtists] = useState<Array<any>>([]);
  const artistsCollectionRef = collection(db, "artists");

  const handleChangeArtistSelect = (event: SelectChangeEvent) => {
    setRefArtist(event.target.value);
  };

  useEffect(() => {
    const getArtists = async () => {
      const data = await getDocs(artistsCollectionRef);
      // console.log(data);
      setAllArtists(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getArtists();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const runUpdateArtistData = () => {
    if (data.characterRefSheet) {
      try {
        getDoc(data.characterRefSheet.artist).then((res) => {
          let artistData = res.data();
          setRefArtistData(artistData);
        });
      } catch (e) {
        getDoc(doc(db, data.characterRefSheet.artist)).then((res) => {
          let artistData = res.data();
          setRefArtistData(artistData);
        });
      }
    }
  };

  const runUpdateAltArtistData = () => {
    if (data.nsfwDetails && data.nsfwDetails.characterRefSheetNSFW) {
      try {
        getDoc(data.nsfwDetails.characterRefSheetNSFW.artist).then((res) => {
          let artistData = res.data();
          setAltRefArtistData(artistData);
        });
      } catch (e) {
        getDoc(doc(db, data.nsfwDetails.characterRefSheetNSFW.artist)).then((res) => {
          let artistData = res.data();
          setAltRefArtistData(artistData);
        });
      }
    }
  };

  useEffect(() => {
    runUpdateArtistData();
    runUpdateAltArtistData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const handleClickRemoveOpen = () => {
    setRemoveConfirmOpen(true);
  };

  const handleCloseRemove = () => {
    setRemoveConfirmOpen(false);
  };

  const handleCloseRemovePerformRemoval = (characterId: string) => {
    deleteCharacterHandler(characterId, runUpdate);
    handleCloseRemove();
  };

  const handleUpdatePublished = () => {
    let newCharacters: Array<CharacterDataRow> = [...(characters || [])];
    let idxToUpdate = newCharacters.indexOf(
      newCharacters.filter((i) => i.id === data.id)[0]
    );
    newCharacters[idxToUpdate].published = !localPublished;
    setCharacters(newCharacters);
    editCharacterHandler(data, runUpdate);
  };

  const handleClearThumb = () => {
    let newCharacters: Array<CharacterDataRow> = [...(characters || [])];
    let idxToUpdate = newCharacters.indexOf(
      newCharacters.filter((i) => i.id === data.id)[0]
    );
    newCharacters[idxToUpdate].characterThumb = "";
    setCharacters(newCharacters);
    editCharacterHandler(data, runUpdate);
  };

  const handleClearRef = () => {
    let newCharacters: Array<CharacterDataRow> = [...(characters || [])];
    let idxToUpdate = newCharacters.indexOf(
      newCharacters.filter((i) => i.id === data.id)[0]
    );
    newCharacters[idxToUpdate].characterRefSheet = "";
    setCharacters(newCharacters);
    editCharacterHandler(data, runUpdate);
  };

  const handleClearAltRef = () => {
    let newCharacters: Array<CharacterDataRow> = [...(characters || [])];
    let idxToUpdate = newCharacters.indexOf(
      newCharacters.filter((i) => i.id === data.id)[0]
    );
    if(newCharacters[idxToUpdate]){
      if(newCharacters[idxToUpdate].nsfwDetails){
        let newNsfwDetails = {...newCharacters[idxToUpdate].nsfwDetails};
        newNsfwDetails.characterRefSheetNSFW = ""
        newCharacters[idxToUpdate].nsfwDetails = newNsfwDetails;
      }
    }
    setCharacters(newCharacters);
    editCharacterHandler(data, runUpdate);
  };

  const handlePostUploadThumb = (res: string) => {
    let newCharacters: Array<CharacterDataRow> = [...(characters || [])];
    let idxToUpdate = newCharacters.indexOf(
      newCharacters.filter((i) => i.id === data.id)[0]
    );
    newCharacters[idxToUpdate].characterThumb = res;
    setCharacters(newCharacters);
    editCharacterHandler(data, runUpdate);
    setIsUploadClicked(false);
  };

  const handlePostUploadRef = (res: string, artist: any) => {
    let newRef: CharacterRefSheet = {
      image: res,
      artist: artist,
    };
    let newCharacters: Array<CharacterDataRow> = [...(characters || [])];
    let idxToUpdate = newCharacters.indexOf(
      newCharacters.filter((i) => i.id === data.id)[0]
    );
    newCharacters[idxToUpdate].characterRefSheet = newRef;
    setCharacters(newCharacters);
    editCharacterHandler(data, runUpdate);
    setIsUploadClicked(false);
  };

  const handlePostUploadAltRef = (res: string, artist: any) => {
    let newRef: CharacterRefSheet = {
      image: res,
      artist: artist,
    };
    let newCharacters: Array<CharacterDataRow> = [...(characters || [])];
    let idxToUpdate = newCharacters.indexOf(
      newCharacters.filter((i) => i.id === data.id)[0]
    );
    
    if(newCharacters[idxToUpdate].nsfwDetails){
      let newNsfwDetails = {...newCharacters[idxToUpdate].nsfwDetails};
      newNsfwDetails.characterRefSheetNSFW = newRef;
      newCharacters[idxToUpdate].nsfwDetails = newNsfwDetails;
    } else {
      newCharacters[idxToUpdate].nsfwDetails = {
        characterRefSheetNSFW: newRef
      }
    }
    setCharacters(newCharacters);
    editCharacterHandler(data, runUpdate);
    setIsUploadClicked(false);
  };

  const [selectedRefFile, setSelectedRefFile] = useState<any>();

  const [isRefFilePicked, setIsRefFilePicked] = useState<boolean>(false);

  const changeRefHandler = (event: any) => {
    setSelectedRefFile(event.target.files[0]);

    setIsRefFilePicked(true);
  };

  const handleRefSubmission = () => {
    const doUpload = () => {
      // disable upload button while upload happening
      setIsUploadClicked(true);
      let uuidprefix = uuidv4();
      let uploadObj = ref(
        storage,
        `refs/${uuidprefix}_${selectedRefFile.name}`
      );
      uploadBytes(uploadObj, selectedRefFile).then((snapshot) => {
        // console.log('Uploaded a blob or file!');
        getDownloadURL(uploadObj).then((res) => {
          setRefUrl(res);
          setUploadRefDialogOpen(false);
          handlePostUploadRef(res, `/artists/${refArtist}`);
        });
      });
    };
    doUpload();
  };

  const handleAltRefSubmission = () => {
    const doUpload = () => {
      // disable upload button while upload happening
      setIsUploadClicked(true);
      let uuidprefix = uuidv4();
      let uploadObj = ref(
        storage,
        `altrefs/${uuidprefix}_${selectedRefFile.name}`
      );
      uploadBytes(uploadObj, selectedRefFile).then((snapshot) => {
        // console.log('Uploaded a blob or file!');
        getDownloadURL(uploadObj).then((res) => {
          setAltRefUrl(res);
          setUploadAltRefDialogOpen(false);
          handlePostUploadAltRef(res, `/artists/${refArtist}`);
        });
      });
    };
    doUpload();
  };


  const [selectedThumbFile, setSelectedThumbFile] = useState<any>();

  const [isThumbFilePicked, setIsThumbFilePicked] = useState<boolean>(false);
  const [isUploadClicked, setIsUploadClicked] = useState<boolean>(false);

  const changeThumbHandler = (event: any) => {
    setSelectedThumbFile(event.target.files[0]);

    setIsThumbFilePicked(true);
  };

  const handleThumbSubmission = () => {
    const doUpload = () => {
      // selectedThumbFile
      // disable upload button while upload happening
      setIsUploadClicked(true);
      let uuidprefix = uuidv4();
      let uploadObj = ref(
        storage,
        `thumbs/${uuidprefix}_${selectedThumbFile.name}`
      );
      uploadBytes(uploadObj, selectedThumbFile).then((snapshot) => {
        // console.log('Uploaded a blob or file!');
        getDownloadURL(uploadObj).then((res) => {
          setThumbUrl(res);
          setUploadThumbDialogOpen(false);
          handlePostUploadThumb(res);
        });
      });
    };
    doUpload();
  };

  const charAge = dayjs(data.characterBirthdate).toNow(true);

  const [uploadThumbDialogOpen, setUploadThumbDialogOpen] =
    useState<boolean>(false);
  const [uploadRefDialogOpen, setUploadRefDialogOpen] =
    useState<boolean>(false);
  const [uploadAltRefDialogOpen, setUploadAltRefDialogOpen] =
    useState<boolean>(false);

  const [copyColorClicked, setCopyColorClicked] = useState<boolean>(false);

  const [editNameOpen, setEditNameOpen] = useState<boolean>(false);
  const [editNameValue, setEditNameValue] = useState<string>(
    data.characterName
  );

  const [editHeightOpen, setEditHeightOpen] = useState<boolean>(false);
  const [editHeightValue, setEditHeightValue] = useState<string>(
    data.characterPhysical?.height || ""
  );

  const [editWeightOpen, setEditWeightOpen] = useState<boolean>(false);
  const [editWeightValue, setEditWeightValue] = useState<string>(
    data.characterPhysical?.weight || ""
  );

  const [editBirthdayOpen, setEditBirthdayOpen] = useState<boolean>(false);
  const [editBirthdayValue, setEditBirthdayValue] = useState<Dayjs | null>(
    dayjs(data.characterBirthdate)
  );

  const [editPronounsOpen, setEditPronounsOpen] = useState<boolean>(false);
  const [editPronounsValue, setEditPronounsValue] = useState<Array<string>>(
    data.characterPronouns
  );

  const [editSpeciesOpen, setEditSpeciesOpen] = useState<boolean>(false);
  const [editSpeciesValue, setEditSpeciesValue] = useState<string>(
    data.characterSpecies
  );

  const [editOrientationOpen, setEditOrientationOpen] =
    useState<boolean>(false);
  const [editOrientationValue, setEditOrientationValue] = useState<string>(
    data.characterOrientation
  );

  const [editColorsOpen, setEditColorsOpen] = useState<boolean>(false);
  const [editColorsValue, setEditColorsValue] = useState<Array<CharacterColor>>(
    data.characterColors
  );

  const [editLikesOpen, setEditLikesOpen] = useState<boolean>(false);
  const [editLikesValue, setEditLikesValue] = useState<Array<string>>(
    data.characterDetails?.likes || []
  );

  const [editDislikesOpen, setEditDislikesOpen] = useState<boolean>(false);
  const [editDislikesValue, setEditDislikesValue] = useState<Array<string>>(
    data.characterDetails?.dislikes || []
  );

  const [editTraitsOpen, setEditTraitsOpen] = useState<boolean>(false);
  const [editTraitsValue, setEditTraitsValue] = useState<Array<string>>(
    data.characterDetails?.traits || []
  );

  const [editRelationshipsOpen, setEditRelationshipsOpen] =
    useState<boolean>(false);
  const [editRelationshipsValue, setEditRelationshipsValue] = useState<
    Array<string>
  >(data.characterDetails?.relationships || []);

  const [localPublished, setLocalPublished] = useState<boolean>(data.published);

  const [editSpicyLikesOpen, setEditSpicyLikesOpen] = useState<boolean>(false);
  const [editSpicyLikesValue, setEditSpicyLikesValue] = useState<Array<string>>(
    data.nsfwDetails?.spicyLikes || []
  );

  const [editAnatomyOpen, setEditAnatomyOpen] = useState<boolean>(false);
  const [editAnatomyValue, setEditAnatomyValue] =
    useState<CharacterNSFWAnatomy>(data.nsfwDetails?.anatomy || {});

  const [editNotesOpen, setEditNotesOpen] = useState<boolean>(false);
  const [editNotesValue, setEditNotesValue] = useState<Array<string>>(
    data.characterDetails?.notes || []
  );

  return (
    <div>
      <Container>
        <EditAnatomyNSFW
          open={editAnatomyOpen}
          setOpen={setEditAnatomyOpen}
          anatomy={editAnatomyValue}
          updateAnatomy={setEditAnatomyValue}
          characters={characters}
          setCharacters={setCharacters}
          editCharacterHandler={editCharacterHandler}
          data={data}
          runUpdate={runUpdate}
        />
        <Dialog
          open={uploadRefDialogOpen}
          onClose={() => {}} // only perform close if button in dialog clicked
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogTitle id="dialog-title">Upload Ref</DialogTitle>
          <DialogContent>
            <DialogContentText id="dialog-description">
              Upload a ref image for the character listing page.
            </DialogContentText>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <input type="file" name="file" onChange={changeRefHandler} />
              {/* {JSON.stringify(allArtists)} */}
              <FormControl sx={{ m: 1, minWidth: 80 }}>
                <InputLabel id="select-autowidth-label">Artist</InputLabel>
                <Select
                  labelId="select-autowidth-label"
                  id="select-autowidth"
                  value={refArtist}
                  onChange={handleChangeArtistSelect}
                  autoWidth
                  label="Artist"
                >
                  {/* <MenuItem value="">
                    <em>None</em>
                  </MenuItem>
                  <MenuItem value={10}>Twenty</MenuItem>
                  <MenuItem value={21}>Twenty one</MenuItem>
                  <MenuItem value={22}>Twenty one and a half</MenuItem> */}
                  {/* {allArtists.map((a: ArtistDataRow) => {
                    return (
                      <MenuItem value={a.id} key={a.artistPrimaryName}>{a.artistPrimaryName}</MenuItem>
                    )
                  })} */}
                  {allArtists &&
                    allArtists.map((a: ArtistDataRow) => {
                      return (
                        <MenuItem value={a.id} key={a.artistPrimaryName}>
                          {a.artistPrimaryName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setUploadRefDialogOpen(false);
                setRefArtist("");
              }}
            >
              Cancel
            </Button>
            {/* <Button disabled={!isThumbFilePicked} onClick={handleThumbSubmission}>Upload</Button> */}
            <LoadingButton
              loading={isUploadClicked}
              disabled={!isRefFilePicked || refArtist === ""}
              loadingPosition="start"
              startIcon={<FileUploadIcon />}
              // variant="outlined"
              onClick={handleRefSubmission}
            >
              Upload
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={uploadAltRefDialogOpen}
          onClose={() => {}} // only perform close if button in dialog clicked
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogTitle id="dialog-title">Upload Alternative (NSFW) Ref</DialogTitle>
          <DialogContent>
            <DialogContentText id="dialog-description">
              Upload a NSFW ref image for the character listing page.
            </DialogContentText>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
              }}
            >
              <input type="file" name="file" onChange={changeRefHandler} />
              {/* {JSON.stringify(allArtists)} */}
              <FormControl sx={{ m: 1, minWidth: 80 }}>
                <InputLabel id="select-autowidth-label">Artist</InputLabel>
                <Select
                  labelId="select-autowidth-label"
                  id="select-autowidth"
                  value={refArtist}
                  onChange={handleChangeArtistSelect}
                  autoWidth
                  label="Artist"
                >
                  {allArtists &&
                    allArtists.map((a: ArtistDataRow) => {
                      return (
                        <MenuItem value={a.id} key={a.artistPrimaryName}>
                          {a.artistPrimaryName}
                        </MenuItem>
                      );
                    })}
                </Select>
              </FormControl>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setUploadAltRefDialogOpen(false);
                setRefArtist("");
              }}
            >
              Cancel
            </Button>
            <LoadingButton
              loading={isUploadClicked}
              disabled={!isRefFilePicked || refArtist === ""}
              loadingPosition="start"
              startIcon={<FileUploadIcon />}
              // variant="outlined"
              onClick={handleAltRefSubmission}
            >
              Upload
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={uploadThumbDialogOpen}
          onClose={() => {}} // only perform close if button in dialog clicked
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogTitle id="dialog-title">Upload Thumbnail</DialogTitle>
          <DialogContent>
            <DialogContentText id="dialog-description">
              Upload a thumbnail image for the character listing page.
            </DialogContentText>
            <input type="file" name="file" onChange={changeThumbHandler} />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setUploadThumbDialogOpen(false);
              }}
            >
              Cancel
            </Button>
            {/* <Button disabled={!isThumbFilePicked} onClick={handleThumbSubmission}>Upload</Button> */}
            <LoadingButton
              loading={isUploadClicked}
              disabled={!isThumbFilePicked}
              loadingPosition="start"
              startIcon={<FileUploadIcon />}
              // variant="outlined"
              onClick={handleThumbSubmission}
            >
              Upload
            </LoadingButton>
          </DialogActions>
        </Dialog>
        <Dialog
          open={editNameOpen}
          onClose={() => {
            setEditNameOpen(false);
          }}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogTitle id="dialog-title">{"Edit Name"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="dialog-description">
              {"Edit character name."}
            </DialogContentText>
            <TextField
              required
              id="character-name"
              label="Character Name"
              defaultValue={data.characterName}
              placeholder="Astra"
              onChange={(event) => {
                setEditNameValue(event.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setEditNameOpen(false); // close
                setEditNameValue(data.characterName); // reset
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setEditNameOpen(false); // close
                let newCharacters: Array<CharacterDataRow> = [
                  ...(characters || []),
                ];
                let idxToUpdate = newCharacters.indexOf(
                  newCharacters.filter((i) => i.id === data.id)[0]
                );
                newCharacters[idxToUpdate].characterName = editNameValue;
                setCharacters(newCharacters);
                editCharacterHandler(data, runUpdate);
              }}
              autoFocus
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={editHeightOpen}
          onClose={() => {
            setEditHeightOpen(false);
          }}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogTitle id="dialog-title">{"Edit Height"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="dialog-description">
              {"Edit character height (use format f'i\" please!)"}
            </DialogContentText>
            <TextField
              required
              id="character-height"
              label="Character Height"
              defaultValue={data.characterPhysical?.height}
              placeholder={"5'9\""}
              onChange={(event) => {
                setEditHeightValue(event.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setEditHeightOpen(false); // close
                setEditHeightValue(data.characterPhysical?.height || ""); // reset
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setEditHeightOpen(false); // close
                let newCharacters: Array<CharacterDataRow> = [
                  ...(characters || []),
                ];
                let idxToUpdate = newCharacters.indexOf(
                  newCharacters.filter((i) => i.id === data.id)[0]
                );
                let phys = newCharacters[idxToUpdate].characterPhysical || {};
                phys.height = editHeightValue;
                newCharacters[idxToUpdate].characterPhysical = phys;
                setCharacters(newCharacters);
                editCharacterHandler(data, runUpdate);
              }}
              autoFocus
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={editWeightOpen}
          onClose={() => {
            setEditWeightOpen(false);
          }}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogTitle id="dialog-title">{"Edit Weight"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="dialog-description">
              {"Edit character weight (use format `w lbs` please!)"}/{" "}
            </DialogContentText>
            <TextField
              required
              id="character-weight"
              label="Character Weight"
              defaultValue={data.characterPhysical?.weight}
              placeholder={"130 lbs"}
              onChange={(event) => {
                setEditWeightValue(event.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setEditWeightOpen(false); // close
                setEditWeightValue(data.characterPhysical?.weight || ""); // reset
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setEditWeightOpen(false); // close
                let newCharacters: Array<CharacterDataRow> = [
                  ...(characters || []),
                ];
                let idxToUpdate = newCharacters.indexOf(
                  newCharacters.filter((i) => i.id === data.id)[0]
                );
                let phys = newCharacters[idxToUpdate].characterPhysical || {};
                phys.weight = editWeightValue;
                newCharacters[idxToUpdate].characterPhysical = phys;
                setCharacters(newCharacters);
                editCharacterHandler(data, runUpdate);
              }}
              autoFocus
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={editBirthdayOpen}
          onClose={() => {
            setEditBirthdayOpen(false);
          }}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogTitle id="dialog-title">{"Edit Birthday"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="dialog-description">
              {"Edit character birthday."}
            </DialogContentText>
            <LocalizationProvider dateAdapter={AdapterDayjs}>
              <DatePicker
                label="Character Birthday"
                defaultCalendarMonth={editBirthdayValue?.month || dayjs().month}
                value={editBirthdayValue}
                onChange={(newValue) => {
                  setEditBirthdayValue(dayjs(newValue?.toString() || ""));
                }}
                renderInput={(params) => <TextField {...params} />}
              />
            </LocalizationProvider>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setEditBirthdayOpen(false); // close
                setEditBirthdayValue(dayjs(data.characterBirthdate)); // reset
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setEditBirthdayOpen(false); // close
                let newCharacters: Array<CharacterDataRow> = [
                  ...(characters || []),
                ];
                let idxToUpdate = newCharacters.indexOf(
                  newCharacters.filter((i) => i.id === data.id)[0]
                );
                newCharacters[idxToUpdate].characterBirthdate =
                  editBirthdayValue
                    ?.startOf("day")
                    .toISOString()
                    .split("T")[0] || "";
                setCharacters(newCharacters);
                editCharacterHandler(data, runUpdate);
              }}
              autoFocus
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <EditPronouns
          open={editPronounsOpen}
          setOpen={setEditPronounsOpen}
          pronouns={editPronounsValue}
          updatePronouns={setEditPronounsValue}
          characters={characters}
          setCharacters={setCharacters}
          editCharacterHandler={editCharacterHandler}
          data={data}
          runUpdate={runUpdate}
        />

        <Dialog
          open={editSpeciesOpen}
          onClose={() => {
            setEditSpeciesOpen(false);
          }}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogTitle id="dialog-title">{"Edit Species"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="dialog-description">
              {"Edit character species."}
            </DialogContentText>
            <TextField
              required
              id="character-species"
              label="Character Species"
              defaultValue={data.characterSpecies}
              placeholder="Bun"
              onChange={(event) => {
                setEditSpeciesValue(event.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setEditSpeciesOpen(false); // close
                setEditSpeciesValue(data.characterSpecies); // reset
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setEditSpeciesOpen(false); // close
                let newCharacters: Array<CharacterDataRow> = [
                  ...(characters || []),
                ];
                let idxToUpdate = newCharacters.indexOf(
                  newCharacters.filter((i) => i.id === data.id)[0]
                );
                newCharacters[idxToUpdate].characterSpecies = editSpeciesValue;
                setCharacters(newCharacters);
                editCharacterHandler(data, runUpdate);
              }}
              autoFocus
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <Dialog
          open={editOrientationOpen}
          onClose={() => {
            setEditOrientationOpen(false);
          }}
          aria-labelledby="dialog-title"
          aria-describedby="dialog-description"
        >
          <DialogTitle id="dialog-title">{"Edit Orientation"}</DialogTitle>
          <DialogContent>
            <DialogContentText id="dialog-description">
              {"Edit character orientation."}
            </DialogContentText>
            <TextField
              required
              id="character-orientation"
              label="Character Orientation"
              defaultValue={data.characterOrientation}
              placeholder="Bun"
              onChange={(event) => {
                setEditOrientationValue(event.target.value);
              }}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setEditOrientationOpen(false); // close
                setEditOrientationValue(data.characterOrientation); // reset
              }}
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                setEditOrientationOpen(false); // close
                let newCharacters: Array<CharacterDataRow> = [
                  ...(characters || []),
                ];
                let idxToUpdate = newCharacters.indexOf(
                  newCharacters.filter((i) => i.id === data.id)[0]
                );
                newCharacters[idxToUpdate].characterOrientation =
                  editOrientationValue;
                setCharacters(newCharacters);
                editCharacterHandler(data, runUpdate);
              }}
              autoFocus
            >
              Save
            </Button>
          </DialogActions>
        </Dialog>

        <EditColors
          open={editColorsOpen}
          setOpen={setEditColorsOpen}
          colors={editColorsValue}
          updateColors={setEditColorsValue}
          characters={characters}
          setCharacters={setCharacters}
          editCharacterHandler={editCharacterHandler}
          data={data}
          runUpdate={runUpdate}
        />

        {/* Dialog to edit likes */}
        <EditTraits
          open={editLikesOpen}
          setOpen={setEditLikesOpen}
          traits={editLikesValue}
          updateTraits={setEditLikesValue}
          characters={characters}
          setCharacters={setCharacters}
          editCharacterHandler={editCharacterHandler}
          data={data}
          runUpdate={runUpdate}
          traitKey={"likes"}
          traitLabel={"Likes"}
        />

        {/* Dialog to edit dislikes */}
        <EditTraits
          open={editDislikesOpen}
          setOpen={setEditDislikesOpen}
          traits={editDislikesValue}
          updateTraits={setEditDislikesValue}
          characters={characters}
          setCharacters={setCharacters}
          editCharacterHandler={editCharacterHandler}
          data={data}
          runUpdate={runUpdate}
          traitKey={"dislikes"}
          traitLabel={"Dislikes"}
        />

        {/* Dialog to edit traits */}
        <EditTraits
          open={editTraitsOpen}
          setOpen={setEditTraitsOpen}
          traits={editTraitsValue}
          updateTraits={setEditTraitsValue}
          characters={characters}
          setCharacters={setCharacters}
          editCharacterHandler={editCharacterHandler}
          data={data}
          runUpdate={runUpdate}
          traitKey={"traits"}
          traitLabel={"Traits"}
        />

        {/* Dialog to edit relationships */}
        <EditTraits
          open={editRelationshipsOpen}
          setOpen={setEditRelationshipsOpen}
          traits={editRelationshipsValue}
          updateTraits={setEditRelationshipsValue}
          characters={characters}
          setCharacters={setCharacters}
          editCharacterHandler={editCharacterHandler}
          data={data}
          runUpdate={runUpdate}
          traitKey={"relationships"}
          traitLabel={"Relationships"}
        />

        {/* Dialog to edit spicyLikes */}
        <EditTraits
          nsfw={true}
          open={editSpicyLikesOpen}
          setOpen={setEditSpicyLikesOpen}
          traits={editSpicyLikesValue}
          updateTraits={setEditSpicyLikesValue}
          characters={characters}
          setCharacters={setCharacters}
          editCharacterHandler={editCharacterHandler}
          data={data}
          runUpdate={runUpdate}
          traitKey={"spicyLikes"}
          traitLabel={"Notable Kinks/Fetishes"}
        />

        {/* Dialog to edit relationships */}
        <EditTraits
          open={editNotesOpen}
          setOpen={setEditNotesOpen}
          traits={editNotesValue}
          updateTraits={setEditNotesValue}
          characters={characters}
          setCharacters={setCharacters}
          editCharacterHandler={editCharacterHandler}
          data={data}
          runUpdate={runUpdate}
          traitKey={"notes"}
          traitLabel={"Notes"}
        />

        {/* ================================================================= */}

        <Box>
          <TableContainer component={Paper}>
            <Table
              // sx={{ minWidth: 650 }}
              aria-label="character-data"
            >
              <TableHead>
                <TableRow>
                  <TableCell>Attribute</TableCell>
                  <TableCell
                  // align="right"
                  >
                    Value {isAuth && "(click cell to edit)"}
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {"Name"}
                  </TableCell>
                  <TableCell
                  // align="right"
                  >
                    {isAuth ? (
                      <Box
                        onClick={() => {
                          setEditNameOpen(true);
                        }}
                      >
                        {data.characterName}
                      </Box>
                    ) : (
                      <Box>{data.characterName}</Box>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {"Age"}
                  </TableCell>
                  <TableCell
                  // align="right"
                  >
                    {isAuth ? (
                      <Box
                        onClick={() => {
                          setEditBirthdayOpen(true);
                        }}
                      >
                        {charAge}
                      </Box>
                    ) : (
                      <Box>{charAge}</Box>
                    )}
                  </TableCell>
                </TableRow>
                {data.characterPhysical && (
                  <>
                    {data.characterPhysical.height &&
                    data.characterPhysical.height !== "" ? (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {"Height"}
                        </TableCell>
                        <TableCell
                        // align="right"
                        >
                          {isAuth ? (
                            <Box
                              onClick={() => {
                                setEditHeightOpen(true);
                              }}
                            >
                              {data.characterPhysical.height}
                            </Box>
                          ) : (
                            <Box>{data.characterPhysical.height}</Box>
                          )}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {"Height"}
                        </TableCell>
                        <TableCell
                        // align="right"
                        >
                          {isAuth ? (
                            <Button
                              onClick={() => {
                                setEditHeightOpen(true);
                              }}
                            >
                              Add Height Info
                            </Button>
                          ) : (
                            <Box>Unknown</Box>
                          )}
                        </TableCell>
                      </TableRow>
                    )}

                    {data.characterPhysical.weight &&
                    data.characterPhysical.weight !== "" ? (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {"Weight"}
                        </TableCell>
                        <TableCell
                        // align="right"
                        >
                          {isAuth ? (
                            <Box
                              onClick={() => {
                                setEditWeightOpen(true);
                              }}
                            >
                              {data.characterPhysical.weight}
                            </Box>
                          ) : (
                            <Box>{data.characterPhysical.weight}</Box>
                          )}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {"Weight"}
                        </TableCell>
                        <TableCell
                        // align="right"
                        >
                          {isAuth ? (
                            <Button
                              onClick={() => {
                                setEditWeightOpen(true);
                              }}
                            >
                              Add Weight Info
                            </Button>
                          ) : (
                            <Box>Unknown</Box>
                          )}
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {"Pronouns"}
                  </TableCell>
                  <TableCell
                  // align="right"
                  >
                    {isAuth ? (
                      <Box>
                        {data.characterPronouns.length > 0 ? (
                          <Box>
                            {/* has pronouns, display and handle editing */}
                            <Box
                              onClick={() => {
                                setEditPronounsOpen(true);
                              }}
                            >
                              {data.characterPronouns.join("/")}
                            </Box>
                          </Box>
                        ) : (
                          <Box>
                            {/* pronouns list is empty for some reason, display big add button */}
                            <Button
                              onClick={() => {
                                setEditPronounsOpen(true);
                              }}
                            >
                              Add Pronouns
                            </Button>
                          </Box>
                        )}
                      </Box>
                    ) : (
                      <Box>{data.characterPronouns.join("/")}</Box>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {"Species"}
                  </TableCell>
                  <TableCell
                  // align="right"
                  >
                    {/* {data.characterSpecies} */}
                    {isAuth ? (
                      <Box
                        onClick={() => {
                          setEditSpeciesOpen(true);
                        }}
                      >
                        {data.characterSpecies}
                      </Box>
                    ) : (
                      <Box>{data.characterSpecies}</Box>
                    )}
                  </TableCell>
                </TableRow>
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {"Orientation"}
                  </TableCell>
                  <TableCell
                  // align="right"
                  >
                    {/* {data.characterOrientation} */}
                    {isAuth ? (
                      <Box
                        onClick={() => {
                          setEditOrientationOpen(true);
                        }}
                      >
                        {data.characterOrientation}
                      </Box>
                    ) : (
                      <Box>{data.characterOrientation}</Box>
                    )}
                  </TableCell>
                </TableRow>
                {data.characterPrideFlags && (
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {"Pride"}
                    </TableCell>
                    <TableCell
                    // align="right"
                    >
                      {data.characterPrideFlags ? (
                        <>
                          {data.characterPrideFlags.map((f, idx) => (
                            <Chip key={`flag-${idx}`} label="insertflaghere" />
                          ))}
                        </>
                      ) : (
                        ""
                      )}
                    </TableCell>
                  </TableRow>
                )}
                <TableRow
                  sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                >
                  <TableCell component="th" scope="row">
                    {"Colors"}
                  </TableCell>
                  <TableCell
                  // align="right"
                  >
                    {data.characterColors.map((c, idx) => {
                      let defaultChipLabel = `Click to copy ${c.label} (${c.hex})`;
                      const handleCopyClick = () => {
                        setCopyColorClicked(true);
                      };
                      const handleMouseLeave = () => {
                        setCopyColorClicked(false);
                      };
                      return (
                        <Tooltip
                          key={`color-chip-${idx}`}
                          title={
                            copyColorClicked ? "Copied!" : defaultChipLabel
                          }
                        >
                          <Chip
                            label={c.label}
                            sx={{ backgroundColor: c.hex }}
                            id={c.hex}
                            onClick={() => {
                              navigator.clipboard.writeText(c.hex);
                              handleCopyClick();
                            }}
                            onMouseLeave={handleMouseLeave}
                          />
                        </Tooltip>
                      );
                    })}
                    <br />
                    {isAuth && (
                      <Button
                        onClick={() => {
                          setEditColorsOpen(true);
                        }}
                      >
                        Edit Colors
                      </Button>
                    )}
                  </TableCell>
                </TableRow>
                {data.characterDetails && (
                  <>
                    {data.characterDetails.likes ? (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {"Likes"}
                        </TableCell>
                        <TableCell
                        // align="right"
                        >
                          {data.characterDetails.likes ? (
                            <Box>
                              {isAuth ? (
                                <Box>
                                  {data.characterDetails.likes.length > 0 ? (
                                    <Box>
                                      {/* has likes, display and handle editing */}
                                      <List
                                        onClick={() => {
                                          setEditLikesOpen(true);
                                        }}
                                      >
                                        {data.characterDetails.likes.map(
                                          (f, idx) => (
                                            <ListItem key={`like-${idx}`}>
                                              <ListItemText>{f}</ListItemText>
                                            </ListItem>
                                          )
                                        )}
                                      </List>
                                    </Box>
                                  ) : (
                                    <Box>
                                      {/* likes list is empty for some reason, display big add button */}
                                      <Button
                                        onClick={() => {
                                          setEditLikesOpen(true);
                                        }}
                                      >
                                        Add Likes
                                      </Button>
                                    </Box>
                                  )}
                                </Box>
                              ) : (
                                <Box>
                                  <List>
                                    {data.characterDetails.likes.map(
                                      (f, idx) => (
                                        <ListItem key={`like-${idx}`}>
                                          <ListItemText>{f}</ListItemText>
                                        </ListItem>
                                      )
                                    )}
                                  </List>
                                </Box>
                              )}
                            </Box>
                          ) : (
                            ""
                          )}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {"Likes"}
                        </TableCell>
                        <TableCell
                        // align="right"
                        >
                          <Button
                            onClick={() => {
                              setEditLikesOpen(true);
                            }}
                          >
                            Add Likes
                          </Button>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.characterDetails.dislikes ? (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {"Dislikes"}
                        </TableCell>
                        <TableCell
                        // align="right"
                        >
                          {data.characterDetails.dislikes ? (
                            <Box>
                              {isAuth ? (
                                <Box>
                                  {data.characterDetails.dislikes.length > 0 ? (
                                    <Box>
                                      {/* has likes, display and handle editing */}
                                      <List
                                        onClick={() => {
                                          setEditDislikesOpen(true);
                                        }}
                                      >
                                        {data.characterDetails.dislikes.map(
                                          (f, idx) => (
                                            <ListItem key={`dislike-${idx}`}>
                                              <ListItemText>{f}</ListItemText>
                                            </ListItem>
                                          )
                                        )}
                                      </List>
                                    </Box>
                                  ) : (
                                    <Box>
                                      {/* likes list is empty for some reason, display big add button */}
                                      <Button
                                        onClick={() => {
                                          setEditDislikesOpen(true);
                                        }}
                                      >
                                        Add Dislikes
                                      </Button>
                                    </Box>
                                  )}
                                </Box>
                              ) : (
                                <Box>
                                  <List>
                                    {data.characterDetails.dislikes.map(
                                      (f, idx) => (
                                        <ListItem key={`dislike-${idx}`}>
                                          <ListItemText>{f}</ListItemText>
                                        </ListItem>
                                      )
                                    )}
                                  </List>
                                </Box>
                              )}
                            </Box>
                          ) : (
                            ""
                          )}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {"Dislikes"}
                        </TableCell>
                        <TableCell
                        // align="right"
                        >
                          <Button
                            onClick={() => {
                              setEditDislikesOpen(true);
                            }}
                          >
                            Add Dislikes
                          </Button>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.characterDetails.traits ? (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {"Traits"}
                        </TableCell>
                        <TableCell
                        // align="right"
                        >
                          {data.characterDetails.traits ? (
                            <Box>
                              {isAuth ? (
                                <Box>
                                  {data.characterDetails.traits.length > 0 ? (
                                    <Box>
                                      {/* has likes, display and handle editing */}
                                      <List
                                        onClick={() => {
                                          setEditTraitsOpen(true);
                                        }}
                                      >
                                        {data.characterDetails.traits.map(
                                          (f, idx) => (
                                            <ListItem key={`trait-${idx}`}>
                                              <ListItemText>{f}</ListItemText>
                                            </ListItem>
                                          )
                                        )}
                                      </List>
                                    </Box>
                                  ) : (
                                    <Box>
                                      {/* likes list is empty for some reason, display big add button */}
                                      <Button
                                        onClick={() => {
                                          setEditTraitsOpen(true);
                                        }}
                                      >
                                        Add Traits
                                      </Button>
                                    </Box>
                                  )}
                                </Box>
                              ) : (
                                <Box>
                                  <List>
                                    {data.characterDetails.traits.map(
                                      (f, idx) => (
                                        <ListItem key={`trait-${idx}`}>
                                          <ListItemText>{f}</ListItemText>
                                        </ListItem>
                                      )
                                    )}
                                  </List>
                                </Box>
                              )}
                            </Box>
                          ) : (
                            ""
                          )}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {"Traits"}
                        </TableCell>
                        <TableCell
                        // align="right"
                        >
                          <Button
                            onClick={() => {
                              setEditTraitsOpen(true);
                            }}
                          >
                            Add Traits
                          </Button>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.characterDetails.relationships ? (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {"Relationships"}
                        </TableCell>
                        <TableCell
                        // align="right"
                        >
                          {data.characterDetails.relationships ? (
                            <Box>
                              {isAuth ? (
                                <Box>
                                  {data.characterDetails.relationships.length >
                                  0 ? (
                                    <Box>
                                      {/* has likes, display and handle editing */}
                                      <List
                                        onClick={() => {
                                          setEditRelationshipsOpen(true);
                                        }}
                                      >
                                        {data.characterDetails.relationships.map(
                                          (f, idx) => (
                                            <ListItem
                                              key={`relationship-${idx}`}
                                            >
                                              <ListItemText>{f}</ListItemText>
                                            </ListItem>
                                          )
                                        )}
                                      </List>
                                    </Box>
                                  ) : (
                                    <Box>
                                      {/* likes list is empty for some reason, display big add button */}
                                      <Button
                                        onClick={() => {
                                          setEditRelationshipsOpen(true);
                                        }}
                                      >
                                        Add Relationship
                                      </Button>
                                    </Box>
                                  )}
                                </Box>
                              ) : (
                                <Box>
                                  <List>
                                    {data.characterDetails.relationships.map(
                                      (f, idx) => (
                                        <ListItem key={`relationship-${idx}`}>
                                          <ListItemText>{f}</ListItemText>
                                        </ListItem>
                                      )
                                    )}
                                  </List>
                                </Box>
                              )}
                            </Box>
                          ) : (
                            ""
                          )}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {"Relationships"}
                        </TableCell>
                        <TableCell
                        // align="right"
                        >
                          <Button
                            onClick={() => {
                              setEditRelationshipsOpen(true);
                            }}
                          >
                            Add Relationship
                          </Button>
                        </TableCell>
                      </TableRow>
                    )}

                    {data.characterDetails.notes ? (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {"Notes"}
                        </TableCell>
                        <TableCell
                        // align="right"
                        >
                          {data.characterDetails.notes ? (
                            <Box>
                              {isAuth ? (
                                <Box>
                                  {data.characterDetails.notes.length > 0 ? (
                                    <Box>
                                      {/* has likes, display and handle editing */}
                                      <List
                                        onClick={() => {
                                          setEditNotesOpen(true);
                                        }}
                                      >
                                        {data.characterDetails.notes.map(
                                          (f, idx) => (
                                            <ListItem key={`note-${idx}`}>
                                              <ListItemText>{f}</ListItemText>
                                            </ListItem>
                                          )
                                        )}
                                      </List>
                                    </Box>
                                  ) : (
                                    <Box>
                                      {/* likes list is empty for some reason, display big add button */}
                                      <Button
                                        onClick={() => {
                                          setEditNotesOpen(true);
                                        }}
                                      >
                                        Add Note
                                      </Button>
                                    </Box>
                                  )}
                                </Box>
                              ) : (
                                <Box>
                                  <List>
                                    {data.characterDetails.notes.map(
                                      (f, idx) => (
                                        <ListItem key={`note-${idx}`}>
                                          <ListItemText>{f}</ListItemText>
                                        </ListItem>
                                      )
                                    )}
                                  </List>
                                </Box>
                              )}
                            </Box>
                          ) : (
                            ""
                          )}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {"Notes"}
                        </TableCell>
                        <TableCell
                        // align="right"
                        >
                          <Button
                            onClick={() => {
                              setEditNotesOpen(true);
                            }}
                          >
                            Add Note
                          </Button>
                        </TableCell>
                      </TableRow>
                    )}
                  </>
                )}
                {showNSFW && (
                  <>
                    {data.nsfwDetails && data.nsfwDetails.spicyLikes ? (
                      <TableRow
                        sx={{
                          "&:last-child td, &:last-child th": { border: 0 },
                        }}
                      >
                        <TableCell component="th" scope="row">
                          {"Notable Kinks/Fetishes"}
                        </TableCell>
                        <TableCell
                        // align="right"
                        >
                          {data.nsfwDetails.spicyLikes ? (
                            <Box>
                              {isAuth ? (
                                <Box>
                                  {data.nsfwDetails.spicyLikes.length > 0 ? (
                                    <Box>
                                      {/* has likes, display and handle editing */}
                                      <List
                                        onClick={() => {
                                          setEditSpicyLikesOpen(true);
                                        }}
                                      >
                                        {data.nsfwDetails.spicyLikes.map(
                                          (f, idx) => (
                                            <ListItem key={`spicylike-${idx}`}>
                                              <ListItemText>{f}</ListItemText>
                                            </ListItem>
                                          )
                                        )}
                                      </List>
                                    </Box>
                                  ) : (
                                    <Box>
                                      {/* likes list is empty for some reason, display big add button */}
                                      <Button
                                        onClick={() => {
                                          setEditSpicyLikesOpen(true);
                                        }}
                                      >
                                        Add Kink/Fetish
                                      </Button>
                                    </Box>
                                  )}
                                </Box>
                              ) : (
                                <Box>
                                  <List>
                                    {data.nsfwDetails.spicyLikes.map(
                                      (f, idx) => (
                                        <ListItem key={`spicylike-${idx}`}>
                                          <ListItemText>{f}</ListItemText>
                                        </ListItem>
                                      )
                                    )}
                                  </List>
                                </Box>
                              )}
                            </Box>
                          ) : (
                            ""
                          )}
                        </TableCell>
                      </TableRow>
                    ) : (
                      <TableRow>
                        <TableCell component="th" scope="row">
                          {"Notable Kinks/Fetishes"}
                        </TableCell>
                        <TableCell
                        // align="right"
                        >
                          <Button
                            onClick={() => {
                              setEditSpicyLikesOpen(true);
                            }}
                          >
                            Add Kink/Fetish
                          </Button>
                        </TableCell>
                      </TableRow>
                    )}
                    {data.nsfwDetails && (
                      <>
                        <TableRow
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          <TableCell component="th" scope="row">
                            {"Anatomy (NSFW)"}
                          </TableCell>
                          <TableCell
                          // align="right"
                          >
                            <Box
                              sx={{
                                display: "flex",
                                flexDirection: "column",
                              }}
                            >
                              {data.nsfwDetails.anatomy ? (
                                <Box>
                                  {data.nsfwDetails.anatomy.penis && (
                                    <Box>
                                      <Typography>
                                        {data.nsfwDetails.anatomy.penis.length}{" "}
                                        inch{" "}
                                        {data.nsfwDetails.anatomy.penis.intact
                                          ? "intact (uncircumcised)"
                                          : "cut (circumcised)"}{" "}
                                        {data.nsfwDetails.anatomy.penis.species}{" "}
                                        penis
                                      </Typography>
                                    </Box>
                                  )}
                                  {data.nsfwDetails.anatomy.balls && (
                                    <Box>
                                      <Typography>
                                        Has Balls
                                        {data.nsfwDetails.anatomy.balls
                                          .description.length > 0
                                          ? `: ${data.nsfwDetails.anatomy.balls.description}`
                                          : ""}
                                      </Typography>
                                    </Box>
                                  )}
                                  {data.nsfwDetails.anatomy.vagina && (
                                    <Box>
                                      <Typography>
                                        {/* // update anatomical language for display; db and interface migration later */}
                                        {/* Has Vagina */}
                                        Has Vulva
                                        {data.nsfwDetails.anatomy.vagina
                                          .description.length > 0
                                          ? `: ${data.nsfwDetails.anatomy.vagina.description}`
                                          : ""}
                                      </Typography>
                                    </Box>
                                  )}
                                  {data.nsfwDetails.anatomy.breasts && (
                                    <Box>
                                      <Typography>
                                        {data.nsfwDetails.anatomy.breasts
                                          .style === "standard"
                                          ? "Two"
                                          : "Six"}{" "}
                                        {!data.nsfwDetails.anatomy.breasts
                                          .symmetric && "a"}
                                        symmetric{" "}
                                        {data.nsfwDetails.anatomy.breasts
                                          .size &&
                                          data.nsfwDetails.anatomy.breasts.size
                                            .cup + " cup"}{" "}
                                        breasts
                                      </Typography>
                                    </Box>
                                  )}
                                </Box>
                              ) : (
                                ""
                              )}
                            </Box>
                            <Button
                              onClick={() => {
                                setEditAnatomyOpen(true);
                              }}
                            >
                              Edit Anatomy Details
                            </Button>
                          </TableCell>
                        </TableRow>
                      </>
                    )}
                  </>
                )}
                <>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {"Ref Sheet"}
                    </TableCell>
                    <TableCell
                    // align="right"
                    >
                      <Box
                        sx={{
                          // textAlign: "center"
                          // alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          gap: "1em",
                        }}
                      >
                        {data.characterRefSheet &&
                          (data.characterRefSheet as CharacterRefSheet)
                            .image && (
                            <>
                              <Box>
                                <img
                                  src={data.characterRefSheet.image}
                                  alt={`${data.characterName}-ref-sheet`}
                                  style={{
                                    height: "250px",
                                  }}
                                />
                              </Box>
                              <Typography variant="body1">
                                {refArtistData
                                  ? refArtistData.artistPrimaryName
                                  : ""}
                              </Typography>
                            </>
                          )}
                        <Box>
                          <Button
                            onClick={() => {
                              setUploadRefDialogOpen(true);
                            }}
                          >
                            Upload New Ref
                          </Button>
                          <Button onClick={handleClearRef}>Clear Ref</Button>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                </>

                {showNSFW && <>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {"Alt/NSFW Ref Sheet"}
                    </TableCell>
                    <TableCell
                    // align="right"
                    >
                      <Box
                        sx={{
                          // textAlign: "center"
                          // alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          gap: "1em",
                        }}
                      >
                        {data.nsfwDetails && data.nsfwDetails.characterRefSheetNSFW &&
                          (data.nsfwDetails.characterRefSheetNSFW as CharacterRefSheet)
                            .image && (
                            <>
                              <Box>
                                <img
                                  src={data.nsfwDetails.characterRefSheetNSFW.image}
                                  alt={`${data.characterName}-alt-ref-sheet`}
                                  style={{
                                    height: "250px",
                                  }}
                                />
                              </Box>
                              <Typography variant="body1">
                                {altRefArtistData
                                  ? altRefArtistData.artistPrimaryName
                                  : ""}
                              </Typography>
                            </>
                          )}
                        <Box>
                          <Button
                            onClick={() => {
                              setUploadAltRefDialogOpen(true);
                            }}
                          >
                            Upload New Alt/NSFW Ref
                          </Button>
                          <Button onClick={handleClearAltRef}>Clear Alt/NSFW Ref</Button>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                </>}

                <>
                  <TableRow
                    sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
                  >
                    <TableCell component="th" scope="row">
                      {"Thumb"}
                    </TableCell>
                    <TableCell
                    // align="right"
                    >
                      <Box
                        sx={{
                          // textAlign: "center"
                          // alignItems: "center",
                          display: "flex",
                          flexDirection: "column",
                          gap: "1em",
                        }}
                      >
                        <Box
                          sx={{
                            display: "flex",
                            flexDirection: "column",
                            gap: "1em",
                          }}
                        >
                          {data.characterThumb && (
                            <Box>
                              <img
                                src={data.characterThumb}
                                alt={`${data.characterName}-thumb`}
                                style={{
                                  height: "100px",
                                }}
                              />
                            </Box>
                          )}
                          <Box>
                            <Button
                              onClick={() => {
                                setUploadThumbDialogOpen(true);
                              }}
                            >
                              Upload New Thumbnail
                            </Button>
                            <Button onClick={handleClearThumb}>
                              Clear Thumbnail
                            </Button>
                          </Box>
                        </Box>
                      </Box>
                    </TableCell>
                  </TableRow>
                </>
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
        {isAuth && (
          <div>
            <Typography variant="body1">
              Options for character {data.characterName}
            </Typography>
            <Box>
              {/* <Button onClick={handleClickEditOpen}>Edit</Button> */}
              <Button onClick={handleClickRemoveOpen}>Remove</Button>
            </Box>
            <Box>
              <FormGroup>
                <FormControlLabel
                  control={
                    <Switch
                      checked={localPublished}
                      onChange={() => {
                        setLocalPublished(!localPublished);
                        handleUpdatePublished();
                      }}
                    />
                  }
                  label="Published?"
                />
              </FormGroup>
            </Box>
          </div>
        )}
      </Container>
      <Dialog
        open={removeConfirmOpen}
        onClose={handleCloseRemove}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm character deletion?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you wish to remove the character {data.characterName}{" "}
            from the list?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRemove}>Cancel</Button>
          <Button
            onClick={() => {
              handleCloseRemovePerformRemoval(data.id);
            }}
            autoFocus
          >
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

function ManageCharacters({
  isAuth,
  showNSFW,
}: {
  isAuth: boolean;
  showNSFW: boolean;
}) {
  const [characters, setCharacters] = useState<Array<any>>([]);
  const charactersCollectionRef = collection(db, "characters");
  const [pending, setPending] = useState<boolean>(true);
  const [addOpen, setAddOpen] = useState<boolean>(false);

  const runUpdate = () => {
    const getArtists = async () => {
      // disabling nsfw filtering until I know if I really need it in this component or not
      // if(showNSFW){
      //   const data = await getDocs(charactersCollectionRef);
      //   setCharacters(data.docs.map((doc) => ({ ...doc.data(), id: doc.id})));
      // } else { // filter out docs where onlyShowIfNsfw is true
      //   const q = query(charactersCollectionRef, where("onlyShowIfNsfw", "==", false));
      //   const querySnapshot = await getDocs(q);
      //   setCharacters(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id})));
      // }
      const data = await getDocs(charactersCollectionRef);
      setCharacters(data.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
    };
    getArtists();
    setPending(false);
  };
  useEffect(() => {
    runUpdate();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNSFW]);
  return (
    <MainBody title={"Manage Characters"} showNSFW={showNSFW}>
      <Container>
        <DataTable
          title="Manage My Characters"
          columns={columns}
          data={characters}
          sortIcon={<SortIcon />}
          pagination
          selectableRows={false}
          expandOnRowClicked
          // expandableRows={isAuth}
          expandableRows={true}
          expandableRowsHideExpander
          expandableRowsComponent={ExpandedComponent}
          expandableRowsComponentProps={{
            showNSFW: showNSFW,
            isAuth: isAuth,
            characters: characters,
            setCharacters: setCharacters,
            runUpdate: runUpdate,
          }}
          progressPending={pending}
        />
      </Container>
      {isAuth && (
        <Fab
          color="primary"
          aria-label="add"
          sx={{ ...fabStyle }}
          onClick={() => {
            setAddOpen(true);
          }}
        >
          <AddIcon />
        </Fab>
      )}
      <Dialog
        open={addOpen}
        onClose={() => {
          setAddOpen(false);
        }}
      >
        <div style={{ padding: "1em" }}>
          <CreateCharacter
            isAuth={isAuth}
            onAddCallback={() => {
              setAddOpen(false);
              runUpdate();
            }}
          />
        </div>
      </Dialog>
    </MainBody>
  );
}

export default ManageCharacters;
