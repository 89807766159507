import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import { useEffect, useState } from 'react'
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import { CharacterDataRow } from "../interfaces";
import EditTrait from './EditTrait';
import AddTrait from './AddTrait';

function EditTraits({ 
    open,
    setOpen,
    traits,
    updateTraits,
    // saveCharacterUpdateHandler
    characters,
    setCharacters,
    editCharacterHandler,
    data,
    runUpdate,
    traitKey,
    traitLabel,
    nsfw
  }:
    {
      open: boolean,
      setOpen: Function,
      traits: Array<any>,
      updateTraits: Function,
      characters: Array<any> | undefined,
      setCharacters: Function,
      editCharacterHandler: Function
      data: CharacterDataRow,
      runUpdate: Function,
      traitKey: any,
      traitLabel: string,
      nsfw?: boolean
    }
  ) {
  const [localTraits, setLocalTraits] = useState<Array<string>>(traits);
  const [addTraitOpen, setAddTraitOpen] = useState<boolean>(false);
  const [editTraitOpen, setEditTraitOpen] = useState<boolean>(false);
  const [oldTraitHolder, setOldTraitHolder] = useState<string>('');

  const handleClose = () => {
    setOpen(false);
    setLocalTraits(traits)
  };

  const handleSaveAndClose = () => {
    let newCharacters: Array<CharacterDataRow> = [...characters || []];
    let idxToUpdate = newCharacters.indexOf(newCharacters.filter((i) => i.id === data.id)[0]);
    if(nsfw) {
      try {
        if(newCharacters[idxToUpdate].nsfwDetails){
          let details = newCharacters[idxToUpdate].nsfwDetails || {};
          let newDetail = {[traitKey]: localTraits};
          newCharacters[idxToUpdate].nsfwDetails = {...details, ...newDetail} // FIXME: the list gets updated correctly, but also a new key called "traitKey" is being created somehow...
          // console.log(newCharacters[idxToUpdate].nsfwDetails)
        }
        setCharacters(newCharacters);
        editCharacterHandler(data, runUpdate);
        updateTraits(localTraits);
        handleClose();
      } catch(err) {
        // console.error(err);
        updateTraits(localTraits);
        handleClose();
      }
    } else {
      try {
        if(newCharacters[idxToUpdate].characterDetails){
          let details = newCharacters[idxToUpdate].characterDetails || {};
          let newDetail = {[traitKey]: localTraits};
          newCharacters[idxToUpdate].characterDetails = {...details, ...newDetail} // FIXME: the list gets updated correctly, but also a new key called "traitKey" is being created somehow...
          // console.log(newCharacters[idxToUpdate].characterDetails)
        }
        setCharacters(newCharacters);
        editCharacterHandler(data, runUpdate);
        updateTraits(localTraits);
        handleClose();
      } catch(err) {
        // console.error(err);
        updateTraits(localTraits);
        handleClose();
      }
    }
    // saveCharacterUpdateHandler();
  };

  const removeTrait = (traitToRemove: string) => {
    let newTraits = [...localTraits];
    let idxOfTraitToRemove = newTraits.indexOf(traitToRemove);
    if (idxOfTraitToRemove !== -1) {
      newTraits.splice(idxOfTraitToRemove, 1);
    }
    setLocalTraits(newTraits);
  };

  useEffect(() => {
    setLocalTraits(traits);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateLocalTraits = (newTrait: string) => {
    let newTraits = [...localTraits];
    newTraits.push(newTrait);
    setLocalTraits(newTraits);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Edit ${traitLabel}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Edit {`${traitLabel}`}.
          </DialogContentText>
          <List>
            {localTraits.map((trait, idx) => (
              <ListItem
                key={`trait-id-${idx}`}
                secondaryAction={
                  <div>
                    <IconButton edge="end" aria-label="edit" onClick={() => {setOldTraitHolder(trait); setEditTraitOpen(true)}}>
                      <EditIcon />
                    </IconButton>
                    <IconButton edge="end" aria-label="delete" onClick={() => {removeTrait(trait)}}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                }
              >
                {/* <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar> */}
                <ListItemText
                  primary={trait}
                />
              </ListItem>
            ))}
            {localTraits.length > 0 && <Divider />}
            <ListItem key="add-new" disablePadding>
              <ListItemButton onClick={() => {setAddTraitOpen(true)}}>
                <ListItemText primary={`Add New ${traitLabel}`} />
              </ListItemButton>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveAndClose} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <EditTrait open={editTraitOpen} setOpen={setEditTraitOpen} oldTrait={oldTraitHolder} handleEditTrait={(oldTrait: string, newTrait: string) => {
        let idxToUpdate = localTraits.indexOf(oldTrait);
        localTraits[idxToUpdate] = newTrait;
      }} traitLabel={traitLabel} />
      <AddTrait open={addTraitOpen} setOpen={setAddTraitOpen} addTrait={handleUpdateLocalTraits} traitLabel={traitLabel} />
    </div>
  )
}

export default EditTraits