import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

function AddTrait({ open, setOpen, addTrait, traitLabel }: {open: boolean, setOpen: Function, addTrait: Function, traitLabel: string}) {
  const [trait, setTrait] = useState<string>("");
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveAndClose = () => {
    let newTrait: string = trait;
    addTrait(newTrait);
    handleClose();
  };

  useEffect(() => {
    setTrait("");
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {`Add ${traitLabel}`}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Add New {`${traitLabel}`}
          </DialogContentText>
          <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <TextField
              required
              id="new-trait"
              label={`${traitLabel}`}
              // placeholder=''
              onChange={(event) => {setTrait(event.target.value)}}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveAndClose} autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AddTrait