import React, { useEffect, useState } from 'react'
import MainBody from './MainBody'
import { Accordion, AccordionDetails, AccordionSummary, Autocomplete, Box, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControlLabel, FormGroup, Paper, Switch, TextField, Typography } from '@mui/material'
import { Add, Delete } from '@mui/icons-material'
import { Timestamp, collection, deleteDoc, doc, getDocs, setDoc } from 'firebase/firestore';
import { db } from '../firebase-config';
import { ArtistDataRow, CharacterDataRow, CommissionDataRow } from '../interfaces';
import DataTable, { TableColumn, ExpanderComponentProps } from 'react-data-table-component';
import { Link } from 'react-router-dom';
import SortIcon from '@mui/icons-material/ArrowDownward'
import AddIcon from '@mui/icons-material/Add';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import { humanizeTimeDifference } from '../helpers/humanizeTimeDifference';
import EditUpdatesManager from '../components/EditUpdatesManager';
import CreateCommission from './CreateCommission';

const fabStyle = {
  position: 'absolute',
  bottom: 16,
  right: 16,
};

const deleteCommissionHandler = async (commId: string, cb: Function) => {
  const commDoc = doc(db, "commissions", commId);
  await deleteDoc(commDoc);
  // window.location.reload();
  cb();
};

const editCommissionHandler = async (data: CommissionDataRow, cb: Function) => {
  const commDoc = doc(db, "commissions", data.id);
  let newDoc: any = { ...data }
  delete newDoc.id // remove ID from document
  setDoc(commDoc, newDoc).then(() => {
    // window.location.reload();
    cb();
  });
};

interface CustomExpanderComponentProps extends ExpanderComponentProps<CommissionDataRow> {
  // currently, props that extend ExpanderComponentProps must be set to optional.
  isAuth?: boolean;
  runUpdate?: any; // doesn't like being Function
  availableArtists?: Array<ArtistDataRow>,
  availableCharacters?: Array<CharacterDataRow>,
}

const ExpandedComponent: React.FC<CustomExpanderComponentProps> = ({ data, isAuth, runUpdate, availableArtists, availableCharacters }) => {
  const [removeConfirmOpen, setRemoveConfirmOpen] = useState<boolean>(false);
  const artistsCollectionRef = collection(db, "artists");
  const charactersCollectionRef = collection(db, "characters");
  const retrieveArtists = (artistsIdsToGet: Array<string>) => {
    return availableArtists!.filter(i => artistsIdsToGet.includes(i.id))
  }
  const retrieveCharacters = (characterIdsToGet: Array<string>) => {
    return availableCharacters!.filter(i => characterIdsToGet.includes(i.id))
  }
  const [artists, setArtists] = useState<Array<ArtistDataRow>>(data.artists.length > 0 ? retrieveArtists(data.artists.map(i => i._key.path.segments.at(-1))) : []);
  const [characters, setCharacters] = useState<Array<CharacterDataRow>>(data.linkedCharacters && data.linkedCharacters.length > 0 ? retrieveCharacters(data.linkedCharacters.map(i => i._key.path.segments.at(-1))) : []);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [editUpdatesOpen, setEditUpdatesOpen] = useState<boolean>(false);
  const [isDone, setIsDone] = useState<boolean>(data.done || false);
  const [isNsfw, setIsNsfw] = useState<boolean>(data.nsfw || false);
  const [hidden, setHidden] = useState<boolean>(data.hide || false);
  const [commissionName, setCommissionName] = useState<string>(data.commission_name || "");
  const [commissionCostUSD, setCommissionCostUSD] = useState<number>(data.costUSD || 0.00);
  const [commissionTipUSD, setCommissionTipUSD] = useState<number>(data.tipUSD || 0.00);
  const [dateInit, setDateInit] = useState<Dayjs>(dayjs(data.dateInit.seconds * 1000) || dayjs());
  const [datePaid, setDatePaid] = useState<Dayjs | undefined>(data.datePaid ? dayjs(data.datePaid.seconds * 1000) : undefined);
  const [dateDelivered, setDateDelivered] = useState<Dayjs | undefined>(data.dateDelivered ? dayjs(data.dateDelivered.seconds * 1000) : undefined);

  const handleClickEditOpen = () => {
    setEditOpen(true);
  };

  const handleClickUpdatesManagerOpen = () => {
    setEditUpdatesOpen(true);
  };

  const handleCloseEditUpdates = (reInit?: boolean) => {
    setEditUpdatesOpen(false);
  };

  const handleCloseEdit = (reInit?: boolean) => {
    setEditOpen(false);
    if (reInit) {
      runUpdate();
      // re-init vars
      setIsDone(data.done || false);
      setIsNsfw(data.nsfw || false);
      setHidden(data.hide || false);
      setCommissionName(data.commission_name || "");
      setCommissionCostUSD(data.costUSD || 0.00);
      setCommissionTipUSD(data.tipUSD || 0.00);
      setDateInit(dayjs(data.dateInit.seconds * 1000));
      setDatePaid(data.datePaid ? dayjs(data.datePaid.seconds * 1000) : undefined);
      setDateDelivered(data.dateDelivered ? dayjs(data.dateDelivered.seconds * 1000) : undefined);
      setArtists(data.artists.length > 0 ? retrieveArtists(data.artists.map(i => i._key.path.segments.at(-1))) : []);
    }
  };

  const handleCloseEditAndSave = (data: CommissionDataRow) => {
    editCommissionHandler(data, runUpdate);
    handleCloseEdit();
  }

  const handleClickRemoveOpen = () => {
    setRemoveConfirmOpen(true);
  };

  const handleCloseRemove = () => {
    setRemoveConfirmOpen(false);
  };

  const handleCloseRemovePerformRemoval = (commissionId: string) => {
    deleteCommissionHandler(commissionId, runUpdate);
    handleCloseRemove();
  };

  const handleChangeArtists = (
    event: React.ChangeEvent<{}>,
    newValue: ArtistDataRow[] | null
  ) => {
    if (newValue) {
      setArtists(newValue);
    }
  };

  const handleChangeCharacters = (
    event: React.ChangeEvent<{}>,
    newValue: CharacterDataRow[] | null
  ) => {
    if (newValue) {
      setCharacters(newValue);
    }
  };

  return (
    <div>
      <Container>
        <Paper
          sx={{
            // margin: "1rem",
            padidng: "1rem",
          }}
        >
          <Box
            sx={{
              padding: "1rem",
              display: "flex",
              flexDirection: "column",
              gap: 1,
            }}
          >
            <Typography>Cost (USD): {`$${commissionCostUSD.toFixed(2)}`}</Typography>
            <Typography>Tip (USD): {`$${commissionTipUSD.toFixed(2)}`}</Typography>
            <Typography>Total Cost (USD): {`$${(commissionCostUSD + commissionTipUSD).toFixed(2)}`}</Typography>
            <Typography>Done: {isDone ? "True" : "False"}</Typography>
            <Typography>Safety: {isNsfw ? "NSFW" : "SFW"}</Typography>
            <Typography>Hidden: {hidden ? "True" : "False"}</Typography>
            {isAuth && <div>
              <Typography variant="body1">Options for Commission {data.commission_name}</Typography>
              <Box>
                <Button onClick={handleClickUpdatesManagerOpen}>Manage Updates</Button>
                <Button onClick={handleClickEditOpen}>Edit</Button>
                <Button onClick={handleClickRemoveOpen}>Remove</Button>
              </Box>
            </div>}
            <EditUpdatesManager open={editUpdatesOpen} onClose={() => { handleCloseEditUpdates() }} commission={data} runUpdateData={runUpdate} />
            <Dialog
              open={removeConfirmOpen}
              onClose={handleCloseRemove}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Confirm commission deletion?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Are you sure you wish to remove the commission {data.commission_name} from the list?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleCloseRemove}>Cancel</Button>
                <Button onClick={() => { handleCloseRemovePerformRemoval(data.id) }} autoFocus>
                  Confirm
                </Button>
              </DialogActions>
            </Dialog>
            <Dialog
              open={editOpen}
              onClose={() => { handleCloseEdit(true) }}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Edit Commission"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  Edit commission {data.commission_name}...
                </DialogContentText>
                <div style={{ marginBottom: "1em" }} />
                <Container>
                  <Box
                    component="form"
                    // sx={{
                    //   '& .MuiTextField-root': { m: 1, width: '50ch' },
                    // }}
                    noValidate
                    autoComplete="off"
                  >
                    <div style={{ display: "flex", flexDirection: "column", gap: "2em" }}>
                      {/* <TextField
                        required
                        id="new-artist-primary-name"
                        label="Primary Artist Name"
                        defaultValue={data.artistPrimaryName}
                        placeholder='AstraBun'
                        onChange={(event) => { setArtistPrimaryName(event.target.value) }}
                      />
                      <TextField
                        required
                        id="new-artist-primary-link"
                        label="Primary Artist Link"
                        defaultValue={data.artistPrimaryLink}
                        placeholder='https://twitter.com/astra_bun'
                        onChange={(event) => { setArtistPrimaryLink(event.target.value) }}
                      />
                      <Box>
                        <Typography gutterBottom>Links:</Typography>
                        {artistLinks.map((link, idx) => (<Chip key={`artist-link-chip-${idx}`} label={link.title} clickable onClick={() => { window.open(link.link || "#", "_blank") }} />))}
                      </Box>
                      <Button onClick={() => { setEditLinksOpen(true) }}>Edit Links</Button>
                      <EditLinks open={editLinksOpen} setOpen={setEditLinksOpen} links={artistLinks} updateLinks={handleUpdateArtistLinks} />
                      <FormGroup>
                        <FormControlLabel control={<Checkbox defaultChecked={onlyShowIfNsfw} onChange={() => { setOnlyShowIfNsfw(!onlyShowIfNsfw) }} />} label="Only show if NSFW?" />
                        {onlyShowIfNsfw && <Typography variant="caption">This artist will be hidden unless the user enters NSFW mode.</Typography>}
                      </FormGroup> */}
                      <Box
                        component="form"
                        // sx={{
                        //   '& .MuiTextField-root': { m: 1, width: '50ch' },
                        // }}
                        noValidate
                        autoComplete="off"
                        sx={{
                          display: "flex",
                          flexDirection: 'column',
                          gap: 1,

                        }}
                      >
                        <Typography>Basic Metadata</Typography>
                        <Autocomplete
                          multiple
                          id="tags-standard"
                          options={availableArtists!}
                          getOptionLabel={(option) => option.artistPrimaryName}
                          // defaultValue={[]}
                          value={artists}
                          onChange={handleChangeArtists}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Artist(s)"
                              placeholder="Artists"
                            />
                          )}
                        />
                        <Autocomplete
                          multiple
                          id="tags-standard-chars"
                          options={availableCharacters!}
                          getOptionLabel={(option) => option.characterName}
                          value={characters}
                          onChange={handleChangeCharacters}
                          renderInput={(params) => (
                            <TextField
                              {...params}
                              variant="standard"
                              label="Characters(s)"
                              placeholder="Characters"
                            />
                          )}
                        />
                        <TextField
                          required
                          id="new-comm-name"
                          label="Commission Title/Name"
                          defaultValue={commissionName}
                          placeholder='A Piece of Art'
                          onChange={(event) => { setCommissionName(event.target.value) }}
                        />
                        <TextField
                          required
                          id="new-comm-price"
                          label="Commission Price (USD)"
                          defaultValue={commissionCostUSD}
                          placeholder='0.00'
                          onChange={(event) => { setCommissionCostUSD(parseFloat(event.target.value)) }}
                        />
                        <TextField
                          required
                          id="new-comm-tip"
                          label="Tipped (USD)"
                          defaultValue={commissionTipUSD}
                          placeholder='0.00'
                          onChange={(event) => { setCommissionTipUSD(parseFloat(event.target.value)) }}
                        />
                        <div />
                        <div />
                        <Typography>High Level Dates</Typography>
                        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                          <LocalizationProvider dateAdapter={AdapterDayjs}>
                            <DateTimePicker
                              label="Commission Init"
                              value={dateInit}
                              onChange={(n: any) => { setDateInit(n) }}
                              renderInput={(params: any) => <TextField {...params} />}
                              // minDateTime={dayjs("2022-12-01")}
                              // maxDateTime={dayjs().add(1, "minute")}
                              disableFuture
                            />
                          </LocalizationProvider>
                          <Button onClick={() => { setDateInit(dayjs()) }} variant="outlined">Now</Button>
                        </Box>
                        <div />
                        {!datePaid ? <>
                          <Button startIcon={<AddIcon />} onClick={() => { setDatePaid(dayjs()) }}>Add Payment Date</Button>
                        </> : <>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                label="Date Paid"
                                value={datePaid}
                                onChange={(n: any) => { setDatePaid(n) }}
                                renderInput={(params: any) => <TextField {...params} />}
                                // minDateTime={dayjs("2022-12-01")}
                                // maxDateTime={dayjs().add(1, "minute")}
                                disableFuture
                              />
                            </LocalizationProvider>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                              <Button onClick={() => { setDatePaid(undefined) }} variant="outlined" startIcon={<Delete />}>Remove</Button>
                              <Button onClick={() => { setDatePaid(dayjs()) }} variant="outlined">Now</Button>
                            </Box>
                          </Box>
                        </>}
                        <div />
                        {!dateDelivered ? <>
                          <Button startIcon={<AddIcon />} onClick={() => { setDateDelivered(dayjs()) }}>Add Delivery Date</Button>
                        </> : <>
                          <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                            <LocalizationProvider dateAdapter={AdapterDayjs}>
                              <DateTimePicker
                                label="Date Delivered"
                                value={dateDelivered}
                                onChange={(n: any) => { setDateDelivered(n) }}
                                renderInput={(params: any) => <TextField {...params} />}
                                // minDateTime={dayjs("2022-12-01")}
                                // maxDateTime={dayjs().add(1, "minute")}
                                disableFuture
                              />
                            </LocalizationProvider>
                            <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                              <Button onClick={() => { setDateDelivered(undefined) }} variant="outlined" startIcon={<Delete />}>Remove</Button>
                              <Button onClick={() => { setDateDelivered(dayjs()) }} variant="outlined">Now</Button>
                            </Box>
                          </Box>
                        </>}
                        <div />
                        <div />
                        <Typography>Status Toggles</Typography>
                        <FormGroup>
                          <FormControlLabel control={<Checkbox checked={isDone} onChange={() => { setIsDone(!isDone) }} />} label="Commission Done?" />
                        </FormGroup>
                        <FormGroup>
                          <FormControlLabel control={<Checkbox checked={isNsfw} onChange={() => { setIsNsfw(!isNsfw) }} />} label="NSFW?" />
                        </FormGroup>
                        <FormGroup>
                          <FormControlLabel control={<Checkbox checked={hidden} onChange={() => { setHidden(!hidden) }} />} label="Hidden from public?" />
                          {hidden && <Typography variant="caption">This commission will be hidden on the public view page.</Typography>}
                        </FormGroup>
                      </Box>
                    </div>
                  </Box>
                </Container>
              </DialogContent>
              <DialogActions>
                <Button onClick={() => {
                  handleCloseEdit(true)
                }}>Cancel</Button>
                <Button onClick={() => {
                  // requireds
                  let editedObj: CommissionDataRow = {
                    id: data.id,
                    artists: artists.map((a) => doc(artistsCollectionRef, a.id)),
                    commission_name: commissionName,
                    costUSD: commissionCostUSD,
                    dateInit: new Timestamp(dateInit.unix(), 0),
                    done: isDone,
                    nsfw: isNsfw,
                    hide: hidden,
                    linkedCharacters: characters.map((c) => doc(charactersCollectionRef, c.id)),
                  };
                  // optionals
                  if (data.art) {
                    editedObj.art = data.art
                  }
                  if (commissionTipUSD !== 0) {
                    editedObj.tipUSD = commissionTipUSD
                  }
                  if (datePaid) {
                    editedObj.datePaid = new Timestamp(datePaid.unix(), 0)
                  }
                  if (data.dateStarted) {
                    editedObj.dateStarted = data.dateStarted
                  }
                  if (dateDelivered) {
                    editedObj.dateDelivered = new Timestamp(dateDelivered.unix(), 0)
                  }
                  handleCloseEditAndSave(editedObj)
                }
                } autoFocus>
                  Save
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </Paper>
      </Container>
    </div>
  )
}

function ManageCommissions({ isAuth, showNSFW }: { isAuth: boolean, showNSFW: boolean }) {
  const artistsCollectionRef = collection(db, "artists");
  const charactersCollectionRef = collection(db, "characters");

  const [commissions, setCommissions] = useState<Array<CommissionDataRow>>([]);
  const [showAll, setShowAll] = useState<boolean>(false);
  const commissionsCollectionRef = collection(db, "commissions");
  const [pending, setPending] = useState<boolean>(true);
  const [artists, setArtists] = useState<Array<ArtistDataRow>>([]);
  const [characters, setCharacters] = useState<Array<CharacterDataRow>>([]);
  const [addOpen, setAddOpen] = useState<boolean>(false);

  const [sumAllCommsUSD, setSumAllCommsUSD] = useState<number | undefined>();

  const runUpdate = () => {
    const getCommissions = async () => {
      const data = await getDocs(commissionsCollectionRef);
      let comms = data.docs.map((doc) => ({ ...doc.data(), id: doc.id } as unknown as CommissionDataRow)) as Array<CommissionDataRow>;
      comms = comms.filter(i => showAll ? true : !i.done)
      setCommissions(comms);
      let sum = 0.0;
      comms.forEach((c) => {
        sum = sum + c.costUSD + (c.tipUSD || 0)
      });
      setSumAllCommsUSD(sum);

      const artistsData = await (getDocs(artistsCollectionRef));
      let artists = artistsData.docs.map((doc) => ({ ...doc.data(), id: doc.id })) as Array<ArtistDataRow>;
      setArtists(artists);
      const charactersData = await (getDocs(charactersCollectionRef));
      let characters = charactersData.docs.map((doc) => ({ ...doc.data(), id: doc.id })) as Array<CharacterDataRow>;
      setCharacters(characters);
    };
    getCommissions();
    setPending(false);
  };

  useEffect(() => {
    runUpdate();
  }, [showAll, deleteCommissionHandler, showNSFW]); // eslint-disable-line react-hooks/exhaustive-deps

  const columns: TableColumn<any>[] = [
    {
      name: "Commission Title",
      selector: (r: CommissionDataRow) => r.commission_name,
      sortable: true
    },
    {
      name: "Date Start",
      selector: (r: CommissionDataRow) => r.dateInit.seconds,
      cell: (r: CommissionDataRow) => dayjs(r.dateInit.seconds * 1000).format(),
      sortable: true
    },
    {
      name: "Wait time",
      selector: (r: CommissionDataRow) => r.dateDelivered ? r.dateDelivered.seconds - r.dateInit.seconds : dayjs().unix() - r.dateInit.seconds,
      cell: (r: CommissionDataRow) => r.dateDelivered ? humanizeTimeDifference(r.dateDelivered.seconds * 1000, r.dateInit.seconds * 1000) : humanizeTimeDifference(r.dateInit.seconds * 1000, dayjs().unix() * 1000),
      sortable: true
    },
    {
      name: "Artist(s)",
      selector: (r: CommissionDataRow) => r.artists.map((a) => {
        let artistId = a._key.path.segments.at(-1);
        if (artists.length === 0) {
          return "Loading..."
        }
        let resolvedArtist = artists.filter(i => i.id === artistId)[0];
        return resolvedArtist.artistPrimaryName;
      }).join(", "),
      sortable: true
    },
    {
      name: "Safety",
      selector: (r: CommissionDataRow) => r.nsfw ? "NSFW" : "SFW",
      sortable: false
    },
    {
      name: "ID",
      selector: (r: CommissionDataRow) => r.id,
      sortable: false
    },
    {
      name: "Done",
      selector: (r: CommissionDataRow) => r.done ? "true" : "false",
      sortable: false,
    },
    {
      name: "Link",
      selector: (r: CommissionDataRow) => `/commissions/log/${r.id}`,
      sortable: false,
      cell: (r: CommissionDataRow) => <Button to={`/commissions/log/${r.id}`} target="_blank" component={Link}>View</Button>
    }
  ];

  return (
    <MainBody title={"Manage Commissions"}>
      <div style={{ marginBottom: "1rem" }} />
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 1,
        }}
      >
        <Button startIcon={<Add />} variant="contained" color="secondary" onClick={() => { setAddOpen(true) }}>Track New Commission</Button>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 1,
          }}
        >
          <Typography>Filters</Typography>
          <Box
            sx={{
              display: "flex",
              flexDirection: "row",
              gap: 1,
            }}
          >
            <div id="show-all">
              <FormGroup>
                <FormControlLabel control={<Switch checked={showAll} onChange={() => { setShowAll(!showAll) }} />} label={showAll ? "Showing all" : "Showing only in-progress"} />
              </FormGroup>
            </div>
          </Box>
        </Box>
        <Container>
          <DataTable
            title="Manage Commissions"
            columns={columns}
            data={commissions}
            sortIcon={<SortIcon />}
            pagination
            selectableRows={false}
            expandOnRowClicked
            // expandableRows={isAuth}
            expandableRows={true}
            expandableRowsHideExpander
            expandableRowsComponent={ExpandedComponent}
            expandableRowsComponentProps={{ isAuth: isAuth, runUpdate: runUpdate, availableArtists: artists, availableCharacters: characters }}
            progressPending={pending}
          />
        </Container>
      </Box>
      {
        isAuth &&
        <Fab color="primary" aria-label="add" sx={{ ...fabStyle }} onClick={() => { setAddOpen(true) }}>
          <AddIcon />
        </Fab>
      }
      {/* <Dialog open={addOpen} onClose={() => { setAddOpen(false) }}>
        <div style={{ padding: "1em" }}>
          todo
        </div>
      </Dialog> */}
      <CreateCommission open={addOpen} onClose={() => { setAddOpen(false) }} availableArtists={artists} availableCharacters={characters} runUpdateData={runUpdate} />
      <div style={{ marginBottom: "5rem" }} />
      <Accordion>
        <AccordionSummary>
          Misc
        </AccordionSummary>
        <AccordionDetails>
          <Container>
            <Box
              sx={{ display: "flex", flexDirection: "column", gap: 1}}
            >
              <Typography>Based on current filters...</Typography>
              <Typography>Approx. Total Spent on Commissions: {`$${sumAllCommsUSD?.toFixed(2)}`}</Typography>
            </Box>
          </Container>
        </AccordionDetails>
      </Accordion>
    </MainBody>
  )
}

export default ManageCommissions