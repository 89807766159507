import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ArtistLinkItem } from "../interfaces";

function EditLink({ open, setOpen, oldLink, handleEditLink }: {open: boolean, setOpen: Function, oldLink: ArtistLinkItem, handleEditLink: Function}) {
  const [title, setTitle] = useState<string>(oldLink.title);
  const [subtitle, setSubtitle] = useState<string|null>(oldLink.subtitle || null);
  const [link, setLink] = useState<string>(oldLink.link);
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveAndClose = () => {
    let newLink: ArtistLinkItem = {
      title: title,
      link: link
    };
    if(subtitle !== null) {
      newLink.subtitle = subtitle;
    }
    handleEditLink(oldLink, newLink);
    handleClose();
  };

  useEffect(() => {
    setTitle(oldLink.title);
    setSubtitle(oldLink.subtitle || null);
    setLink(oldLink.link);
  }, [oldLink.link, oldLink.subtitle, oldLink.title, open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Edit Link"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Edit Link
          </DialogContentText>
          <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <TextField
              required
              id="new-link-title"
              label="Link Title"
              placeholder='Twitter'
              defaultValue={oldLink.title}
              onChange={(event) => {setTitle(event.target.value)}}
            />
            <TextField
              id="new-link-subtitle"
              label="Link Subtitle (Optional)"
              placeholder='The Epic Tweeting Website'
              defaultValue={oldLink.subtitle || ""}
              onChange={(event) => {setSubtitle(event.target.value)}}
            />
            <TextField
              required
              id="new-link-url"
              label="URL"
              placeholder='https://twitter.com/astra_bun'
              defaultValue={oldLink.link}
              onChange={(event) => {setLink(event.target.value)}}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveAndClose} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EditLink