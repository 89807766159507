import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'

function AddPronoun({ open, setOpen, addPronoun }: {open: boolean, setOpen: Function, addPronoun: Function}) {
  const [pronoun, setPronoun] = useState<string>("");
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveAndClose = () => {
    let newPronoun: string = pronoun;
    addPronoun(newPronoun);
    handleClose();
  };

  useEffect(() => {
    setPronoun("");
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Add Pronoun"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Add New Pronoun
          </DialogContentText>
          <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <TextField
              required
              id="new-pronoun"
              label="Pronoun"
              placeholder='He'
              onChange={(event) => {setPronoun(event.target.value)}}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveAndClose} autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AddPronoun