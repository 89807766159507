import { Typography } from '@mui/material'
import React from 'react'
import MainBody from './MainBody'

function NotFound404() {
  return (
    <MainBody title={"Oopsie whoopsie UwU~ Page Not Found!"}>
      <Typography variant="h4">Hmm, I couldn't find that page...</Typography>
      <Typography variant="body1">Sorry! That page couldn't be found. Try going to the homepage and starting from there.</Typography>
    </MainBody>
  )
}

export default NotFound404