import { Box, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogTitle, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material';
import { LocalizationProvider, DateTimePicker } from '@mui/x-date-pickers'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import dayjs, { Dayjs } from 'dayjs'
import React, { useState } from 'react'
import { CommissionDataRow } from '../interfaces';
import { Timestamp, addDoc, collection, doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase-config';

type Props = {
  isNew?: boolean,
  existingUpdate?: any, // passed in on edit, not new
  commission: CommissionDataRow,
  open: boolean,
  onClose: any, // func
  runUpdateData: any, // func
}

function UpdateEditor(props: Props) {
  const { isNew, existingUpdate, open, onClose, commission, runUpdateData } = props;
  const [note, setNote] = useState<string>(existingUpdate ? existingUpdate.note : "");
  const [ts, setTs] = useState<Dayjs>(existingUpdate ? dayjs(existingUpdate.ts.seconds * 1000) : dayjs());
  const [hidden, setHidden] = useState<boolean>((existingUpdate && existingUpdate.hidden !== undefined) ? existingUpdate.hidden : false);

  const closeAction = (resetForm?: boolean) => {
    onClose();
    if (resetForm === true) {
      setNote(existingUpdate ? existingUpdate.note : "");
      setTs(existingUpdate ? dayjs(existingUpdate.ts.seconds * 1000) : dayjs());
    }
  }
  const saveAndClose = () => {
    const commUpdatesCollectionRef = collection(db, "commissionHistoryItems");
    if (isNew) {
      addDoc(commUpdatesCollectionRef, {
        ts: new Timestamp(ts.unix(), 0),
        description: note,
        commission: doc(collection(db, "commissions"), commission.id),
        hidden: hidden,
      })
      closeAction(true);
    } else {
      updateDoc(doc(commUpdatesCollectionRef, existingUpdate.id), {
        ts: new Timestamp(ts.unix(), 0),
        description: note,
        hidden: hidden,
      });
      closeAction();
    }
    runUpdateData();
  }

  return (
    <>
      {isNew ? <>
        <Dialog
          open={open}
          onClose={() => { closeAction(true) }}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {`Add Commission Update Note`}
          </DialogTitle>
          <Container>
            <Typography variant="caption">{commission.commission_name}</Typography>
          </Container>
          <DialogContent>
            <Box
              sx={{
                display: "flex",
                flexDirection: "column",
                gap: 1
              }}
            >
              <TextField
                id="note-editor"
                label="Note"
                multiline
                rows={4}
                // defaultValue=""
                value={note}
                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                  setNote(event.target.value);
                }}
              />
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Timestamp"
                    value={ts}
                    onChange={(n: any) => { setTs(n) }}
                    renderInput={(params: any) => <TextField {...params} />}
                    // minDateTime={dayjs("2022-12-01")}
                    // maxDateTime={dayjs().add(1, "minute")}
                    disableFuture
                  />
                </LocalizationProvider>
                <Button onClick={() => { setTs(dayjs()) }} variant="outlined">Now</Button>
              </Box>
              <FormGroup>
                <FormControlLabel control={<Checkbox checked={hidden} onChange={() => { setHidden(!hidden) }} />} label="Private Update?" />
              </FormGroup>
            </Box>
          </DialogContent>
          <DialogActions>
            <Button onClick={() => { saveAndClose() }} autoFocus>Add and Close</Button>
            <Button onClick={() => { closeAction(true) }}>Close</Button>
          </DialogActions>

        </Dialog>
      </> : <>
        {existingUpdate && <>
          <Dialog
            open={open}
            onClose={() => { closeAction(true) }}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">
              {`Edit Commission Update Note`}
            </DialogTitle>
            <DialogContent>
              <Box
                sx={{
                  display: "flex",
                  flexDirection: "column",
                  gap: 1
                }}
              >
                <TextField
                  id="note-editor"
                  label="Note"
                  multiline
                  rows={4}
                  // defaultValue=""
                  value={note}
                  onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                    setNote(event.target.value);
                  }}
                />
                <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DateTimePicker
                      label="Timestamp"
                      value={ts}
                      onChange={(n: any) => { setTs(n) }}
                      renderInput={(params: any) => <TextField {...params} />}
                      // minDateTime={dayjs("2022-12-01")}
                      // maxDateTime={dayjs().add(1, "minute")}
                      disableFuture
                    />
                  </LocalizationProvider>
                  <Button onClick={() => { setTs(dayjs()) }} variant="outlined">Now</Button>
                </Box>
                <FormGroup>
                  <FormControlLabel control={<Checkbox checked={hidden} onChange={() => { setHidden(!hidden) }} />} label="Private Update?" />
                </FormGroup>
              </Box>
            </DialogContent>
            <DialogActions>
              <Button onClick={() => { saveAndClose() }} autoFocus>Save and Close</Button>
              <Button onClick={() => { onClose(true) }}>Close</Button>
            </DialogActions>

          </Dialog>
        </>}
      </>}
    </>
  )
}

export default UpdateEditor