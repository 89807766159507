import { Autocomplete, Box, Button, Checkbox, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, TextField, Typography } from '@mui/material'
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import AddIcon from '@mui/icons-material/Add';
import dayjs, { Dayjs } from 'dayjs';
import React, { useState } from 'react'
import { Delete } from '@mui/icons-material';
import { Timestamp, addDoc, collection, doc } from 'firebase/firestore';
import { ArtistDataRow, CharacterDataRow, CommissionDoc } from '../interfaces';
import { db } from '../firebase-config';

type Props = {
  open: boolean,
  onClose: any, // func
  availableArtists: Array<ArtistDataRow>,
  availableCharacters: Array<CharacterDataRow>,
  runUpdateData: any, // func
}

function CreateCommission(props: Props) {
  const artistsCollectionRef = collection(db, "artists");
  const charactersCollectionRef = collection(db, "characters");
  const commissionsCollectionRef = collection(db, "commissions");
  const { open, onClose, availableArtists, availableCharacters, runUpdateData } = props;

  const handleClose = (reset?: boolean) => {
    onClose();
  };

  const handleCloseAndSave = (o: CommissionDoc) => {
    addDoc(commissionsCollectionRef, o).then(() => {console.log("ADDED")}).catch((err) => console.error(`FAILED TO ADD: ${err}`));
    runUpdateData();
    handleClose();
  }

  const [artists, setArtists] = useState<Array<ArtistDataRow>>([]);
  const [characters, setCharacters] = useState<Array<CharacterDataRow>>([]);
  const [isDone, setIsDone] = useState<boolean>(false);
  const [isNsfw, setIsNsfw] = useState<boolean>(false);
  const [hidden, setHidden] = useState<boolean>(true);
  const [commissionName, setCommissionName] = useState<string>("");
  const [commissionCostUSD, setCommissionCostUSD] = useState<number>(0.00);
  const [commissionTipUSD, setCommissionTipUSD] = useState<number>(0.00);
  const [dateInit, setDateInit] = useState<Dayjs>(dayjs());
  const [datePaid, setDatePaid] = useState<Dayjs | undefined>();
  const [dateDelivered, setDateDelivered] = useState<Dayjs | undefined>();


  const handleChangeArtists = (
    event: React.ChangeEvent<{}>,
    newValue: ArtistDataRow[] | null
  ) => {
    if (newValue) {
      setArtists(newValue);
    }
  };

  const handleChangeCharacters = (
    event: React.ChangeEvent<{}>,
    newValue: CharacterDataRow[] | null
  ) => {
    if (newValue) {
      setCharacters(newValue);
    }
  };

  return (
    <>
      <Dialog
        open={open}
        onClose={() => { handleClose(true) }}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Add Commission"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Add commission
          </DialogContentText>
          <div style={{ marginBottom: "1em" }} />
          <Container>
            <Box
              component="form"
              noValidate
              autoComplete="off"
            >
              <div style={{ display: "flex", flexDirection: "column", gap: "2em" }}>
                <Box
                  component="form"
                  // sx={{
                  //   '& .MuiTextField-root': { m: 1, width: '50ch' },
                  // }}
                  noValidate
                  autoComplete="off"
                  sx={{
                    display: "flex",
                    flexDirection: 'column',
                    gap: 1,

                  }}
                >
                  <Typography>Basic Metadata</Typography>
                  <Autocomplete
                    multiple
                    id="tags-standard"
                    options={availableArtists}
                    getOptionLabel={(option) => option.artistPrimaryName}
                    // defaultValue={[]}
                    value={artists}
                    onChange={handleChangeArtists}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Artist(s)"
                        placeholder="Artists"
                      />
                    )}
                  />
                  <Autocomplete
                    multiple
                    id="tags-standard-chars"
                    options={availableCharacters}
                    getOptionLabel={(option) => option.characterName}
                    value={characters}
                    onChange={handleChangeCharacters}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        variant="standard"
                        label="Characters(s)"
                        placeholder="Characters"
                      />
                    )}
                  />
                  <TextField
                    required
                    id="new-comm-name"
                    label="Commission Title/Name"
                    defaultValue={commissionName}
                    placeholder='A Piece of Art'
                    onChange={(event) => { setCommissionName(event.target.value) }}
                  />
                  <TextField
                    required
                    id="new-comm-price"
                    label="Commission Price (USD)"
                    defaultValue={commissionCostUSD}
                    placeholder='0.00'
                    onChange={(event) => { setCommissionCostUSD(parseFloat(event.target.value)) }}
                  />
                  <TextField
                    required
                    id="new-comm-tip"
                    label="Tipped (USD)"
                    defaultValue={commissionTipUSD}
                    placeholder='0.00'
                    onChange={(event) => { setCommissionTipUSD(parseFloat(event.target.value)) }}
                  />
                  <div />
                  <div />
                  <Typography>High Level Dates</Typography>
                  <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DateTimePicker
                        label="Commission Init"
                        value={dateInit}
                        onChange={(n: any) => { setDateInit(n) }}
                        renderInput={(params: any) => <TextField {...params} />}
                        // minDateTime={dayjs("2022-12-01")}
                        // maxDateTime={dayjs().add(1, "minute")}
                        disableFuture
                      />
                    </LocalizationProvider>
                    <Button onClick={() => { setDateInit(dayjs()) }} variant="outlined">Now</Button>
                  </Box>
                  <div />
                  {!datePaid ? <>
                    <Button startIcon={<AddIcon />} onClick={() => { setDatePaid(dayjs()) }}>Add Payment Date</Button>
                  </> : <>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="Date Paid"
                          value={datePaid}
                          onChange={(n: any) => { setDatePaid(n) }}
                          renderInput={(params: any) => <TextField {...params} />}
                          // minDateTime={dayjs("2022-12-01")}
                          // maxDateTime={dayjs().add(1, "minute")}
                          disableFuture
                        />
                      </LocalizationProvider>
                      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                        <Button onClick={() => { setDatePaid(undefined) }} variant="outlined" startIcon={<Delete />}>Remove</Button>
                        <Button onClick={() => { setDatePaid(dayjs()) }} variant="outlined">Now</Button>
                      </Box>
                    </Box>
                  </>}
                  <div />
                  {!dateDelivered ? <>
                    <Button startIcon={<AddIcon />} onClick={() => { setDateDelivered(dayjs()) }}>Add Delivery Date</Button>
                  </> : <>
                    <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                      <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DateTimePicker
                          label="Date Delivered"
                          value={dateDelivered}
                          onChange={(n: any) => { setDateDelivered(n) }}
                          renderInput={(params: any) => <TextField {...params} />}
                          // minDateTime={dayjs("2022-12-01")}
                          // maxDateTime={dayjs().add(1, "minute")}
                          disableFuture
                        />
                      </LocalizationProvider>
                      <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                        <Button onClick={() => { setDateDelivered(undefined) }} variant="outlined" startIcon={<Delete />}>Remove</Button>
                        <Button onClick={() => { setDateDelivered(dayjs()) }} variant="outlined">Now</Button>
                      </Box>
                    </Box>
                  </>}
                  <div />
                  <div />
                  <Typography>Status Toggles</Typography>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={isDone} onChange={() => { setIsDone(!isDone) }} />} label="Commission Done?" />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={isNsfw} onChange={() => { setIsNsfw(!isNsfw) }} />} label="NSFW?" />
                  </FormGroup>
                  <FormGroup>
                    <FormControlLabel control={<Checkbox checked={hidden} onChange={() => { setHidden(!hidden) }} />} label="Hidden from public?" />
                    {hidden && <Typography variant="caption">This commission will be hidden on the public view page.</Typography>}
                  </FormGroup>
                </Box>
              </div>
            </Box>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {
            handleClose(true)
          }}>Cancel</Button>
          <Button onClick={() => {
            if(artists.length === 0){
              alert("Not enough artists! Add at least one!")
              return;
            }
            // requireds
            let newObj: CommissionDoc = {
              artists: artists.map((a) => doc(artistsCollectionRef, a.id)),
              commission_name: commissionName,
              costUSD: commissionCostUSD,
              dateInit: new Timestamp(dateInit.unix(), 0),
              done: isDone,
              nsfw: isNsfw,
              hide: hidden,
              linkedCharacters: characters.map((c) => doc(charactersCollectionRef, c.id)),
            };
            if (commissionTipUSD !== 0) {
              newObj.tipUSD = commissionTipUSD
            }
            if (datePaid) {
              newObj.datePaid = new Timestamp(datePaid.unix(), 0)
            }
            if (dateDelivered) {
              newObj.dateDelivered = new Timestamp(dateDelivered.unix(), 0)
            }
            handleCloseAndSave(newObj)
          }
          } autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default CreateCommission