import { Box, Button, Grid, Paper, Typography } from '@mui/material'
import { collection, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom';
import { db } from '../firebase-config';
import { CharacterItem } from '../interfaces';
import MainBody from './MainBody'

function Characters({ isAuth, showNSFW }: {isAuth: boolean, showNSFW: boolean}) {
  const charactersCollectionRef = collection(db, "characters");
  const [characters, setCharacters] = useState<Array<any>>([]);

  const runUpdate = () => {
    const getCharacters = async () => {
      if(isAuth){
        const data = await getDocs(charactersCollectionRef);
        // console.log(data);
        setCharacters(data.docs.map((doc) => ({ ...doc.data(), id: doc.id})));
      } else { // filter out docs where onlyShowIfNsfw is true
        const q = query(charactersCollectionRef, where("published", "==", true));
        const querySnapshot = await getDocs(q);
        setCharacters(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id})));
      }
    };
    getCharacters();
  };

  useEffect(() => {
    runUpdate()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainBody title={"Characters"} showNSFW={showNSFW}>
      <Box>
        <Typography variant="h2">Characters</Typography>
      </Box>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1em",
          alignItems: "center", // possibly fixme
        }}
      >
        <Grid container>
          {characters.map((char, idx) => {
            let character: CharacterItem = char; // create local copy w/ type for easy attribute access
            return (
              <Grid key={`char-${idx}`}>
                <Paper sx={{
                  padding: "1em",
                  margin: "1em",
                  width: "250px",
                  height: "250px",
                  justifyContent: "center",
                  alignItems: "center"
                }}>
                  {/* {JSON.stringify(char)} */}
                  {/* If Character Has Thumbnail, render here as the contents of the Paper */}
                  {/* Else render the character name */}
                  {character.characterThumb ? (
                    <Link to={`/characters/${character.characterName}`}>
                      <img src={character.characterThumb} alt={`Thumbnail of ${character.characterName}`} style={{ width: "100%", maxHeight: "100%", objectFit: "cover" }} />
                    </Link>
                  ) : (
                    <Button
                      variant="outlined"
                      sx={{ width: "100%", height: "100%" }}
                      component={Link}
                      to={`/characters/${character.characterName}`}
                    >
                      {character.characterName}
                    </Button>
                  )}
                </Paper>
              </Grid>
            )
          })}
        </Grid>
      </Box>
    </MainBody>
  )
}

export default Characters