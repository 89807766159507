import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import AddLink from './AddLink';
import { ArtistLinkItem } from "../interfaces";
import EditLink from './EditLink';

function EditLinks({ open, setOpen, links, updateLinks }: {open: boolean, setOpen: Function, links: Array<any>, updateLinks: Function}) {
  const [localLinks, setLocalLinks] = useState<Array<ArtistLinkItem>>(links);
  const [addLinkOpen, setAddLinkOpen] = useState<boolean>(false);
  const [editLinkOpen, setEditLinkOpen] = useState<boolean>(false);
  const [oldLinkHolder, setOldLinkHolder] = useState<ArtistLinkItem>({
    title: '',
    link: '',
  });

  const handleClose = () => {
    setOpen(false);
    setLocalLinks(links)
  };

  const handleSaveAndClose = () => {
    updateLinks(localLinks);
    handleClose();
  };

  const removeLink = (linkToRemove: ArtistLinkItem) => {
    let newLinks = [...localLinks];
    let idxOfLinkToRemove = newLinks.indexOf(linkToRemove);
    if (idxOfLinkToRemove !== -1) {
      newLinks.splice(idxOfLinkToRemove, 1);
    }
    setLocalLinks(newLinks);
  };

  useEffect(() => {
    setLocalLinks(links);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateLocalArtistLinks = (newLink: ArtistLinkItem) => {
    let newLinks = [...localLinks];
    newLinks.push(newLink);
    setLocalLinks(newLinks);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Edit Links"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Edit links for the artist.
          </DialogContentText>
          <List>
            {localLinks.map((lnk, idx) => (
              <ListItem
                key={`artist-link-id-${idx}`}
                secondaryAction={
                  <div>
                    <IconButton edge="end" aria-label="edit" onClick={() => {setOldLinkHolder(lnk); setEditLinkOpen(true)}}>
                      <EditIcon />
                    </IconButton>
                    <IconButton edge="end" aria-label="delete" onClick={() => {removeLink(lnk)}}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                }
              >
                {/* <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar> */}
                <ListItemText
                  primary={lnk.title}
                  secondary={lnk.subtitle || null}
                />
              </ListItem>
            ))}
            {localLinks.length > 0 && <Divider />}
            <ListItem key="add-new" disablePadding>
              <ListItemButton onClick={() => {setAddLinkOpen(true)}}>
                <ListItemText primary="Add New Link" />
              </ListItemButton>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveAndClose} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <EditLink open={editLinkOpen} setOpen={setEditLinkOpen} oldLink={oldLinkHolder} handleEditLink={(oldLink: ArtistLinkItem, newLink: ArtistLinkItem) => {
        let idxToUpdate = localLinks.indexOf(oldLink);
        localLinks[idxToUpdate] = newLink;
      }} />
      <AddLink open={addLinkOpen} setOpen={setAddLinkOpen} addLink={handleUpdateLocalArtistLinks} />
    </div>
  )
}

export default EditLinks