import { Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'
import MainBody from './MainBody'

function CharacterNotFound() {
  return (
    <MainBody title={"Character Not Found"}>
      <Typography variant="h3">Uh-oh...</Typography>
      <Typography variant="h4">Sorry! I couldn't find that character.</Typography>
      <Typography variant="body1">Check the link and try again, or use the <Link to="/characters" style={{ color: "inherit" }}>characters</Link> page to navigate.</Typography>
    </MainBody>
  )
}

export default CharacterNotFound