import dayjs from "dayjs";
import RelativeTime from "dayjs/plugin/relativeTime";
import Duration from "dayjs/plugin/duration";
dayjs.extend(RelativeTime);
dayjs.extend(Duration);

function humanizeTimeDifference(date1: any, date2: any) {
  const d1 = dayjs(date1);
  const d2 = dayjs(date2);

  const diffInMilliseconds = Math.abs(d1.diff(d2));

  // Initialize variables to store the most appropriate unit and value
  let unit = 'millisecond';
  let value = diffInMilliseconds;

  // Use Day.js duration plugin to get an object with all the components
  const diffDuration = dayjs.duration(diffInMilliseconds);

  if (diffDuration.asDays() >= 1) {
    unit = 'day';
    value = diffDuration.days() + diffDuration.months() * 30 + diffDuration.years() * 365;
  } else if (diffDuration.asHours() >= 1) {
    unit = 'hour';
    value = diffDuration.hours();
  } else if (diffDuration.asMinutes() >= 1) {
    unit = 'minute';
    value = diffDuration.minutes();
  } else if (diffDuration.asSeconds() >= 1) {
    unit = 'second';
    value = diffDuration.seconds();
  }

  return `${value} ${unit}${value === 1 ? '' : 's'}`;
}

export { humanizeTimeDifference };