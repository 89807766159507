import { Avatar, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText } from '@mui/material'
import { useEffect, useState } from 'react'
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import { CharacterColor, CharacterDataRow } from "../interfaces";
import EditColor from './EditColor';
import AddColor from './AddColor';
import CircleIcon from '@mui/icons-material/Circle';

function EditColors({ open, setOpen, colors, updateColors, characters,
  setCharacters,
  editCharacterHandler,
  data,
  runUpdate, }: {open: boolean, setOpen: Function, colors: Array<any>, updateColors: Function,
    characters: Array<any> | undefined,
    setCharacters: Function,
    editCharacterHandler: Function
    data: CharacterDataRow,
    runUpdate: Function
  }) {
  const [localColors, setLocalColors] = useState<Array<CharacterColor>>(colors);
  const [addColorOpen, setAddColorOpen] = useState<boolean>(false);
  const [editColorOpen, setEditColorOpen] = useState<boolean>(false);
  const [oldColorHolder, setOldColorHolder] = useState<CharacterColor>({
    label: '',
    hex: '',
  });

  const handleClose = () => {
    setOpen(false);
    setLocalColors(colors)
  };

  const handleSaveAndClose = () => {
    let newCharacters: Array<CharacterDataRow> = [...characters || []];
    let idxToUpdate = newCharacters.indexOf(newCharacters.filter((i) => i.id === data.id)[0]);
    newCharacters[idxToUpdate].characterColors = localColors;
    setCharacters(newCharacters);
    editCharacterHandler(data, runUpdate);
    updateColors(localColors);
    handleClose();
  };

  const removeColor = (colorToRemove: CharacterColor) => {
    let newColors = [...localColors];
    let idxOfColorToRemove = newColors.indexOf(colorToRemove);
    if (idxOfColorToRemove !== -1) {
      newColors.splice(idxOfColorToRemove, 1);
    }
    setLocalColors(newColors);
  };

  useEffect(() => {
    setLocalColors(colors);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateLocalColors = (newColor: CharacterColor) => {
    let newColors = [...localColors];
    newColors.push(newColor);
    setLocalColors(newColors);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Edit Colors"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Edit character colors.
          </DialogContentText>
          <List>
            {localColors.map((c, idx) => (
              <ListItem
                key={`color-id-${idx}`}
                secondaryAction={
                  <div>
                    <IconButton edge="end" aria-label="edit" onClick={() => {setOldColorHolder(c); setEditColorOpen(true)}}>
                      <EditIcon />
                    </IconButton>
                    <IconButton edge="end" aria-label="delete" onClick={() => {removeColor(c)}}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                }
              >
                <ListItemAvatar>
                  <Avatar>
                    <CircleIcon sx={{ color: c.hex }} />
                  </Avatar>
                </ListItemAvatar>
                <ListItemText
                  primary={c.label}
                  secondary={c.hex}
                />
              </ListItem>
            ))}
            {localColors.length > 0 && <Divider />}
            <ListItem key="add-new" disablePadding>
              <ListItemButton onClick={() => {setAddColorOpen(true)}}>
                <ListItemText primary="Add New Color" />
              </ListItemButton>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveAndClose} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <EditColor open={editColorOpen} setOpen={setEditColorOpen} oldColor={oldColorHolder} handleEditColor={(oldColor: CharacterColor, newColor: CharacterColor) => {
        let idxToUpdate = localColors.indexOf(oldColor);
        localColors[idxToUpdate] = newColor;
      }} />
      <AddColor open={addColorOpen} setOpen={setAddColorOpen} addColor={handleUpdateLocalColors} />
    </div>
  )
}

export default EditColors