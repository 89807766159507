import { Box, Button, emphasize, Grid, List, ListItem, ListItemIcon, ListItemText, Paper, Tooltip, Typography } from '@mui/material';
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { db } from '../firebase-config';
import { CharacterColor, CharacterDataRow } from '../interfaces';
import FavoriteIcon from '@mui/icons-material/Favorite';
import HeartBrokenIcon from '@mui/icons-material/HeartBroken';
import InfoIcon from '@mui/icons-material/Info';
import LinkIcon from '@mui/icons-material/Link';
import RecommendIcon from '@mui/icons-material/Recommend';
import CreateIcon from '@mui/icons-material/Create';
import SportsBaseballIcon from '@mui/icons-material/SportsBaseball';
import FavoriteBorderIcon from '@mui/icons-material/FavoriteBorder';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import SpeakerNotesIcon from '@mui/icons-material/SpeakerNotes';
import MainBody from './MainBody';
// import { Dayjs } from 'dayjs';
import dayjs from 'dayjs';
import RelativeTime from 'dayjs/plugin/relativeTime'
import CharacterNotFound from './CharacterNotFound';
import Gallery from '../components/Gallery';
var config = {
  rounding: Math.floor
}
dayjs.extend(RelativeTime, config)

function CharacterView({ isAuth, showNSFW, setShowNSFW, isSpecialAccessProvisioned }: {isAuth: boolean, showNSFW: boolean, setShowNSFW: Function, isSpecialAccessProvisioned?: boolean}) {
  let { charNameFromUrl } = useParams();
  const charactersCollectionRef = collection(db, "characters");
  const [character, setCharacter] = useState<CharacterDataRow|any>("Loading...");
  const [copyColorClicked, setCopyColorClicked] = useState<boolean>(false);
  const [refArtistData, setRefArtistData] = useState<any>(null);
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [refArtistDataNSFW, setRefArtistDataNSFW] = useState<any>(null);

  const runUpdateArtistData = () => {
    if(character.characterRefSheet){
      try {
        getDoc(character.characterRefSheet.artist).then(res => {
          // console.log(res.data())
          let artistData = res.data();
          setRefArtistData(artistData);
        })
      } catch (e) {
        getDoc(doc(db, character.characterRefSheet.artist)).then(res => {
          // console.log(res.data())
          let artistData = res.data();
          setRefArtistData(artistData);
        })
      }
    }
    if(character.nsfwDetails.characterRefSheetNSFW){
      try {
        getDoc(character.nsfwDetails.characterRefSheetNSFW.artist).then(res => {
          // console.log(res.data())
          let artistData = res.data();
          setRefArtistDataNSFW(artistData);
        })
      } catch (e) {
        getDoc(doc(db, character.nsfwDetails.characterRefSheetNSFW.artist)).then(res => {
          // console.log(res.data())
          let artistData = res.data();
          setRefArtistDataNSFW(artistData);
        })
      }
    }
  };

  const runUpdate = () => {
    const getCharacters = async () => {
      if(isAuth || isSpecialAccessProvisioned){
        const q = query(charactersCollectionRef, where("characterName", "==", charNameFromUrl));
        const querySnapshot = await getDocs(q);
        setCharacter(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}))[0] || null);
      } else {
        const q = query(charactersCollectionRef, where("published", "==", true), where("characterName", "==", charNameFromUrl));
        const querySnapshot = await getDocs(q);
        setCharacter(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id}))[0] || null);
      }
    };
    getCharacters();
  };

  useEffect(() => {
    try {
      runUpdate()
    } catch (e) {
      // console.error(e);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    try {
      runUpdateArtistData()
    } catch (e) {
      // console.error(e);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [character])

  const ageTxt = character ? `${Math.abs(Math.trunc(dayjs(character.characterBirthdate).diff(dayjs(), 'year', true)))} years` : '';
  
  return (
    <MainBody showNSFW={showNSFW} title={character ? `${character.characterName}'s Ref Page` : "Loading..."}>
      {!character ? <CharacterNotFound /> : character === "Loading..." ? "Loading..." : <Box>
        {/* {JSON.stringify(character)} */}
        <Box sx={{
          display: "flex",
          flexDirection: "column",
          // gap: "1em",
          alignItems: "center"
        }}>
          <Typography variant="h3">{character.characterName}</Typography>
          <Typography variant="h4">NO AI - Use of this character (or use of any images containing this character) for training image generation models is prohibited.</Typography>
          {/* TODO: Pride Flag Icons Here */}
          <Typography variant="h5">Species: {character.characterSpecies}</Typography>
          <Typography variant="h6">{character.characterPronouns.join("/")}</Typography>
          <Typography variant="h6">Orientation: {character.characterOrientation}</Typography>
          {/* <Typography variant="h6">Age: {dayjs(character.characterBirthdate).toNow(true)}</Typography> */}
          <Typography variant="h6">Age: {ageTxt}</Typography>
          
          <Paper sx={{ width: "100%", padding: "1em", margin: "1em" }}>
            {/* Colors Grid */}
            <Box sx={{ display: "flex", flexDirection: "column", gap: "1em" }}>
              <Box>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  {character.characterColors.map((color: CharacterColor, idx: any) => {
                    let defaultChipLabel = `Click to copy ${color.label} (${color.hex})`;
                    const handleCopyClick = () => {setCopyColorClicked(true)};
                    const handleMouseLeave = () => {setCopyColorClicked(false)};
                    return (
                      <Grid item key={`color-${idx}`} xs={12} sm={4} md={4} lg={2}>
                        <Tooltip title={copyColorClicked ? "Copied!" : defaultChipLabel} >
                          <Button
                            fullWidth
                            id={color.hex}
                            component={Paper} sx={{
                              padding: "1em",
                              // textShadow: `1px 1px ${emphasize(color.hex, 1)}`,
                              color: `${emphasize(color.hex, 1)}`,
                              backgroundColor: color.hex,
                              // width: "100%",
                              textAlign: "left",
                              justifyContent: "flex-start"
                            }}
                            onClick={() => {
                              navigator.clipboard.writeText(color.hex.slice(1)); // cut off the '#' from the start of hex
                              handleCopyClick();
                            }} onMouseLeave={handleMouseLeave}
                          >
                            <Box sx={{ display: "flex", flexDirection: "column" }}>
                              <Typography>{color.label}</Typography>
                              <Typography>{color.hex}</Typography>
                            </Box>
                          </Button>
                        </Tooltip>
                      </Grid>
                    )
                  })}
                </Grid>
              </Box>
              {/* Ref Sheet */}
              {!showNSFW ?
                (character.characterRefSheet ?
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center"
                    }}
                  >
                    <img
                      alt="Ref Sheet"
                      src={character.characterRefSheet.image}
                      style={{ width: "100%" }}
                    />
                    {refArtistData ?
                      <Typography variant="body1">
                        Reference Sheet by <a
                          href={refArtistData.artistPrimaryLink}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            // textDecoration: "none",
                            color: "inherit"
                          }}
                        >
                          {refArtistData.artistPrimaryName}
                        </a>
                      </Typography>
                    : ""}
                  </Box>
                :
                  <Typography variant="body1">{"Ref Sheet Missing :("}</Typography>
                )
              :
                (character.nsfwDetails.characterRefSheetNSFW ?
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center"
                    }}
                  >
                    <img
                      alt="NSFW Ref Sheet"
                      src={character.nsfwDetails.characterRefSheetNSFW.image}
                      style={{ width: "100%" }}
                    />
                    {refArtistDataNSFW ?
                      <Typography variant="body1">
                        Alternate Reference Sheet by <a
                          href={refArtistDataNSFW.artistPrimaryLink}
                          target="_blank"
                          rel="noreferrer"
                          style={{
                            // textDecoration: "none",
                            color: "inherit"
                          }}
                        >
                          {refArtistDataNSFW.artistPrimaryName}
                        </a>
                      </Typography>
                    : ""}
                  </Box>
                :
                  <Box
                    sx={{
                      display: "flex",
                      flexDirection: "column"
                    }}
                  >
                    {/* <Typography variant="h4" color={"error"} onClick={() => {setShowNSFW(false)}}>{"NSFW Ref Sheet Not Available :( Click to swap to SFW view"}</Typography>
                    <Box>{character.characterRefSheet && character.characterRefSheet.image && <img onClick={() => {setShowNSFW(false)}} alt="sfw ref" src={character.characterRefSheet.image} style={{ height: "auto", width: "100%" }} />}</Box>
                    <Typography variant="h4" color={"error"} onClick={() => {setShowNSFW(false)}}>{"NSFW Ref Sheet Not Available :( Click to swap to SFW view"}</Typography> */}
                    <Box>{character.characterRefSheet && character.characterRefSheet.image && <img alt="sfw ref" src={character.characterRefSheet.image} style={{ height: "auto", width: "100%" }} />}</Box>
                    <Typography variant="caption" color={"error"}>{"NSFW Ref Sheet Not Available :( to view NSFW art as a reference, scroll down!"}</Typography>
                  </Box>
                )
              }
              <Box>
                <Grid container rowSpacing={1} columnSpacing={1}>
                  {showNSFW && character.nsfwDetails && character.nsfwDetails.anatomy && <Grid item xs={12} lg={6}>
                      <Paper elevation={2} sx={{ padding: "1em" }}>
                        <Box>
                          <Typography variant={"h6"}>NSFW Anatomy</Typography>
                          <List>
                            {character.nsfwDetails.anatomy.penis && <ListItem>
                              <ListItemIcon>
                                <CreateIcon />
                              </ListItemIcon>
                              <ListItemText>
                                <Typography>{character.nsfwDetails.anatomy.penis.length} inch {character.nsfwDetails.anatomy.penis.intact ? "intact (uncircumcised)" : "cut (circumcised)"} {character.nsfwDetails.anatomy.penis.species} penis</Typography>
                              </ListItemText>
                            </ListItem>}
                            {character.nsfwDetails.anatomy.balls && <ListItem>
                              <ListItemIcon>
                                <SportsBaseballIcon />
                              </ListItemIcon>
                              <ListItemText>
                                <Typography>Has balls{character.nsfwDetails.anatomy.balls.description.length > 0 ? `: ${character.nsfwDetails.anatomy.balls.description}` : ""}</Typography>
                              </ListItemText>
                            </ListItem>}
                            {character.nsfwDetails.anatomy.breasts && <ListItem>
                              <ListItemIcon>
                                <FavoriteBorderIcon />
                              </ListItemIcon>
                              <ListItemText>
                                <Typography>{character.nsfwDetails.anatomy.breasts.style === "standard" ? "Two" : "Six"} {!character.nsfwDetails.anatomy.breasts.symmetric && "a"}symmetric {character.nsfwDetails.anatomy.breasts.size && character.nsfwDetails.anatomy.breasts.size.cup + " cup"} breasts</Typography>
                              </ListItemText>
                            </ListItem>}
                            {character.nsfwDetails.anatomy.vagina && <ListItem>
                              <ListItemIcon>
                                <ImportContactsIcon />
                              </ListItemIcon>
                              <ListItemText>
                                {/* // update anatomical language for display; db and interface migration later */}
                                {/* <Typography>Has Vagina{character.nsfwDetails.anatomy.vagina.description.length > 0 ? `: ${character.nsfwDetails.anatomy.vagina.description}` : ""}</Typography> */}
                                <Typography>Has Vulva{character.nsfwDetails.anatomy.vagina.description.length > 0 ? `: ${character.nsfwDetails.anatomy.vagina.description}` : ""}</Typography>
                              </ListItemText>
                            </ListItem>}
                          </List>
                        </Box>
                      </Paper>
                    </Grid>
                  }
                  {
                    (showNSFW && character.nsfwDetails && character.nsfwDetails.spicyLikes && character.nsfwDetails.spicyLikes.length > 0) && 
                    <Grid item xs={12} lg={6}>
                      <Paper elevation={2} sx={{ padding: "1em" }}>
                        <Box>
                          <Typography variant={"h6"}>Notable Kinks/Fetishes</Typography>
                          <List>
                            {character.nsfwDetails.spicyLikes.map((item: string, idx: any) => {
                              return (
                                <ListItem key={`item-${idx}`}>
                                  <ListItemIcon>
                                    <RecommendIcon />
                                  </ListItemIcon>
                                  <ListItemText>
                                    {item}
                                  </ListItemText>
                                </ListItem>
                              )
                            })}
                          </List>
                        </Box>
                      </Paper>
                    </Grid>
                  }

                  {character.characterPhysical ? (<>
                    <Grid item xs={12} lg={6}>
                      <Paper elevation={2} sx={{ padding: "1em" }}>
                        <Box>
                          <Typography variant={"h6"}>Physical Details</Typography>
                          <List>
                            {character.characterPhysical.height && <ListItem>
                              <ListItemIcon>
                                <CreateIcon />
                              </ListItemIcon>
                              <ListItemText>
                                <Typography>Height: {character.characterPhysical.height}</Typography>
                              </ListItemText>
                            </ListItem>}
                            {character.characterPhysical.weight && <ListItem>
                              <ListItemIcon>
                                <CreateIcon />
                              </ListItemIcon>
                              <ListItemText>
                                <Typography>Weight: {character.characterPhysical.weight}</Typography>
                              </ListItemText>
                            </ListItem>}
                          </List>
                        </Box>
                      </Paper>
                    </Grid>
                  </>) : ""}

                  {character.characterDetails ? (<>

                    {
                        character.characterDetails.notes && character.characterDetails.notes.length > 0 && 
                        <Grid item xs={12} lg={6}>
                          <Paper elevation={2} sx={{ padding: "1em" }}>
                            <Box>
                              <Typography variant={"h6"}>Notes</Typography>
                              <List>
                                {character.characterDetails.notes.map((item: string, idx: any) => {
                                  return (
                                    <ListItem key={`item-${idx}`}>
                                      <ListItemIcon>
                                        <SpeakerNotesIcon />
                                      </ListItemIcon>
                                      <ListItemText>
                                        {item}
                                      </ListItemText>
                                    </ListItem>
                                  )
                                })}
                              </List>
                            </Box>
                          </Paper>
                        </Grid>
                      }
                    
                      {
                        character.characterDetails.likes && character.characterDetails.likes.length > 0 && 
                        <Grid item xs={12} lg={6}>
                          <Paper elevation={2} sx={{ padding: "1em" }}>
                            <Box>
                              <Typography variant={"h6"}>Likes</Typography>
                              <List>
                                {character.characterDetails.likes.map((item: string, idx: any) => {
                                  return (
                                    <ListItem key={`item-${idx}`}>
                                      <ListItemIcon>
                                        <FavoriteIcon />
                                      </ListItemIcon>
                                      <ListItemText>
                                        {item}
                                      </ListItemText>
                                    </ListItem>
                                  )
                                })}
                              </List>
                            </Box>
                          </Paper>
                        </Grid>
                      }

                      {
                        character.characterDetails.dislikes && character.characterDetails.dislikes.length > 0 && 
                        <Grid item xs={12} lg={6}>
                          <Paper elevation={2} sx={{ padding: "1em" }}>
                            <Box>
                              <Typography variant={"h6"}>Dislikes</Typography>
                              <List>
                                {character.characterDetails.dislikes.map((item: string, idx: any) => {
                                  return (
                                    <ListItem key={`item-${idx}`}>
                                      <ListItemIcon>
                                        <HeartBrokenIcon />
                                      </ListItemIcon>
                                      <ListItemText>
                                        {item}
                                      </ListItemText>
                                    </ListItem>
                                  )
                                })}
                              </List>
                            </Box>
                          </Paper>
                        </Grid>
                      }

                      {
                        character.characterDetails.traits && character.characterDetails.traits.length > 0 && 
                        
                        <Grid item xs={12} lg={6}>
                          <Paper elevation={2} sx={{ padding: "1em" }}>
                            <Box>
                              <Typography variant={"h6"}>Traits</Typography>
                              <List>
                                {character.characterDetails.traits.map((item: string, idx: any) => {
                                  return (
                                    <ListItem key={`item-${idx}`}>
                                      <ListItemIcon>
                                        <InfoIcon />
                                      </ListItemIcon>
                                      <ListItemText>
                                        {item}
                                      </ListItemText>
                                    </ListItem>
                                  )
                                })}
                              </List>
                            </Box>
                          </Paper>
                        </Grid>
                      }

                      {
                        character.characterDetails.relationships && character.characterDetails.relationships.length > 0 && 
                        <Grid item xs={12} lg={6}>
                          <Paper elevation={2} sx={{ padding: "1em" }}>
                            <Box>
                              <Typography variant={"h6"}>Relationships</Typography>
                              <List>
                                {character.characterDetails.relationships.map((item: string, idx: any) => {
                                  return (
                                    <ListItem key={`item-${idx}`}>
                                      <ListItemIcon>
                                        <LinkIcon />
                                      </ListItemIcon>
                                      <ListItemText>
                                        {item}
                                      </ListItemText>
                                    </ListItem>
                                  )
                                })}
                              </List>
                            </Box>
                          </Paper>
                        </Grid>
                      }
                  </>) : ""}
                </Grid>
              </Box>
            </Box>
          </Paper>

          {/* <Paper sx={{ width: "100%", padding: "1em", margin: "1em" }}> */}
          <Gallery isAuth={isAuth} showNSFW={showNSFW} character={character} />
          {/* </Paper> */}
        </Box>
      </Box>}

      <div style={{ marginBottom: "1em" }}></div>
      
    </MainBody>
  )
}

export default CharacterView