import { collection, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router-dom';
import { db } from '../firebase-config';
import CharacterNotFound from './CharacterNotFound';
import CharacterView from './CharacterView';

function CharacterViewSecret({ isAuth, showNSFW, setShowNSFW, isSpecialAccessProvisioned, setIsSpecialAccessProvisioned, setSpecialAccessStr }: {isAuth: boolean, showNSFW: boolean, setShowNSFW: Function, isSpecialAccessProvisioned: boolean, setIsSpecialAccessProvisioned: Function, setSpecialAccessStr: Function}) {
  let { secret, charNameFromUrl } = useParams(); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [validSecret, setValidSecret] = useState<boolean|null>();
  const [pending, setPending] = useState<boolean>(true);

  const runUpdate = () => {
    const collectionRef = collection(db, "sharingKeys");
    const getAvailable = async () => {
      const data = await getDocs(collectionRef);
      let av: any = data.docs.map((doc) => ({ ...doc.data(), id: doc.id}));
      let kz = av.map((i: { id: any; })=>i.id);
      // console.log(kz)
      let pos = av.findIndex((i: { id: string; }) => i.id === secret);
      let check = kz.includes(secret || "");
      if(check){
        if (av[pos].allowedChars){
          if (av[pos].allowedChars.includes(charNameFromUrl)){
            setIsSpecialAccessProvisioned(true);
            setValidSecret(true);
            try {
              setSpecialAccessStr(av[pos].for)
            } catch {
              // do nothing
            }
          }
        } else {
          setIsSpecialAccessProvisioned(true);
          setValidSecret(true);
          try {
            setSpecialAccessStr(av[pos].for)
          } catch {
            // do nothing
          }
        }
      } else {
        setIsSpecialAccessProvisioned(false);
        setValidSecret(false);
        setSpecialAccessStr("");
      }
      setPending(false);
    };
    getAvailable();
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {runUpdate()}, []);

  return (
    <>{pending ? <div>Loading...</div> : validSecret ? <div><CharacterView isAuth={isAuth} showNSFW={showNSFW} setShowNSFW={setShowNSFW} isSpecialAccessProvisioned={isSpecialAccessProvisioned} /></div> : <div><CharacterNotFound /></div>}</>
  )
}

export default CharacterViewSecret