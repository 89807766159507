import React from 'react'

import { createTheme, ThemeOptions, ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import "./Page.css";

declare module '@mui/material/styles' {
  interface Theme {
    status: {
      danger: string;
    };
  }
  // allow configuration using `createTheme`
  interface ThemeOptions {
    status?: {
      danger?: string;
    };
  }
}

export const themeOptions: ThemeOptions = {
  palette: {
    mode: 'dark',
    primary: {
      main: '#34609f',
    },
    secondary: {
      main: '#6df0c9',
    },
    background: {
      default: '#132e47', // astra
      // default: '#012e56', // msg
    },
  },
  components: {
    MuiChip: {
      styleOverrides: {
        clickable: ({ ownerState, theme }) => ({
          '&:hover, &:focus': {
              // backgroundColor: ownerState.style?.backgroundColor
              backgroundColor: ownerState.id
          },
        }),
      }
    },
    MuiButton: {
      styleOverrides: {
        root: ({ ownerState, theme }) => ({
          '&:hover': {
              // backgroundColor: ownerState.style?.backgroundColor
              backgroundColor: ownerState.id
          },
        })
      }
    }
  },
};

const theme = createTheme(themeOptions);

function Page(props: React.PropsWithChildren) {
  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      {props.children}
    </ThemeProvider>
  )
}

export default Page