import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, List } from '@mui/material';
import React, { useEffect, useState } from 'react'
import { CommissionDataRow } from '../interfaces';
import { collection, doc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase-config';
import UpdatesListItem from './UpdatesListItem';
import UpdateEditor from './UpdateEditor';

type Props = {
  open: boolean,
  onClose: any, // func
  commission: CommissionDataRow,
  runUpdateData: any, // func
}

function EditUpdatesManager(props: Props) {
  const { open, onClose, commission, runUpdateData } = props;
  const [updates, setUpdates] = useState<Array<any>>([]);
  const [newUpdateEditorOpen, setNewUpdateEditorOpen] = useState<boolean>(false);

  const runUpdate = () => {
    const getUpdatesForComm = (commission: CommissionDataRow) => {
      let updates: Array<any> = [];
      if (commission) {
        if (commission.id) {
          const commUpdatesCollectionRef = collection(db, "commissionHistoryItems");
          const relatedUpdatesQuery = query(commUpdatesCollectionRef, where("commission", "==", doc(collection(db, "commissions"), commission.id)))
          getDocs(relatedUpdatesQuery).then((snapshot) => {
            snapshot.forEach((doc) => updates.push({ id: doc.id, note: doc.data().description, ts: doc.data().ts }));
          }).finally(() => {
            updates = updates.sort((a, b) => b.ts.seconds - a.ts.seconds);
            setUpdates(updates);
          })
        }
      }
    };
    if (commission) {
      getUpdatesForComm(commission);
    }
  };

  useEffect(() => {
    runUpdate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [commission]);

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        {/* {commission?.commission_name}
      {JSON.stringify(updates)} */}
        <DialogTitle id="alert-dialog-title">
          {`Commission Updates for ${commission?.commission_name}`}
        </DialogTitle>
        <DialogContent>
          <Box sx={{ display: 'flex', flexDirection: 'column' }}>
            <Button variant="outlined" onClick={() => { setNewUpdateEditorOpen(true) }}>Log New Update</Button>
          </Box>
          <List>
            {updates.map((update, updateIdx) => {
              return (
                <UpdatesListItem key={updateIdx} update={update} commission={commission} runUpdateData={runUpdateData} />
              )
            })}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => { onClose() }}>Close</Button>
        </DialogActions>

      </Dialog>
      <UpdateEditor isNew runUpdateData={runUpdateData} open={newUpdateEditorOpen} onClose={() => {setNewUpdateEditorOpen(false)}} commission={commission} />
    </>
  )
}

export default EditUpdatesManager