import { Button, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import MainBody from './MainBody'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';


import { addDoc, collection } from "firebase/firestore";
import { db } from '../firebase-config';
import { ArtistDoc } from "../interfaces";
// import { useNavigate } from 'react-router-dom';

function CreateArtist({ isAuth, onAddCallback }: {isAuth: boolean, onAddCallback: Function}) {
  const [newArtistPrimaryName, setNewArtistPrimaryName] = useState<string>("");
  const [newArtistPrimaryLink, setNewArtistPrimaryLink] = useState<string>("");
  const [onlyShowIfNsfw, setOnlyShowIfNsfw] = useState<boolean>(false);

  // let navigate = useNavigate();

  const artistsCollectionRef = collection(db, "artists");
  const createArtist = async () => {
    let newDoc: ArtistDoc = {
      artistPrimaryName: newArtistPrimaryName,
      artistPrimaryLink: newArtistPrimaryLink,
      onlyShowIfNsfw: onlyShowIfNsfw,
    }
    await addDoc(artistsCollectionRef, newDoc);
    onAddCallback();
  };

  return (
    <MainBody>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h3">Create Artist</Typography>
        <Typography variant="caption">Add a new artist to the database for use in art credits and/or commission waitlist items.</Typography>
        <Box
          sx={{
            // textAlign: "center"
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            gap: "1em",
          }}
        >
          <Box
            component="form"
            // sx={{
            //   '& .MuiTextField-root': { m: 1, width: '50ch' },
            // }}
            noValidate
            autoComplete="off"
          >
            <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
              <TextField
                required
                id="new-artist-primary-name"
                label="Primary Artist Name"
                // defaultValue=""
                placeholder='AstraBun'
                onChange={(event) => {setNewArtistPrimaryName(event.target.value)}}
              />
              <TextField
                required
                id="new-artist-primary-link"
                label="Primary Artist Link"
                // defaultValue=""
                placeholder='https://twitter.com/astra_bun'
                onChange={(event) => {setNewArtistPrimaryLink(event.target.value)}}
              />
              <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked={onlyShowIfNsfw} onChange={() => {setOnlyShowIfNsfw(!onlyShowIfNsfw)}} />} label="Only show if NSFW?" />
                {onlyShowIfNsfw && <Typography variant="caption">This artist will be hidden unless the user enters NSFW mode.</Typography>}
              </FormGroup>
            </div>
            <Button onClick={createArtist}>Create Artist</Button>
          </Box>
        </Box>
      </Box>
    </MainBody>
  )
}

export default CreateArtist