import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material'
import React, { useEffect, useState } from 'react'
import { ArtistLinkItem } from "../interfaces";

function AddLink({ open, setOpen, addLink }: {open: boolean, setOpen: Function, addLink: Function}) {
  const [title, setTitle] = useState<string>("");
  const [subtitle, setSubtitle] = useState<string|null>(null);
  const [link, setLink] = useState<string>("");
  
  const handleClose = () => {
    setOpen(false);
  };

  const handleSaveAndClose = () => {
    let newLink: ArtistLinkItem = {
      title: title,
      link: link
    };
    if(subtitle !== null) {
      newLink.subtitle = subtitle;
    }
    addLink(newLink);
    handleClose();
  };

  useEffect(() => {
    setTitle("");
    setSubtitle(null);
    setLink("");
  }, [open]);

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Add Link"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Add New Link
          </DialogContentText>
          <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
            <TextField
              required
              id="new-link-title"
              label="Link Title"
              placeholder='Twitter'
              onChange={(event) => {setTitle(event.target.value)}}
            />
            <TextField
              id="new-link-subtitle"
              label="Link Subtitle (Optional)"
              placeholder='The Epic Tweeting Website'
              onChange={(event) => {setSubtitle(event.target.value)}}
            />
            <TextField
              required
              id="new-link-url"
              label="URL"
              placeholder='https://twitter.com/astra_bun'
              onChange={(event) => {setLink(event.target.value)}}
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveAndClose} autoFocus>
            Add
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AddLink