import { Button, Chip, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useState } from 'react'
import MainBody from './MainBody'
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';
import { Dayjs } from 'dayjs';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import { addDoc, collection } from "firebase/firestore";
import { db } from '../firebase-config';
import { CharacterColor, CharacterItem } from "../interfaces";
import EditPronouns from '../components/EditPronouns';

function CreateCharacter({ isAuth, onAddCallback }: {isAuth: boolean, onAddCallback: Function}) {
  const [newCharacterName, setNewCharacterName] = useState<string>("");
  const [newCharacterBirthdate, setNewCharacterBirthdate] = useState<Dayjs | null>(null);
  const [newCharacterPronouns, setNewCharacterPronouns] = useState<Array<string>>([]);
  const [newCharacterSpecies, setNewCharacterSpecies] = useState<string>("");
  const [newCharacterOrientation, setNewCharacterOrientation] = useState<string>("");
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [newCharacterColors, setNewCharacterColors] = useState<Array<CharacterColor>>([]);
  const [editPronounsOpen, setEditPronounsOpen] = useState<boolean>(false);
  const [newCharacterPublished, setNewCharacterPublished] = useState<boolean>(false);
  
  const charactersCollectionRef = collection(db, "characters");
  const createCharacter = async () => {
    let newDoc: CharacterItem = {
      characterName: newCharacterName,
      characterBirthdate: newCharacterBirthdate?.startOf('day').toISOString().split("T")[0] || "",
      characterPronouns: newCharacterPronouns,
      characterSpecies: newCharacterSpecies,
      characterOrientation: newCharacterOrientation,
      characterColors: newCharacterColors,
      published: newCharacterPublished,
      characterDetails: {},
      nsfwDetails: {},
      characterPhysical: {},
    }
    await addDoc(charactersCollectionRef, newDoc);
    onAddCallback();
  };

  return (
    <MainBody>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h3">Create Character</Typography>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
          }}
        >
          <Typography variant="caption">Add a new character to the database.</Typography>
          <Typography variant="caption">Additional details available on edit page.</Typography>
        </Box>
        <Box
          sx={{
            // textAlign: "center"
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            gap: "1em",
          }}
        >
          <Box
            component="form"
            // sx={{
            //   '& .MuiTextField-root': { m: 1, width: '50ch' },
            // }}
            noValidate
            autoComplete="off"
          >
            <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
              <TextField
                required
                id="new-character-primary-name"
                label="Character Name"
                // defaultValue=""
                placeholder='Astra'
                onChange={(event) => {setNewCharacterName(event.target.value)}}
              />
              <LocalizationProvider dateAdapter={AdapterDayjs}>
                <DatePicker
                  label="Character Birthday"
                  value={newCharacterBirthdate}
                  onChange={(newValue) => {
                    setNewCharacterBirthdate(newValue);
                  }}
                  renderInput={(params) => <TextField {...params} />}
                />
              </LocalizationProvider>
              <Box
                sx={{
                  // textAlign: "center"
                  alignItems: "center",
                  display: "flex",
                  flexDirection: "row",
                  gap: "1em",
                }}
              >
                <Typography>Pronouns:</Typography>
                {newCharacterPronouns.map((p, idx) => {
                  return (
                    <Chip key={`pronoun-list-item-${idx}`} label={p} />
                  )
                })}
              </Box>
              <Button onClick={() => {setEditPronounsOpen(true)}}>Edit Pronouns</Button>

              <TextField
                required
                id="new-character-species"
                label="Character Species"
                // defaultValue=""
                placeholder='Lagomorph (Rabbit)'
                onChange={(event) => {setNewCharacterSpecies(event.target.value)}}
              />
              <TextField
                required
                id="new-character-orientation"
                label="Character Orientation"
                // defaultValue=""
                placeholder='Bi'
                onChange={(event) => {setNewCharacterOrientation(event.target.value)}}
              />
              <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked={newCharacterPublished} onChange={() => {setNewCharacterPublished(!newCharacterPublished)}} />} label="Make Character Publicly Visible?" />
                {newCharacterPublished && <Typography variant="caption">This character will be publicly visible.</Typography>}
              </FormGroup>
              {/* TODO: Render Chip elements for each item in the colors array, where the label is the color.label, and the chip color is the color.hex */}
              {/* TODO: button to open "EditColors" component in a dialogue box */}
              {/* TODO: render summary of addl details (e.g., maybe a count of each type?) */}
              {/* TODO: button to enter addl details, opens dialogue box */}
              {/* TODO: mini rendering of ref sheet if exists */}
              {/* TODO: button that opens dialogue to upload ref sheet and label w/ string path/key of artist */}

            </div>
            <Button onClick={createCharacter}>Create Character</Button>
          </Box>
        </Box>
      </Box>
      <EditPronouns open={editPronounsOpen} setOpen={setEditPronounsOpen} pronouns={newCharacterPronouns} updatePronouns={setNewCharacterPronouns} characters={[]} setCharacters={()=>{}} editCharacterHandler={() => {}} data={{characterName: '', characterBirthdate: '', characterPronouns: [], characterSpecies: '', characterOrientation: '', characterColors: [], published: false, id: ''}} runUpdate={() => {}}
      />
    </MainBody>
  )
}

export default CreateCharacter