import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DeleteIcon from "@mui/icons-material/Delete";

import SelectCharacterDialog from './SelectCharacterDialog';

function EditAssociatedCharacters({ open, setOpen, showNSFW, characters, updateCharacters }: {open: boolean, setOpen: Function, showNSFW: boolean, characters: Array<any>, updateCharacters: Function}) {
  const [localCharacters, setLocalCharacters] = useState<Array<any>>(characters);
  const [addCharacterOpen, setAddCharacterOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
    setLocalCharacters(characters)
  };

  const handleSaveAndClose = () => {
    updateCharacters(localCharacters);
    handleClose();
  };

  const removeCharacter = (charToRemove: any) => {
    let newChars = [...localCharacters];
    let idxToRemove = newChars.indexOf(charToRemove);
    if (idxToRemove !== -1) {
      newChars.splice(idxToRemove, 1);
    }
    setLocalCharacters(newChars);
  };

  useEffect(() => {
    setLocalCharacters(characters);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Edit Characters"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Edit characters for this artwork.
          </DialogContentText>
          <List>
            {localCharacters.map((a, idx) => (
              <ListItem
                key={`char-id-${idx}`}
                secondaryAction={
                  <div>
                    <IconButton edge="end" aria-label="delete" onClick={() => {removeCharacter(a)}}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                }
              >
                <ListItemText
                  primary={a}
                />
              </ListItem>
            ))}
            {localCharacters.length > 0 && <Divider />}
            <ListItem key="add-new" disablePadding>
              <ListItemButton onClick={() => {setAddCharacterOpen(true)}}>
                <ListItemText primary="Add New Character" />
              </ListItemButton>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveAndClose} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <SelectCharacterDialog open={addCharacterOpen} setOpen={setAddCharacterOpen} showNSFW={showNSFW} localCharacters={localCharacters} setLocalCharacters={setLocalCharacters} />
    </div>
  )
}

export default EditAssociatedCharacters