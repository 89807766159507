import React, { useEffect, useState } from 'react'
import MainBody from './MainBody'
import { collection, doc, getDoc, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase-config';
import { ArtistDataRow, CharacterDataRow, CommissionDataRow } from '../interfaces';
import { Link, useParams } from 'react-router-dom';
import { Box, Chip, Typography, lighten, useTheme } from '@mui/material';
import dayjs from 'dayjs';
import RelativeTime from "dayjs/plugin/relativeTime";
import Duration from "dayjs/plugin/duration";
import { Chrono } from 'react-chrono';
import { humanizeTimeDifference } from '../helpers/humanizeTimeDifference';
dayjs.extend(RelativeTime);
dayjs.extend(Duration);

function Commission({ isAuth, showNSFW }: { isAuth: boolean, showNSFW: boolean }) {
  let { commissionIdFromUrl } = useParams();
  const theme = useTheme();
  
  const [loaded, setLoaded] = useState<boolean>(false);
  const [characters, setCharacters] = useState<Array<CharacterDataRow>>([]);

  const commissionsCollectionRef = collection(db, "commissions");
  const [commission, setCommission] = useState<CommissionDataRow>();

  const [commArtists, setCommArtists] = useState<Array<ArtistDataRow>>([]);

  const [updates, setUpdates] = useState<Array<any>>([]);

  const runUpdate = () => {
    const getUpdatesForComm = (commission: CommissionDataRow) => {
      let updates: Array<any> = [];
      if (commission) {
        if (commission.id) {
          const commUpdatesCollectionRef = collection(db, "commissionHistoryItems");
          const relatedUpdatesQuery = query(commUpdatesCollectionRef, where("commission", "==", doc(collection(db, "commissions"), commission.id)))
          const queryForNonHidden = query(relatedUpdatesQuery, where("hidden", "!=", true));
          getDocs(isAuth ? relatedUpdatesQuery : queryForNonHidden).then((snapshot) => {
            snapshot.forEach((doc) => updates.push({ id: doc.id, __original_id: doc.data().__original_id, hidden: doc.data().hidden, note: doc.data().description, ts: doc.data().ts }));
          }).finally(async () => {
            // setUpdates(updates);
            updates = updates.sort((a, b) => b.ts.seconds - a.ts.seconds);
            setUpdates(updates);
            const charactersCollectionRef = collection(db, "characters");
            const charactersData = await (getDocs(charactersCollectionRef));
            let characters = charactersData.docs.map((doc) => ({ ...doc.data(), id: doc.id })) as Array<CharacterDataRow>;
            setCharacters(characters);
            setLoaded(true)
          })
        }
      }
    };
    const getArtistsForComm = (comm: CommissionDataRow) => {
      let artists: Array<ArtistDataRow> = [];
      try {
        comm.artists.forEach((artist) => {
          getDoc(artist).then((snap) => {
            let ad: ArtistDataRow = { id: snap.id, ...(snap.data() as any) } as ArtistDataRow;
            artists.push(ad);
          }).finally(() => {
            setCommArtists(artists);
            getUpdatesForComm(comm);
            // setLoaded(true);
          })
        });
      } catch {
        setLoaded(true);
      }
    };
    const getCommData = async () => {
      const data = await getDoc(doc(commissionsCollectionRef, commissionIdFromUrl));
      if(data.exists() === false){
        setLoaded(true);
        return;
      }
      const comm: CommissionDataRow = { id: commissionIdFromUrl, ...data.data() } as CommissionDataRow;
      if (showNSFW === false) {
        if (comm.nsfw) {
          setLoaded(true);
          return;
        }
      }
      setCommission(comm);
      if(!comm){
        setLoaded(true) // not found
      } else {
        getArtistsForComm(comm);
      }
      
      // setLoaded(true);
    };
    
    getCommData();
  };

  useEffect(() => {
    runUpdate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [showNSFW]);

  return (
    <MainBody
      title={"Commission History"}
    >
      <>
        {loaded === false ? <>
          Loading...
        </> : <>
          {(!commission) ? <>
            <p>Not Found :(</p>
          </> : (!isAuth && (commission.linkedCharacters &&!(commission.linkedCharacters.map((i) => characters.filter(j => j.id === i._key.path.segments.at(-1))[0].published).includes(true)))) ? <>
            <p>Not Found.</p>
          </> : <>
            {/* <p>todo: render comm details</p>
            <code>{JSON.stringify(commission)}</code>
            <br /><br />
            <code>{JSON.stringify(commArtists)}</code>
            <br /><br />
            <code>{JSON.stringify(updates)}</code> */}
            <Box sx={{ margin: "1rem", padding: "1rem", display: "flex", flexDirection: "column", gap: 1 }}>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
              <Typography variant="h4">{commission.commission_name}</Typography>
              {commission.nsfw === true ? <><Chip label="NSFW" color="error" /></> : <></>}
              {commission.done === true ? <><Chip label="Done" color="success" /></> : <></>}
            </Box>
            <Typography variant="caption">by {commArtists.map(a => a.artistPrimaryName).join(" and ")}</Typography>
            <div />
            {commission.linkedCharacters && commission.linkedCharacters.length > 0 ? <>
              <Box>
                {commission.linkedCharacters.map((i: any, idx: number) => {
                  let charId = i._key.path.segments.at(-1);
                  let resolvedChar = characters.filter(i => i.id === charId)[0];
                  return (
                    <Chip key={idx} label={resolvedChar.characterName} component={Link} to={`/characters/${resolvedChar.characterName}`} target="_blank" />
                  )
                })}
              </Box>
            </> : <></>}
            <div />
            {/* <Button variant='outlined' color="primary" component={Link} to={`/commissions/log/${commission.id}`}>View Commission Log</Button> */}
            {commission.dateInit && <Typography variant="body1">Initialized: {dayjs(commission.dateInit.seconds * 1000).format()}</Typography>}
            {commission.datePaid && <Typography variant="body1">Date Paid: {dayjs(commission.datePaid.seconds * 1000).format()}</Typography>}
            {commission.dateStarted && <Typography variant="body1">Date Started: {dayjs(commission.dateStarted.seconds * 1000).format()}</Typography>}
            {commission.dateDelivered && <Typography variant="body1">Date Delivered: {dayjs(commission.dateDelivered.seconds * 1000).format()}</Typography>}
            {commission.dateDelivered && <Typography variant="body1">Delivered after {humanizeTimeDifference(commission.dateInit.seconds * 1000, commission.dateDelivered.seconds * 1000)}</Typography>}
            {commission.done === false && <Typography variant="body1">Waiting for {humanizeTimeDifference(commission.dateInit.seconds * 1000, dayjs().unix() * 1000)}{commission.dateDelivered && ` (NOTE: Delivery date is set but commission is not marked "DONE".)`}</Typography>}
            <div style={{ marginBottom: "1rem" }} />
            {updates.length > 0 && <>
              <Typography variant="h5">Progress Updates/Notes ({updates.length})</Typography>
              <Chrono
                    // mode="VERTICAL"
                    mode="VERTICAL_ALTERNATING"
                    disableClickOnCircle
                    hideControls
                    disableNavOnKey
                    disableAutoScrollOnClick
                    theme={{
                      primary: theme.palette.primary.main,
                      secondary: theme.palette.secondary.main,
                      cardBgColor: lighten(theme.palette.background.paper, 1),
                      titleColor: theme.palette.text.primary,
                      titleColorActive: theme.palette.getContrastText(theme.palette.secondary.main),
                    }}
                    items={updates.map((update, updateIdx) => {
                      let formatted = {
                        // title: "Commission Update",
                        // title: `${dayjs(update.ts.seconds * 1000).format()}`,
                        title: `${commission.done ? `${humanizeTimeDifference(update.ts.seconds * 1000, commission.dateInit.seconds * 1000)} ${update.ts.seconds < commission.dateInit.seconds ? "before" : "after"} start of comm` : dayjs(update.ts.seconds * 1000).fromNow()}`,
                        // cardTitle: `Update ${dayjs(update.ts.seconds * 1000).format()} | ${update.id}${update.__original_id ? ` | ${update.__original_id}` : ""}`,
                        cardTitle: `Update ${dayjs(update.ts.seconds * 1000).format()}`,
                        // url: "http://www.history.com",
                        cardSubtitle: `${update.hidden === true ? "[PRIVATE] " : ""}Note added ${commission.done ? `${humanizeTimeDifference(update.ts.seconds * 1000, commission.dateInit.seconds * 1000)} ${update.ts.seconds < commission.dateInit.seconds ? "before" : "after"} start of comm` : dayjs(update.ts.seconds * 1000).fromNow()}`,
                        cardDetailedText: `${update.note}`,
                        // media: {
                        //   type: "IMAGE",
                        //   source: {
                        //     url: "http://someurl/image.jpg"
                        //   }
                        // }
                      };
                      return (
                        formatted
                      )
                    }
                    )} />
              {/* <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`updates-content`}
                  id={`updates-header`}
                >
                  <Typography>Progress Updates/Notes ({updates.length})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  <Chrono
                    // mode="VERTICAL"
                    mode="VERTICAL_ALTERNATING"
                    disableClickOnCircle
                    hideControls
                    disableNavOnKey
                    disableAutoScrollOnClick
                    theme={{
                      primary: theme.palette.primary.main,
                      secondary: theme.palette.secondary.main,
                      cardBgColor: lighten(theme.palette.background.paper, 1),
                      titleColor: theme.palette.text.primary,
                      titleColorActive: theme.palette.getContrastText(theme.palette.secondary.main),
                    }}
                    items={updates.map((update, updateIdx) => {
                      let formatted = {
                        // title: "Commission Update",
                        // title: `${dayjs(update.ts.seconds * 1000).format()}`,
                        title: `${commission.done ? `${humanizeTimeDifference(update.ts.seconds * 1000, commission.dateInit.seconds * 1000)} ${update.ts.seconds < commission.dateInit.seconds ? "before" : "after"} start of comm` : dayjs(update.ts.seconds * 1000).fromNow()}`,
                        cardTitle: `Update ${dayjs(update.ts.seconds * 1000).format()}`,
                        // url: "http://www.history.com",
                        cardSubtitle: `Note added ${commission.done ? `${humanizeTimeDifference(update.ts.seconds * 1000, commission.dateInit.seconds * 1000)} ${update.ts.seconds < commission.dateInit.seconds ? "before" : "after"} start of comm` : dayjs(update.ts.seconds * 1000).fromNow()}`,
                        cardDetailedText: update.note,
                        // media: {
                        //   type: "IMAGE",
                        //   source: {
                        //     url: "http://someurl/image.jpg"
                        //   }
                        // }
                      };
                      return (
                        formatted
                      )
                    }
                    )} />
                </AccordionDetails>
              </Accordion> */}
            </>}

          </Box>
          </>}
        </>}
      </>
    </MainBody>
  )
}

export default Commission