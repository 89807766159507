import { Autocomplete, Box, Button, Checkbox, createFilterOptions, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, FormControlLabel, FormGroup, MenuItem, Select, SelectChangeEvent, TextField, Typography } from '@mui/material'
import { useEffect, useState } from 'react'
import { CharacterDataRow, CharacterNSFWAnatomy } from "../interfaces";

function EditAnatomyNSFW({ open, setOpen, anatomy, updateAnatomy, characters,
  setCharacters,
  editCharacterHandler,
  data,
  runUpdate, }: {open: boolean, setOpen: Function, anatomy: CharacterNSFWAnatomy, updateAnatomy: Function,
    characters: Array<any> | undefined,
    setCharacters: Function,
    editCharacterHandler: Function
    data: CharacterDataRow,
    runUpdate: Function
  }) {
  const [localAnatomy, setLocalAnatomy] = useState<CharacterNSFWAnatomy>(anatomy);

  const handleClose = () => {
    setOpen(false);
    setLocalAnatomy(anatomy)
  };

  const handleSaveAndClose = () => {
    let newCharacters: Array<CharacterDataRow> = [...characters || []];
    let idxToUpdate = newCharacters.indexOf(newCharacters.filter((i) => i.id === data.id)[0]);
    let nsfwdetails = newCharacters[idxToUpdate].nsfwDetails || {};
    nsfwdetails.anatomy = localAnatomy;
    newCharacters[idxToUpdate].nsfwDetails = nsfwdetails;
    setCharacters(newCharacters);
    editCharacterHandler(data, runUpdate);
    updateAnatomy(localAnatomy);
    handleClose();
  };

  useEffect(() => {
    setLocalAnatomy(anatomy);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  interface PenisOptionType {
    inputValue?: string;
    title: string;
  }
  
  const defaultPenisTypes: readonly PenisOptionType[] = [
    { title: 'human'},
    { title: 'canine'},
  ];

  const filter = createFilterOptions<PenisOptionType>();

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Edit Anatomy"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Edit character anatomy (NSFW).
          </DialogContentText>
          <Box
            component="form"
            // sx={{
            //   '& .MuiTextField-root': { m: 1, width: '50ch' },
            // }}
            noValidate
            autoComplete="off"
          >
            {/* {`DEBUG: ${JSON.stringify(localAnatomy)}`} */}
            <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
              <FormGroup sx={{
                gap: "1em"
              }}>
                <FormControlLabel control={<Checkbox defaultChecked={localAnatomy.penis !== undefined} onChange={() => {
                  let n = {...localAnatomy};
                  if(n.penis === undefined){
                    // set some defaults to use in the form
                    n.penis = {
                      length: 6,
                      species: "human",
                      intact: false,
                    };
                    setLocalAnatomy(n);
                  } else {
                    delete n.penis;
                    setLocalAnatomy(n);
                  }
                }} />} label="Does the character have a penis?" />
                {localAnatomy.penis && <>
                  <TextField
                    id="penis-length-inches"
                    label="Length (inches)"
                    type="number"
                    InputLabelProps={{
                      shrink: true,
                    }}
                    inputProps={{
                      step: 0.25,
                    }}
                    value={localAnatomy.penis.length}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      let n = {...localAnatomy};
                      if (n.penis){
                        n.penis.length = !isNaN(Number(event.target.value)) ? Number(event.target.value) : n.penis.length;
                      }
                      setLocalAnatomy(n);
                    }}
                  />
                  
                  <FormControlLabel control={<Checkbox defaultChecked={localAnatomy.penis.intact} onChange={() => {
                    let n = {...localAnatomy};
                    if(n.penis === undefined){
                      // do nothing
                    } else {
                      n.penis.intact = !n.penis.intact;
                      setLocalAnatomy(n);
                    }
                  }} />} label="Does the character have an *intact* penis? (Do they have foreskin/are they 'uncut'?)" />

                  <FormControlLabel control={
                    <Autocomplete
                      value={localAnatomy.penis.species}
                      onChange={(event: any, newValue: any) => {
                        let n = {...localAnatomy};
                        if(n.penis === undefined) {
                          return // do nothing
                        }
                        if (typeof newValue === 'string') {
                          n.penis.species = ({
                            title: newValue,
                          }).title;
                        } else if (newValue && newValue.inputValue) {
                          // Create a new value from the user input
                          n.penis.species = ({
                            title: newValue.inputValue,
                          }).title;
                        } else {
                          n.penis.species = (newValue).title;
                        }
                      }}
                      filterOptions={(options: any, params: any) => {
                        const filtered = filter(options, params);
                
                        const { inputValue } = params;
                        // Suggest the creation of a new value
                        const isExisting = options.some((option: any) => inputValue === option.title);
                        if (inputValue !== '' && !isExisting) {
                          filtered.push({
                            inputValue,
                            title: `Add "${inputValue}"`,
                          });
                        }
                
                        return filtered;
                      }}
                      selectOnFocus
                      clearOnBlur
                      handleHomeEndKeys
                      id="free-solo-with-text-demo"
                      options={defaultPenisTypes}
                      getOptionLabel={(option: any) => {
                        // Value selected with enter, right from the input
                        if (typeof option === 'string') {
                          return option;
                        }
                        // Add "xxx" option created dynamically
                        if (option.inputValue) {
                          return option.inputValue;
                        }
                        // Regular option
                        return option.title;
                      }}
                      renderOption={(props: any, option: any) => <li {...props}>{option.title}</li>}
                      sx={{ width: 300 }}
                      freeSolo
                      renderInput={(params: any) => (
                        <TextField {...params} label="Style" />
                      )}
                    />
                  } label={"What 'style' is it? (Human, Canine, Other?)"} /> 

                </>}
              </FormGroup>
              <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked={localAnatomy.balls !== undefined} onChange={() => {
                  let n = {...localAnatomy};
                  if(n.balls === undefined){
                    // set some defaults to use in the form
                    n.balls = {
                      description: "",
                    };
                    setLocalAnatomy(n);
                  } else {
                    delete n.balls;
                    setLocalAnatomy(n);
                  }
                }} />} label="Does this character have balls? (Use description to note anything notable about them)" />
                {localAnatomy.balls && <>
                  <TextField
                    id="balls"
                    label="Balls (description)"
                    value={localAnatomy.balls.description}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      let n = {...localAnatomy};
                      if (n.balls){
                        n.balls.description = event.target.value;
                      }
                      setLocalAnatomy(n);
                    }}
                  />
                </>}
              </FormGroup>
              <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked={localAnatomy.vagina !== undefined} onChange={() => {
                  let n = {...localAnatomy};
                  if(n.vagina === undefined){
                    // set some defaults to use in the form
                    n.vagina = {
                      description: "",
                    };
                    setLocalAnatomy(n);
                  } else {
                    delete n.vagina;
                    setLocalAnatomy(n);
                  }
                // update anatomical language for display; db and interface migration later
                // }} />} label="Does this character have a vagina? (Use description to note anything notable about it)" />
              }} />} label="Does this character have a vulva? (Use description to note anything notable about it)" />
                {localAnatomy.vagina && <>
                  <TextField
                    // update anatomical language for display; db and interface migration later
                    // id="vagina"
                    id="vulva"
                    // label="Vagina (description)"
                    label="Vulva (description)"
                    value={localAnatomy.vagina.description}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                      let n = {...localAnatomy};
                      if (n.vagina){
                        n.vagina.description = event.target.value;
                      }
                      setLocalAnatomy(n);
                    }}
                  />
                </>}
              </FormGroup>

              <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked={localAnatomy.breasts !== undefined} onChange={() => {
                  let n = {...localAnatomy};
                  if(n.breasts === undefined){
                    // set some defaults to use in the form
                    n.breasts = {
                      symmetric: true,
                      style: "standard",
                      size: {
                        cup: "AAA"
                      }
                    };
                    setLocalAnatomy(n);
                  } else {
                    delete n.breasts;
                    setLocalAnatomy(n);
                  }
                }} />} label="Does this character have breasts?" />
                {localAnatomy.breasts && <>
                  {/* Symmetric checkbox */}
                  <FormControlLabel control={<Checkbox defaultChecked={localAnatomy.breasts.symmetric} onChange={() => {
                    let n = {...localAnatomy};
                    if(n.breasts === undefined){
                      // do nothing
                    } else {
                      n.breasts.symmetric = !n.breasts.symmetric;
                      setLocalAnatomy(n);
                    }
                  }} />} label="Does the character have symmetric breasts?" />
                  {/* style select */}
                  <FormControlLabel control={
                    <Select
                      labelId="style-select"
                      id="style-select"
                      value={localAnatomy.breasts.style}
                      label="Breast Style"
                      onChange={(event: SelectChangeEvent) => {
                        let n = {...localAnatomy};
                        if(n.breasts === undefined){
                          // do nothing
                        } else {
                          n.breasts.style = (event.target.value as string);
                          setLocalAnatomy(n);
                        }
                      }}
                    >
                      {["standard", "multi"].map((bStyle) => {
                        return (
                          <MenuItem key={bStyle} value={bStyle}>{bStyle}</MenuItem>
                        )
                      })}
                    </Select>
                  } label="What style breasts does the character have? Standard (2, like a human) or Multi (6+, like a canine)?" />
                  {/* size drop down */}
                  {localAnatomy.breasts.size && <>
                    <FormControlLabel control={
                      <Select
                        labelId="style-select"
                        id="style-select"
                        value={localAnatomy.breasts.size.cup}
                        label="Breast Cup Size"
                        onChange={(event: SelectChangeEvent) => {
                          let n = {...localAnatomy};
                          if(n.breasts === undefined){
                            // do nothing
                          } else {
                            n.breasts.size = {cup: (event.target.value as string)}
                            setLocalAnatomy(n);
                          }
                        }}
                      >
                        {["AAA", "AA", "A", "B", "C", "D", "DD", "E", "F", "FF", "G", "GG", "H", "HH", "J", "JJ", "K", "KK", "L", "LL", "M", "N", "O", "OO", "R"].map((bCupSize) => {
                          return (
                            <MenuItem key={bCupSize} value={bCupSize}>{bCupSize}</MenuItem>
                          )
                        })}
                      </Select>
                    } label={`What size bra cup would the character wear?*`} />
                    <Typography variant="caption">*<a style={{ color: "inherit" }} href="https://www.bratabase.com/browse/by-photo/" target="_blank" rel="noreferrer">Need</a> Some <a style={{ color: "inherit" }} href="https://brayola.com/sizes" target="_blank" rel="noreferrer">Help</a>?</Typography>
                  </>}
                </>}
              </FormGroup>
            </div>
          </Box>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveAndClose} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EditAnatomyNSFW