import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Page from "./Page";
// import CreateArtist from "./pages/CreateArtist";
import Home from "./pages/Home"; // eslint-disable-line @typescript-eslint/no-unused-vars
import Login from "./pages/Login";
import NavAppBar from "./pages/NavAppBar";

import { signOut } from "firebase/auth";
import { auth } from "./firebase-config";
import Artists from "./pages/ManageArtists";
import Characters from "./pages/Characters";
import ManageCharacters from "./pages/ManageCharacters";
import ManageArtworks from "./pages/ManageArtworks";
import ManageCommissions from "./pages/ManageCommissions";
import Commissions from "./pages/Commissions";
import CharacterView from "./pages/CharacterView";
import MobileFriendlyKonamiCode from "./pages/MobileFriendlyKonamiCode";
import ProtectedRoute, { ProtectedRouteProps } from "./components/ProtectedRoute";
import NotFound404 from "./pages/NotFound404";
import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button } from "@mui/material";
import CharacterViewSecret from "./pages/CharacterViewSecret";
import Commission from "./pages/Commission";
// import TmpHelper from "./pages/TmpHelper";

function App() {
  const [isAuth, setIsAuth] = useState<boolean>(JSON.parse(localStorage.getItem('isAuth') || "false") === true);
  const [isSpecialAccessProvisioned, setIsSpecialAccessProvisioned] = useState<boolean>(false);
  const [specialAccessStr, setSpecialAccessStr] = useState<string>("");
  const defaultProtectedRouteProps: Omit<ProtectedRouteProps, 'outlet'> = {
    isAuthenticated: isAuth,
    authenticationPath: '/',
  };
  const [showNSFW, setShowNSFW] = useState<boolean>(
    // true
    JSON.parse(localStorage.getItem('showNSFW') || "false") === true
  );
  const [hasTypedTheKonamiCode, setHasTypedTheKonamiCode] = useState<boolean>(
    // true
    JSON.parse(localStorage.getItem('konamiCode') || "false") === true
  )

  const [showKonamiDialog, setShowKonamiDialog] = useState<boolean>(false);

  const signUserOut = () => {
    signOut(auth).then(() => {
      localStorage.removeItem('isAuth');
      setIsAuth(false);
      window.location.pathname = "/";
    });
  };

  const handleDisplayKonamiDialog = (abortController?: AbortController, handler?: any) => {
    setShowNSFW(true);
    localStorage.setItem("showNSFW", "true");
    setHasTypedTheKonamiCode(true);
    localStorage.setItem("konamiCode", "true");
    // document.removeEventListener('keydown', handler, false);
    // abortController.abort(); // stop listening
  }

  // const [abCtl, setAbCtl] = useState<any>();
  // const [hndl, setHndl] = useState<any>();

  useEffect(() => {
    if(!showNSFW){
      const abortController = new AbortController();
      document.addEventListener('DOMContentLoaded', () => {
        // 'use strict';
  
        let timeout = 3000; // originally: 1000
        let secret = "hornybun"; // classy, I know ;3c
    
        let buffer: Array<string> = [];
        let lastKeyTime = Date.now();

        const handler = (event: KeyboardEvent) => {
          const charList = 'abcdefghijklmnopqrstuvwxyz0123456789';
          const key = event.key.toLowerCase();
  
          // we are only interested in alphanumeric keys
          if (charList.indexOf(key) === -1) return;
  
          const currentTime = Date.now();
  
          if (currentTime - lastKeyTime > timeout) {
              buffer = [];
          }
  
          buffer.push(key);
          lastKeyTime = currentTime;
  
          // console.log(`typing buffer: ${buffer}`); // temp debug
          let input = buffer.join("");
          // console.log(input)
          if(input === secret && !showNSFW){
            // console.log("MATCH")
            // setShowNSFW(true);
            // localStorage.setItem("showNSFW", "true");
            // setHasTypedTheKonamiCode(true);
            // localStorage.setItem("konamiCode", "true");
            document.removeEventListener('keydown', handler, false);
            abortController.abort(); // stop listening
            // all above replaced with
            // handleDisplayKonamiDialog(abortController, handler)
            // setAbCtl(abortController);
            // setHndl(handler);
            setShowKonamiDialog(true);
          }
        }
    
        document.addEventListener('keydown', event => handler(event), {signal: abortController.signal});
      })
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleGetNextUrl = () => {
    let queryString = window.location.search;
    let urlParams = new URLSearchParams(queryString);
    let next = urlParams.get('next');
    return next !== null ? next : "/";
  }

  return <Page>
    <Router>
      <nav>
        <NavAppBar specialAccessStr={specialAccessStr} isSpecialAccessProvisioned={isSpecialAccessProvisioned} hasTypedTheKonamiCode={hasTypedTheKonamiCode} setHasTypedTheKonamiCode={setHasTypedTheKonamiCode} isAuth={isAuth} signUserOut={signUserOut} showNSFW={showNSFW} setShowNSFW={setShowNSFW} />
      </nav>
      <Routes>
        {/* <Route path="/" element={<Home />} /> */}
        <Route path="/" element={<Characters isAuth={isAuth} showNSFW={showNSFW} />} />
        {/* <Route path="/tmp" element={<TmpHelper />} /> */}
        {/* <Route path="/createartist" element={<CreateArtist isAuth={isAuth} />} /> */}
        <Route path="/astra/login" element={<Login isAuth={isAuth} setIsAuth={setIsAuth} />} />
        <Route path="/mgmt/artists" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<Artists isAuth={isAuth} showNSFW={showNSFW} />} />} />
        <Route path="/characters" element={<Characters isAuth={isAuth} showNSFW={showNSFW} />} />
        <Route path="/mgmt/characters" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ManageCharacters isAuth={isAuth} showNSFW={showNSFW} />} />} />
        <Route path="/mgmt/artwork" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ManageArtworks isAuth={isAuth} showNSFW={showNSFW} />} />} />
        <Route path="/mgmt/commissions" element={<ProtectedRoute {...defaultProtectedRouteProps} outlet={<ManageCommissions isAuth={isAuth} showNSFW={showNSFW} />} />} />
        <Route path="/commissions" element={<Commissions isAuth={isAuth} showNSFW={showNSFW} />} />
        <Route path="/commissions/log/:commissionIdFromUrl" element={<Commission isAuth={isAuth} showNSFW={showNSFW} />} />
        <Route path="/commissions/all" element={<Commissions isAuth={isAuth} showNSFW={showNSFW} showDone={true} />} />
        <Route path="/characters/:charNameFromUrl" element={<CharacterView isAuth={isAuth} showNSFW={showNSFW} setShowNSFW={setShowNSFW} />} />
        <Route path="/secret/:secret/characters/:charNameFromUrl" element={<CharacterViewSecret isAuth={isAuth} showNSFW={showNSFW} setShowNSFW={setShowNSFW} isSpecialAccessProvisioned={isSpecialAccessProvisioned} setIsSpecialAccessProvisioned={setIsSpecialAccessProvisioned} setSpecialAccessStr={setSpecialAccessStr} />} />
        {/* <Route path="/uwu-for-mobile/enable" element={<ProtectedRoute isAuthenticated={!hasTypedTheKonamiCode} authenticationPath={"/"} outlet={<MobileFriendlyKonamiCode hasTypedTheKonamiCode={hasTypedTheKonamiCode} setHasTypedTheKonamiCode={setHasTypedTheKonamiCode} showNSFW={showNSFW} setShowNSFW={setShowNSFW} />} />} /> */}
        {/* <Route path="/uwu-for-mobile/enable" element={<ProtectedRoute isAuthenticated={!hasTypedTheKonamiCode} authenticationPath={handleGetNextUrl()} outlet={<MobileFriendlyKonamiCode hasTypedTheKonamiCode={hasTypedTheKonamiCode} setHasTypedTheKonamiCode={setHasTypedTheKonamiCode} showNSFW={showNSFW} setShowNSFW={setShowNSFW} />} />} /> */}
        <Route path="/uwu/enable" element={<ProtectedRoute isAuthenticated={!hasTypedTheKonamiCode} authenticationPath={handleGetNextUrl()} outlet={<MobileFriendlyKonamiCode hasTypedTheKonamiCode={hasTypedTheKonamiCode} setHasTypedTheKonamiCode={setHasTypedTheKonamiCode} showNSFW={showNSFW} setShowNSFW={setShowNSFW} />} />} />

        <Route path="*" element={<NotFound404 />} />
      </Routes>
    </Router>
    <Dialog
      open={showKonamiDialog}
      onClose={() => {}} // don't close unless the cancel button is pressed
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Are you sure?"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          By continuing, you agree that you are of legal age to view adult-oriented content and wish to see this. 
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => {setShowKonamiDialog(false)}}>Cancel</Button>
        <Button onClick={() => {
          // setHasTypedTheKonamiCode(true);
          // setShowNSFW(true);
          // localStorage.setItem("konamiCode", "true");
          // window.location.assign("/");
          // handleDisplayKonamiDialog(abCtl, hndl);
          handleDisplayKonamiDialog();
          setShowKonamiDialog(false);
        }} autoFocus>
          Confirm
        </Button>
      </DialogActions>
    </Dialog>
  </Page>;
}

export default App;
