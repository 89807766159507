import { doc, getDoc } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { db } from '../firebase-config';

function GalleryArtistLink({artistDoc, idx}: {artistDoc: string|any, idx: number}) {
  const [artistLink, setArtistLink] = useState<any>("Loading...");
  useEffect(() => {
    try {
      getDoc(artistDoc).then(res => {
        let artist: any = res.data();
        setArtistLink(
          <span key={`artist-position-${idx}`}>
            <a href={artist.artistPrimaryLink} target="_blank" rel="noreferrer" style={{ color: "inherit" }}>{artist.artistPrimaryName}</a>
          </span>
        );
      });
    } catch {
      getDoc(doc(db, artistDoc)).then(res => {
        let artist: any = res.data();
        setArtistLink(
          <span key={`artist-position-${idx}`}>
            <a href={artist.artistPrimaryLink} target="_blank" rel="noreferrer" style={{ color: "inherit" }}>{artist.artistPrimaryName}</a>
          </span>
        );
      });
    }
  }, [artistDoc, idx])
  return (
    <span>{artistLink}</span>
  )
}

export default GalleryArtistLink