import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import React, { useEffect, useState } from 'react'
import DeleteIcon from "@mui/icons-material/Delete";
import SelectArtistDialog from './SelectArtistDialog';

function EditAssociatedArtists({ open, setOpen, showNSFW, artists, updateArtists }: {open: boolean, setOpen: Function, showNSFW: boolean, artists: Array<any>, updateArtists: Function}) {
  const [localArtists, setLocalArtists] = useState<Array<any>>(artists);
  const [addArtistOpen, setAddArtistOpen] = useState<boolean>(false);

  const handleClose = () => {
    setOpen(false);
    setLocalArtists(artists)
  };

  const handleSaveAndClose = () => {
    updateArtists(localArtists);
    handleClose();
  };

  const removeArtist = (artistToRemove: any) => {
    let newArtists = [...localArtists];
    let idxOfArtistToRemove = newArtists.indexOf(artistToRemove);
    if (idxOfArtistToRemove !== -1) {
      newArtists.splice(idxOfArtistToRemove, 1);
    }
    setLocalArtists(newArtists);
  };

  useEffect(() => {
    setLocalArtists(artists);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  // const handleUpdateLocalArtistLinks = (newArtist: any) => {
  //   let newArtists = [...localArtists];
  //   newArtists.push(newArtist);
  //   setLocalArtists(newArtists);
  // };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Edit Artists"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Edit artists for this artwork.
          </DialogContentText>
          <List>
            {localArtists.map((a, idx) => (
              <ListItem
                key={`artist-id-${idx}`}
                secondaryAction={
                  <div>
                    <IconButton edge="end" aria-label="delete" onClick={() => {removeArtist(a)}}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                }
              >
                <ListItemText
                  primary={a}
                />
              </ListItem>
            ))}
            {localArtists.length > 0 && <Divider />}
            <ListItem key="add-new" disablePadding>
              <ListItemButton onClick={() => {setAddArtistOpen(true)}}>
                <ListItemText primary="Add New Artist" />
              </ListItemButton>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveAndClose} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <SelectArtistDialog open={addArtistOpen} setOpen={setAddArtistOpen} showNSFW={showNSFW} localArtists={localArtists} setLocalArtists={setLocalArtists} />
    </div>
  )
}

export default EditAssociatedArtists