import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, IconButton, List, ListItem, ListItemButton, ListItemText } from '@mui/material'
import { useEffect, useState } from 'react'
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from '@mui/icons-material/Edit';
import { CharacterDataRow } from "../interfaces";
import EditPronoun from './EditPronoun';
import AddPronoun from './AddPronoun';

function EditPronouns({ 
    open,
    setOpen,
    pronouns,
    updatePronouns,
    // saveCharacterUpdateHandler
    characters,
    setCharacters,
    editCharacterHandler,
    data,
    runUpdate,
  }:
    {
      open: boolean,
      setOpen: Function,
      pronouns: Array<any>,
      updatePronouns: Function,
      characters: Array<any> | undefined,
      setCharacters: Function,
      editCharacterHandler: Function
      data: CharacterDataRow,
      runUpdate: Function
      // saveCharacterUpdateHandler: Function
    }
  ) {
  const [localPronouns, setLocalPronouns] = useState<Array<string>>(pronouns);
  const [addPronounOpen, setAddPronounOpen] = useState<boolean>(false);
  const [editPronounOpen, setEditPronounOpen] = useState<boolean>(false);
  const [oldPronounHolder, setOldPronounHolder] = useState<string>('');

  const handleClose = () => {
    setOpen(false);
    setLocalPronouns(pronouns)
  };

  const handleSaveAndClose = () => {
    let newCharacters: Array<CharacterDataRow> = [...characters || []];
    let idxToUpdate = newCharacters.indexOf(newCharacters.filter((i) => i.id === data.id)[0]);
    try {
      newCharacters[idxToUpdate].characterPronouns = localPronouns;
      setCharacters(newCharacters);
      editCharacterHandler(data, runUpdate);
      updatePronouns(localPronouns);
      handleClose();
    } catch(err) {
      // console.error(err);
      updatePronouns(localPronouns);
      handleClose();
    }
    // saveCharacterUpdateHandler();
  };

  const removePronoun = (pronounToRemove: string) => {
    let newLinks = [...localPronouns];
    let idxOfLinkToRemove = newLinks.indexOf(pronounToRemove);
    if (idxOfLinkToRemove !== -1) {
      newLinks.splice(idxOfLinkToRemove, 1);
    }
    setLocalPronouns(newLinks);
  };

  useEffect(() => {
    setLocalPronouns(pronouns);
  }, [open]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateLocalPronouns = (newPronoun: string) => {
    let newPronouns = [...localPronouns];
    newPronouns.push(newPronoun);
    setLocalPronouns(newPronouns);
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Edit Pronouns"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Edit pronouns.
          </DialogContentText>
          <List>
            {localPronouns.map((pnoun, idx) => (
              <ListItem
                key={`pronoun-id-${idx}`}
                secondaryAction={
                  <div>
                    <IconButton edge="end" aria-label="edit" onClick={() => {setOldPronounHolder(pnoun); setEditPronounOpen(true)}}>
                      <EditIcon />
                    </IconButton>
                    <IconButton edge="end" aria-label="delete" onClick={() => {removePronoun(pnoun)}}>
                      <DeleteIcon />
                    </IconButton>
                  </div>
                }
              >
                {/* <ListItemAvatar>
                  <Avatar>
                    <FolderIcon />
                  </Avatar>
                </ListItemAvatar> */}
                <ListItemText
                  primary={pnoun}
                />
              </ListItem>
            ))}
            {localPronouns.length > 0 && <Divider />}
            <ListItem key="add-new" disablePadding>
              <ListItemButton onClick={() => {setAddPronounOpen(true)}}>
                <ListItemText primary="Add New Pronoun" />
              </ListItemButton>
            </ListItem>
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={handleSaveAndClose} autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <EditPronoun open={editPronounOpen} setOpen={setEditPronounOpen} oldPronoun={oldPronounHolder} handleEditPronoun={(oldPronoun: string, newPronoun: string) => {
        let idxToUpdate = localPronouns.indexOf(oldPronoun);
        localPronouns[idxToUpdate] = newPronoun;
      }} />
      <AddPronoun open={addPronounOpen} setOpen={setAddPronounOpen} addPronoun={handleUpdateLocalPronouns} />
    </div>
  )
}

export default EditPronouns