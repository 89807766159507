import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Typography } from '@mui/material'
import React, { useState } from 'react'
import MainBody from './MainBody'

function MobileFriendlyKonamiCode({hasTypedTheKonamiCode, setHasTypedTheKonamiCode, showNSFW, setShowNSFW}: {hasTypedTheKonamiCode: boolean, setHasTypedTheKonamiCode: Function, showNSFW: boolean, setShowNSFW: Function}) {
  const [confirmOpen, setConfirmOpen] = useState<boolean>(false);
  return (
    <MainBody title={"Enter UwU Mode?"} showNSFW={showNSFW}>
      {/* MobileFriendlyKonamiCode */}
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: "1em",
        }}
      >
        <Typography variant="h3">Ah, so you want to see the lewd stuff~</Typography>
        <Typography variant="body1" gutterBottom>
          If you're on this page, you were likely given this page by Astra or a friend to enable NSFW mode when you don't have access to a keyboard! (There's an alternative way to enable this when you DO have a keyboard, but, for example, on phones *this* page is the way to go).
        </Typography>
        <Typography variant="body1" gutterBottom>To enable this mode, click the button below. Afterwards, you will be redirected back to the homepage.</Typography>

        <Button onClick={() => {setConfirmOpen(true)}} variant="contained" color="error">Show me the goods (NSFW/18+)</Button>
      </Box>
      <Dialog
        open={confirmOpen}
        onClose={() => {}} // don't close unless the cancel button is pressed
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Are you sure?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            By continuing, you agree that you are of legal age to view adult-oriented content and wish to see this. 
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => {setConfirmOpen(false)}}>Cancel</Button>
          <Button onClick={() => {
            setHasTypedTheKonamiCode(true);
            setShowNSFW(true);
            localStorage.setItem("konamiCode", "true");
            localStorage.setItem("showNSFW", "true");
            let queryString = window.location.search;
            let urlParams = new URLSearchParams(queryString);
            let next = urlParams.get('next');
            window.location.assign((next !== null ? next : "/"));
          }} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
    </MainBody>
  )
}

export default MobileFriendlyKonamiCode