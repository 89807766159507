import { Dialog, DialogTitle, DialogContent, DialogContentText, DialogActions, Button, Autocomplete, TextField } from '@mui/material';
import { collection, getDocs } from 'firebase/firestore';
import React, { useEffect, useState } from 'react'
import { db } from '../firebase-config';

function SelectCharacterDialog({ open, setOpen, showNSFW, localCharacters, setLocalCharacters }: { open: boolean, setOpen: Function, showNSFW: boolean, localCharacters: Array<any>, setLocalCharacters: Function }) {
  const [selected, setSelected] = useState<any>(null);
  const [characters, setCharacters] = useState<Array<any>>([]);
  const charactersCollectionRef = collection(db, "characters");

  const runUpdate = () => {
    const getCharacters = async () => {
      const data = await getDocs(charactersCollectionRef);
      setCharacters(data.docs.map((doc) => ({ ...doc.data(), id: doc.id})));
    };
    getCharacters();
  };

  useEffect(() => {
    runUpdate()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleClose = () => {
    setOpen(false);
  };
  const handleSaveAndClose = () => {
    let newLocalCharacters = [...localCharacters];
    newLocalCharacters.push(`${selected.id}`)
    setLocalCharacters(newLocalCharacters);
    setSelected(null);
    handleClose();
  }
  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">
        {"Select Character to Add"}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          Select a character from the database to add to this artwork
        </DialogContentText>   
        <Autocomplete
          disablePortal
          id="combo-box-demo"
          options={characters}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label="Character" />}
          getOptionLabel={opt => opt.characterName}
          value={selected}
          onChange={(event: any, newValue: any | null) => {
            setSelected(newValue);
          }}
        />
        {/* {JSON.stringify(selected)} */}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleClose}>Cancel</Button>
        <Button onClick={handleSaveAndClose} autoFocus disabled={selected===null}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default SelectCharacterDialog