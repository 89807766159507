import React, { useState } from 'react'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import { Link } from 'react-router-dom';
import { Chip, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Divider, FormControlLabel, FormGroup, List, ListItem, ListItemButton, ListItemIcon, ListItemText, SwipeableDrawer, Switch, Tooltip } from '@mui/material';
// icons
import HomeIcon from '@mui/icons-material/Home';
import PaletteIcon from '@mui/icons-material/Palette';
import PersonIcon from '@mui/icons-material/Person';
import GradingIcon from '@mui/icons-material/Grading';
import BrushIcon from '@mui/icons-material/Brush';
import RequestQuoteIcon from '@mui/icons-material/RequestQuote';
import PersonAddIcon from '@mui/icons-material/PersonAdd';

import "./NavAppBar.css";

const drawerWidth = 240;

export default function NavAppBar({hasTypedTheKonamiCode, setHasTypedTheKonamiCode, isAuth, signUserOut, showNSFW, setShowNSFW, isSpecialAccessProvisioned, specialAccessStr}: {hasTypedTheKonamiCode: boolean, setHasTypedTheKonamiCode: Function, isAuth: boolean, signUserOut: any, showNSFW: boolean, setShowNSFW: Function, isSpecialAccessProvisioned?: boolean, specialAccessStr?: string}) { // FIXME: get proper type for signUserOut function
  const navPages = [
    {route: '/', text: 'Home', icon: <HomeIcon />},
    {route: '/characters', text: 'Characters', icon: <PersonIcon />},
    {route: '/commissions', text: 'Commission Waitlist', icon: <GradingIcon />}, // FIXME: RE-ENABLE WHEN THIS PAGE IS READY
  ];

  const mgmtNavPages = [
    // {route: '/commissions', text: 'Commission Waitlist', icon: <GradingIcon />}, // FIXME: REMOVE AND KEEP THE NORMAL navPages VERSION WHEN THIS PAGE IS READY
    {route: '/mgmt/artists', text: 'Artists', icon: <BrushIcon />},
    {route: '/mgmt/characters', text: 'Characters', icon: <PersonAddIcon />},
    {route: '/mgmt/artwork', text: 'Artworks', icon: <PaletteIcon />},
    {route: '/mgmt/commissions', text: 'Commissions', icon: <RequestQuoteIcon />},
  ];

  const [open, setOpen] = React.useState(false);
  
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const toggleDrawerOpen = () => {
    if (open) {
      handleDrawerClose();
    } else {
      handleDrawerOpen();
    }
  }

  const [showLeavingNSFWModeDialog, setShowLeavingNSFWModeDialog] = useState<boolean>(false);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <Dialog
        open={showLeavingNSFWModeDialog}
        onClose={() => {
          // setShowLeavingNSFWModeDialog(false); // don't handle closing unless they click okay
        }}
        aria-labelledby="dialog-title"
        aria-describedby="dialog-description"
      >
        <DialogTitle id="dialog-title">
          {"You Are About To Leave Horny Mode™"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="dialog-description">
            To get this toggle back, type the same "Konami Code" style text you typed to get this mode enabled ;3c
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            onClick={() => {
              setShowLeavingNSFWModeDialog(false); // close
            }}
          >
            WAIT, I'M STILL HORNY!!!!!!!!!!
          </Button>
          <Button onClick={() => {
            setShowLeavingNSFWModeDialog(false); // close
            setShowNSFW(false);
            localStorage.removeItem("showNSFW");
            localStorage.removeItem("konamiCode");
            setHasTypedTheKonamiCode(false);
          }}>Okay</Button>
        </DialogActions>
      </Dialog>
      <AppBar position="static" sx={{ zIndex: (theme) => theme.zIndex.drawer + 1 }} enableColorOnDark>
        <Toolbar>
          <IconButton
            size="large"
            edge="start"
            color="inherit"
            aria-label="menu"
            sx={{ mr: 2 }}
            onClick={toggleDrawerOpen}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            <Link to="/" className={"header-link"}>
              {/* Astra Bun's Art Collection */}
              Galerie d'Astra
            </Link>
          </Typography>
          {isSpecialAccessProvisioned && <Tooltip sx={{ marginRight: "1em"}} title="Welcome! You are using a special link made to grant artists full viewing permission to all available art for reference!"><Chip label={`Welcome${specialAccessStr && specialAccessStr.length > 0 && ", "}${specialAccessStr}!`} color="info" /></Tooltip>}
          {
            showNSFW ? <Tooltip title="Click to disable NSFW and stop showing this toggle"><Chip label="Hide Toggle" color="error" clickable={true} onClick={() => {
              setShowLeavingNSFWModeDialog(true);
            }} /></Tooltip> : ""
          }
          {
            hasTypedTheKonamiCode ? 
              <FormGroup>
                <FormControlLabel control={<Switch checked={showNSFW} onChange={() => {
                  let newState = !showNSFW;
                  setShowNSFW(newState);
                  localStorage.setItem("showNSFW", newState ? "true" : "false")
                }} />} label="NSFW?" />
              </FormGroup>
            : ""
          }
          {
            !isAuth ? 
              <span>
                {/* <Button component={Link} to="/login" color="inherit">Login</Button> */}
              </span>
            :
              <span>
                <Button onClick={signUserOut} color="inherit">Log Out</Button>
              </span>
          }
        </Toolbar>
      </AppBar>
      <SwipeableDrawer
        onClose={handleDrawerClose}
        onOpen={handleDrawerOpen}
        open={open}
        disableSwipeToOpen={false}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          width: drawerWidth,
          flexShrink: 0,
          [`& .MuiDrawer-paper`]: { width: drawerWidth, boxSizing: 'border-box' },
        }}
      >
        <Divider />
        <Toolbar />
        <Box sx={{ overflow: 'auto' }}>
          <List>
            {navPages.map((navItem, index) => (
              <ListItem key={navItem.text} disablePadding>
                <ListItemButton component={Link} to={navItem.route} onClick={handleDrawerClose}>
                  <ListItemIcon>
                    {navItem.icon}
                  </ListItemIcon>
                  <ListItemText primary={navItem.text} />
                </ListItemButton>
              </ListItem>
            ))}
          </List>
          {/* <Divider /> */}
          {isAuth && (<div>
            <Divider />
            <List>
              <ListItem key="mgmt-title">
                <Typography>Management</Typography>
              </ListItem>
              {mgmtNavPages.map((navItem, index) => (
                <ListItem key={navItem.text} disablePadding>
                  <ListItemButton component={Link} to={navItem.route} onClick={handleDrawerClose}>
                    <ListItemIcon>
                      {navItem.icon}
                    </ListItemIcon>
                    <ListItemText primary={navItem.text} />
                  </ListItemButton>
                </ListItem>
              ))}
            </List>
          </div>)}
        </Box>
      </SwipeableDrawer>
    </Box>
  );
}
