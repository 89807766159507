import { Button, Checkbox, Chip, Container, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Fab, FormControlLabel, FormGroup, List, ListItem, ListItemText, Paper, TextField, Typography } from '@mui/material'
import { Box } from '@mui/system'
import React, { useEffect, useState } from 'react'
import DataTable, { TableColumn, ExpanderComponentProps } from 'react-data-table-component';
import MainBody from './MainBody'
import SortIcon from '@mui/icons-material/ArrowDownward'
import AddIcon from '@mui/icons-material/Add';

import { getDocs, collection, deleteDoc, doc, setDoc, query, where } from "firebase/firestore";
import { db } from '../firebase-config';
import EditLinks from '../components/EditLinks';

import { ArtistLinkItem, ArtistDataRow } from "../interfaces";
// import { Link } from 'react-router-dom';
import CreateArtist from './CreateArtist';
// import { Link } from 'react-router-dom';

const fabStyle = {
  position: 'absolute',
  bottom: 16,
  right: 16,
};

const deleteArtistHandler = async (artistId: string, cb: Function) => {
  const artistDoc = doc(db, "artists", artistId);
  await deleteDoc(artistDoc);
  // window.location.reload();
  cb();
};

const editArtistHandler = async (data: ArtistDataRow, cb: Function) => {
  const artistDoc = doc(db, "artists", data.id);
  // getDoc(artistDoc).then((e) => console.log(e))
  setDoc(artistDoc, data).then(() => {
    // window.location.reload();
    cb();
  });
};

const columns: TableColumn<any>[] = [
  {
    name: "Artist",
    selector: r => r.artistPrimaryName,
    sortable: true
  },
  {
    name: "ID",
    selector: r => r.id,
    sortable: false
  },
  {
    name: "Link",
    selector: r => r.artistPrimaryLink,
    sortable: false,
    cell: r => <Button href={r.artistPrimaryLink} target="_blank" rel="noreferrer" >Visit on the web</Button>
  }
];

interface CustomExpanderComponentProps extends ExpanderComponentProps<ArtistDataRow> {
  // currently, props that extend ExpanderComponentProps must be set to optional.
  isAuth?: boolean;
  runUpdate?: any; // doesn't like being Function
}

const ExpandedComponent: React.FC<CustomExpanderComponentProps> = ({ data, isAuth, runUpdate }) => {
  const [removeConfirmOpen, setRemoveConfirmOpen] = useState<boolean>(false);
  const [editOpen, setEditOpen] = useState<boolean>(false);
  const [artistPrimaryLink, setArtistPrimaryLink] = useState<string>(data.artistPrimaryLink);
  const [artistPrimaryName, setArtistPrimaryName] = useState<string>(data.artistPrimaryName);
  const [artistLinks, setArtistLinks] = useState<Array<ArtistLinkItem>>(data.artistLinks || []);
  const [editLinksOpen, setEditLinksOpen] = useState<boolean>(false);
  const [onlyShowIfNsfw, setOnlyShowIfNsfw] = useState<boolean>(data.onlyShowIfNsfw || false);

  const handleClickEditOpen = () => {
    setEditOpen(true);
  };

  const handleCloseEdit = () => {
    setEditOpen(false);
  };

  const handleCloseEditAndSave = (data: ArtistDataRow) => {
    editArtistHandler(data, runUpdate);
    handleCloseEdit();
  }

  const handleClickRemoveOpen = () => {
    setRemoveConfirmOpen(true);
  };

  const handleCloseRemove = () => {
    setRemoveConfirmOpen(false);
  };

  const handleCloseRemovePerformRemoval = (artistId: string) => {
    deleteArtistHandler(artistId, runUpdate);
    handleCloseRemove();
  };

  const handleUpdateArtistLinks = (newLinks: Array<ArtistLinkItem>) => {
    setArtistLinks(newLinks);
  };
  
  // return <pre>{JSON.stringify(data, null, 2)}</pre>;
  return (
    <div>
      <Container>
        <Box>
          {(data.artistLinks && data.artistLinks.length > 0) ? (
            <div>
              <Typography variant="body1" gutterBottom>{data.artistPrimaryName} around the web...</Typography>
              <Paper>
                <List>
                  {data.artistLinks.map((i: ArtistLinkItem, idx) => (
                    <ListItem key={`artist-link-item-${idx}`} button component={"a"} href={i.link} target="_blank" rel="noreferrer">
                      <ListItemText
                        primary={i.title}
                        secondary={i.subtitle || null}
                      />
                    </ListItem>
                  ))}
                </List>
              </Paper>
            </div>
          ) : (<div><Typography variant="body1" gutterBottom>Looks like this artist doesn't have any other links, but you can' totally find them using the "Visit on the Web" button above!</Typography></div>)}
        </Box>
        {isAuth && <div>
          <Typography variant="body1">Options for artist {data.artistPrimaryName}</Typography>
          <Box>
            <Button onClick={handleClickEditOpen}>Edit</Button>
            <Button onClick={handleClickRemoveOpen}>Remove</Button>
          </Box>
        </div>}
      </Container>
      <Dialog
        open={removeConfirmOpen}
        onClose={handleCloseRemove}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Confirm artist deletion?"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you wish to remove the artist {data.artistPrimaryName} from the list?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRemove}>Cancel</Button>
          <Button onClick={() => {handleCloseRemovePerformRemoval(data.id)}} autoFocus>
            Confirm
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={editOpen}
        onClose={handleCloseEdit}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Edit Artist"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Edit artist {data.artistPrimaryName}...
          </DialogContentText>
          <div style={{ marginBottom: "1em" }} />
          <Container>
            <Box
              component="form"
              // sx={{
              //   '& .MuiTextField-root': { m: 1, width: '50ch' },
              // }}
              noValidate
              autoComplete="off"
            >
              <div style={{ display: "flex", flexDirection: "column", gap: "2em" }}>
                <TextField
                  required
                  id="new-artist-primary-name"
                  label="Primary Artist Name"
                  defaultValue={data.artistPrimaryName}
                  placeholder='AstraBun'
                  onChange={(event) => {setArtistPrimaryName(event.target.value)}}
                />
                <TextField
                  required
                  id="new-artist-primary-link"
                  label="Primary Artist Link"
                  defaultValue={data.artistPrimaryLink}
                  placeholder='https://twitter.com/astra_bun'
                  onChange={(event) => {setArtistPrimaryLink(event.target.value)}}
                />
                <Box>
                  <Typography gutterBottom>Links:</Typography>
                  {artistLinks.map((link, idx) => (<Chip key={`artist-link-chip-${idx}`} label={link.title} clickable onClick={() => {window.open(link.link || "#", "_blank")}} />))}
                </Box>
                <Button onClick={() => {setEditLinksOpen(true)}}>Edit Links</Button>
                <EditLinks open={editLinksOpen} setOpen={setEditLinksOpen} links={artistLinks} updateLinks={handleUpdateArtistLinks} />
                <FormGroup>
                  <FormControlLabel control={<Checkbox defaultChecked={onlyShowIfNsfw} onChange={() => {setOnlyShowIfNsfw(!onlyShowIfNsfw)}} />} label="Only show if NSFW?" />
                  {onlyShowIfNsfw && <Typography variant="caption">This artist will be hidden unless the user enters NSFW mode.</Typography>}
                </FormGroup>
              </div>
            </Box>
          </Container>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseEdit}>Cancel</Button>
          <Button onClick={() => {
            let editedObj: ArtistDataRow = {
              id: data.id,
              artistPrimaryName: artistPrimaryName,
              artistPrimaryLink: artistPrimaryLink,
              onlyShowIfNsfw: onlyShowIfNsfw,
            };
            if(artistLinks.length > 0) {
              editedObj.artistLinks = artistLinks;
            };
            handleCloseEditAndSave(editedObj)}
          } autoFocus>
            Save
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
};

function Artists({ isAuth, showNSFW }: {isAuth: boolean, showNSFW: boolean}) {
  const [artists, setArtists] = useState<Array<any>>([]);
  const artistsCollectionRef = collection(db, "artists");
  const [pending, setPending] = useState<boolean>(true);
  const [addOpen, setAddOpen] = useState<boolean>(false);

  const runUpdate = () => {
    const getArtists = async () => {
      if(showNSFW){
        const data = await getDocs(artistsCollectionRef);
        // console.log(data);
        setArtists(data.docs.map((doc) => ({ ...doc.data(), id: doc.id})));
      } else { // filter out docs where onlyShowIfNsfw is true
        const q = query(artistsCollectionRef, where("onlyShowIfNsfw", "==", false));
        const querySnapshot = await getDocs(q);
        setArtists(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id})));
      }
    };
    getArtists();
    setPending(false);
  };

  useEffect(() => {
    // const getArtists = async () => {
    //   const data = await getDocs(artistsCollectionRef);
    //   // console.log(data);
    //   setArtists(data.docs.map((doc) => ({ ...doc.data(), id: doc.id})));
    // };
    // getArtists();
    // setPending(false);
    runUpdate();
  }, [deleteArtistHandler, showNSFW]); // eslint-disable-line react-hooks/exhaustive-deps
  return (
    <MainBody title={"Manage Artists"} showNSFW={showNSFW}>
      {/* <Box>
        <Typography variant="h1">Artists</Typography>
      </Box> */}
      <Container>
        <DataTable
          title="Manage Artists (who have drawn me)"
          columns={columns}
          data={artists}
          sortIcon={<SortIcon />}
          pagination
          selectableRows={false}
          expandOnRowClicked
          // expandableRows={isAuth}
          expandableRows={true}
          expandableRowsHideExpander
          expandableRowsComponent={ExpandedComponent}
          expandableRowsComponentProps={{ isAuth: isAuth, runUpdate: runUpdate }}
          progressPending={pending}
        />
      </Container>
      {
        isAuth && 
        // <Fab color="primary" aria-label="add" sx={{ ...fabStyle }} component={Link} to="/createartist">
        <Fab color="primary" aria-label="add" sx={{ ...fabStyle }} onClick={() => {setAddOpen(true)}}>
          <AddIcon />
        </Fab>
      }
      <Dialog open={addOpen} onClose={() => {setAddOpen(false)}}>
        <div style={{ padding: "1em" }}>
          <CreateArtist isAuth={isAuth} onAddCallback={() => {setAddOpen(false); runUpdate()}} />
        </div>
      </Dialog>
    </MainBody>
  )
}

export default Artists