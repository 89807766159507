import { uuidv4 } from '@firebase/util';
import { LoadingButton } from '@mui/lab';
import { Box, Typography, TextField, FormGroup, FormControlLabel, Checkbox, Button, Chip } from '@mui/material';
import { collection, setDoc, doc, Timestamp } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import React, { useState } from 'react'
import EditAssociatedArtists from './EditAssociatedArtists';
import EditAssociatedCharacters from './EditAssociatedCharacters';
import { db, storage } from '../firebase-config';
import { ArtworkItem } from '../interfaces';
import MainBody from '../pages/MainBody';
import FileUploadIcon from "@mui/icons-material/FileUpload";
import dayjs, { Dayjs } from 'dayjs';
import { DateTimePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';

function EditArtwork({ data, isAuth, onEditCallback, showNSFW }: { data: any, isAuth: boolean, onEditCallback: Function, showNSFW: boolean }) {
  const [features, setFeatures] = useState<Array<any>>(data.features);
  const [artists, setArtists] = useState<Array<any>>(data.artists);
  const [title, setTitle] = useState<string>(data.title);
  const [img, setImg] = useState<string>(data.img);
  const [published, setPublished] = useState<boolean>(data.published);
  const [nsfw, setNsfw] = useState<boolean>(data.nsfw);
  const [customRender, setCustomRender] = useState<string | null | undefined>(data.customRender || null); // eslint-disable-line @typescript-eslint/no-unused-vars
  const [id, setId] = useState<any>(data.id); // eslint-disable-line @typescript-eslint/no-unused-vars

  const [editArtistsOpen, setEditArtistsOpen] = useState<boolean>(false);
  const [editFeaturesOpen, setEditFeaturesOpen] = useState<boolean>(false);

  const [selectedImgFile, setSelectedImgFile] = useState<any>();
  const [IsImgFilePicked, setIsImgFilePicked] = useState<boolean>(false);
  const [isUploadClicked, setIsUploadClicked] = useState<boolean>(false);
  const [isFileUploadDone, setIsFileUploadDone] = useState<boolean>(false);
  const [timestamp, setTimestamp] = useState<Dayjs>(dayjs((data.timestamp as Timestamp).seconds * 1000) || dayjs());

  const changeImgHandler = (event: any) => {
    setSelectedImgFile(event.target.files[0]);
    setIsFileUploadDone(false);
    setIsImgFilePicked(true);
  };

  const handleImgSubmission = () => {
    const doUpload = () => {
      // disable upload button while upload happening
      setIsUploadClicked(true);
      let uuidprefix = uuidv4();
      let lastDot = selectedImgFile.name.lastIndexOf('.');
      // let fileName = selectedImgFile.name.substring(0, lastDot);
      let ext = selectedImgFile.name.substring(lastDot + 1);

      let uploadObj = ref(
        storage,
        // `art/${uuidprefix}_${selectedImgFile.name}`
        `art/${uuidprefix}.${ext}`
      );
      uploadBytes(uploadObj, selectedImgFile).then((snapshot) => {
        // console.log('Uploaded a blob or file!');
        getDownloadURL(uploadObj).then((res) => {
          setImg(res);
          setIsFileUploadDone(true);
          setIsUploadClicked(false);
        });
      });
    };
    doUpload();
  };

  const artworkCollectionRef = collection(db, "art");
  const editArtwork = async () => {
    let newDoc: ArtworkItem = {
      features: features,
      artists: artists,
      title: title,
      img: img,
      published: published,
      nsfw: nsfw,
      timestamp: Timestamp.fromMillis(timestamp.unix() * 1000),
    }
    if (customRender !== null && customRender !== undefined) {
      newDoc.customRender = customRender;
    }
    // await addDoc(artworkCollectionRef, newDoc);
    await setDoc(doc(artworkCollectionRef, `${id}`), newDoc);
    onEditCallback();
  };

  return (
    <MainBody>
      <Box sx={{ textAlign: "center" }}>
        <Typography variant="h3">Create Artwork</Typography>
        <Typography variant="caption">Add a new artwork to the database.</Typography>
        <Box
          sx={{
            // textAlign: "center"
            alignItems: "center",
            display: "flex",
            flexDirection: "column",
            gap: "1em",
          }}
        >
          <Box
            component="form"
            // sx={{
            //   '& .MuiTextField-root': { m: 1, width: '50ch' },
            // }}
            noValidate
            autoComplete="off"
          >
            <div style={{ display: "flex", flexDirection: "column", gap: "1em" }}>
              <TextField
                required
                id="new-artwork-title"
                label="Title"
                defaultValue={title}
                placeholder='Cool art!'
                onChange={(event) => { setTitle(event.target.value) }}
              />
              {img.length > 0 ? ((customRender !== null && customRender === "mp4") ? <>
                <video autoPlay loop muted style={{ height: "200px", width: "auto", objectFit: "scale-down" }}>
                  <source src={img} type="video/mp4" />
                  Your browser does not support the video tag.
                </video>
              </> : <img src={img} alt={"Preview"} style={{ height: "200px", width: "auto", objectFit: "scale-down" }} />) : ""}
              <input type="file" name="file" onChange={changeImgHandler} />
              <LoadingButton
                loading={isUploadClicked}
                disabled={!IsImgFilePicked || isFileUploadDone}
                loadingPosition="start"
                startIcon={<FileUploadIcon />}
                // variant="outlined"
                onClick={handleImgSubmission}
              >
                Upload
              </LoadingButton>
              {artists.map((a, idx) => <Chip key={`a-${idx}`} label={a} />)}
              <Button onClick={() => { setEditArtistsOpen(true) }}>Edit Artist(s)</Button>
              {features.map((a, idx) => <Chip key={`a-${idx}`} label={a} />)}
              <Button onClick={() => { setEditFeaturesOpen(true) }}>Edit Characters(s)</Button>
              <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked={nsfw} onChange={() => { setNsfw(!nsfw) }} />} label="NSFW?" />
                {nsfw && <Typography variant="caption">This art will be hidden unless the user enters NSFW mode.</Typography>}
              </FormGroup>
              <FormGroup>
                <FormControlLabel control={<Checkbox defaultChecked={published} onChange={() => { setPublished(!published) }} />} label="Published?" />
                {!published && <Typography variant="caption">This art will be hidden for visitors.</Typography>}
              </FormGroup>
              <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DateTimePicker
                    label="Artwork Timestamp"
                    value={timestamp}
                    onChange={(n: any) => { setTimestamp(n) }}
                    renderInput={(params: any) => <TextField {...params} />}
                    disableFuture
                  />
                </LocalizationProvider>
                <Button onClick={() => { setTimestamp(dayjs()) }} variant="outlined">Now</Button>
              </Box>
            </div>
            <Button onClick={editArtwork} disabled={img.length === 0 || artists.length === 0 || features.length === 0 || title.length === 0}>Create Artwork</Button>
          </Box>
        </Box>
      </Box>
      <EditAssociatedArtists open={editArtistsOpen} setOpen={setEditArtistsOpen} artists={artists} updateArtists={setArtists} showNSFW={showNSFW} />
      <EditAssociatedCharacters open={editFeaturesOpen} setOpen={setEditFeaturesOpen} showNSFW={showNSFW} characters={features} updateCharacters={setFeatures} />
    </MainBody>
  )
}

export default EditArtwork