import React, { useEffect, useState } from 'react'
import { ArtistDataRow, CommissionDataRow } from '../interfaces';
import { Accordion, AccordionDetails, AccordionSummary, Box, Button, Chip, Paper, TableRow, Typography, lighten, darken, useTheme } from '@mui/material';
import { getDoc } from 'firebase/firestore';
import dayjs from 'dayjs';
import RelativeTime from "dayjs/plugin/relativeTime";
import Duration from "dayjs/plugin/duration";
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Chrono } from "react-chrono";
import { Link } from 'react-router-dom';
import { humanizeTimeDifference } from '../helpers/humanizeTimeDifference';
dayjs.extend(RelativeTime);
dayjs.extend(Duration);

function HighLevelCommissionTableData({ commission, commIdx }: { commission: CommissionDataRow, commIdx: number }) {
  const [commArtists, setCommArtists] = useState<Array<ArtistDataRow>>([]);
  const [
    updates,
    // setUpdates
  ] = useState<Array<any>>([]);

  const getArtistsForComm = () => {
    let artists: Array<ArtistDataRow> = [];
    commission.artists.forEach((artist) => {
      getDoc(artist).then((snap) => {
        let ad: ArtistDataRow = { id: snap.id, ...(snap.data() as any) } as ArtistDataRow;
        artists.push(ad);
      }).finally(() => {
        setCommArtists(artists);
      })
    });
  };
  useEffect(() => {
    getArtistsForComm();
  }, []) // eslint-disable-line react-hooks/exhaustive-deps

  // const getUpdatesForComm = () => {
  //   let updates: Array<any> = [];
  //   if (commission) {
  //     if (commission.id) {
  //       const commUpdatesCollectionRef = collection(db, "commissionHistoryItems");
  //       const relatedUpdatesQuery = query(commUpdatesCollectionRef, where("commission", "==", doc(collection(db, "commissions"), commission.id)))
  //       getDocs(relatedUpdatesQuery).then((snapshot) => {
  //         let u = snapshot.forEach((doc) => updates.push({ note: doc.data().description, ts: doc.data().ts }));
  //       }).finally(() => {
  //         // setUpdates(updates);
  //         updates = updates.sort((a, b) => b.ts.seconds - a.ts.seconds);
  //         return updates;
  //       })
  //     }
  //   }
  //   return updates;
  // };
  // useEffect(() => {
  //   // let u = getUpdatesForComm();
  //   // setUpdates(u);
  // }, [commission])

  const theme = useTheme();

  return (
    <TableRow key={commIdx}>
      <Box
        sx={{ margin: "1rem" }}
      >
        <Paper elevation={2} sx={{ backgroundColor: commission.done ? darken(theme.palette.success.dark, 0.5) : undefined }}>
          <Box sx={{ margin: "1rem", padding: "1rem", display: "flex", flexDirection: "column", gap: 1 }}>
            <Box sx={{ display: "flex", flexDirection: "row", gap: 1 }}>
              <Typography variant="h4">{commission.commission_name}</Typography>
              {commission.nsfw === true ? <><Chip label="NSFW" color="error" /></> : <></>}
            </Box>
            <Typography variant="caption">by {commArtists.map(a => a.artistPrimaryName).join(" and ")}</Typography>
            {commission.dateInit && <Typography variant="body1">Initialized: {dayjs(commission.dateInit.seconds * 1000).format()}</Typography>}
            {commission.datePaid && <Typography variant="body1">Date Paid: {dayjs(commission.datePaid.seconds * 1000).format()}</Typography>}
            {commission.dateStarted && <Typography variant="body1">Date Started: {dayjs(commission.dateStarted.seconds * 1000).format()}</Typography>}
            {commission.dateDelivered && <Typography variant="body1">Date Delivered: {dayjs(commission.dateDelivered.seconds * 1000).format()}</Typography>}
            {commission.dateDelivered && <Typography variant="body1">Delivered after {humanizeTimeDifference(commission.dateInit.seconds * 1000, commission.dateDelivered.seconds * 1000)}</Typography>}
            {commission.done === false && <Typography variant="body1">Waiting for {humanizeTimeDifference(commission.dateInit.seconds * 1000, dayjs().unix() * 1000)}{commission.dateDelivered && ` (NOTE: Delivery date is set but commission is not marked "DONE".)`}</Typography>}
            <div style={{ marginBottom: "1rem" }} />
            <Button variant={commission.done ? 'contained' : 'outlined'} color={commission.done ? "secondary" : "info"} component={Link} to={`/commissions/log/${commission.id}`}>View Commission Log</Button>
            {updates.length > 0 && <>
              <Accordion>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`${commIdx}-updates-content`}
                  id={`${commIdx}-updates-header`}
                >
                  <Typography>Progress Updates/Notes ({updates.length})</Typography>
                </AccordionSummary>
                <AccordionDetails>
                  {/* <Typography variant="h5">Progress Updates/Notes:</Typography> */}
                  {/* {updates.map((update, updateIdx) => {
                    return (
                      <div key={updateIdx} style={{ marginBottom: "1rem" }}>
                        <Typography variant="body1" sx={{ fontWeight: "800" }}>{dayjs(update.ts.seconds * 1000).format()}: </Typography>
                        <Typography variant="body1" sx={{}}>{update.note}</Typography>
                      </div>
                    )
                  })} */}
                  <Chrono
                    // mode="VERTICAL"
                    mode="VERTICAL_ALTERNATING"
                    disableClickOnCircle
                    hideControls
                    disableNavOnKey
                    disableAutoScrollOnClick
                    theme={{
                      primary: theme.palette.primary.main,
                      secondary: theme.palette.secondary.main,
                      cardBgColor: lighten(theme.palette.background.paper, 1),
                      titleColor: theme.palette.text.primary,
                      titleColorActive: theme.palette.getContrastText(theme.palette.secondary.main),
                    }}
                    items={updates.map((update, updateIdx) => {
                      let formatted = {
                        // title: "Commission Update",
                        // title: `${dayjs(update.ts.seconds * 1000).format()}`,
                        title: `${commission.done ? `${humanizeTimeDifference(update.ts.seconds * 1000, commission.dateInit.seconds * 1000)} ${update.ts.seconds < commission.dateInit.seconds ? "before" : "after"} start of comm` : dayjs(update.ts.seconds * 1000).fromNow()}`,
                        cardTitle: `Update ${dayjs(update.ts.seconds * 1000).format()}`,
                        // url: "http://www.history.com",
                        cardSubtitle: `Note added ${commission.done ? `${humanizeTimeDifference(update.ts.seconds * 1000, commission.dateInit.seconds * 1000)} ${update.ts.seconds < commission.dateInit.seconds ? "before" : "after"} start of comm` : dayjs(update.ts.seconds * 1000).fromNow()}`,
                        cardDetailedText: update.note,
                        // media: {
                        //   type: "IMAGE",
                        //   source: {
                        //     url: "http://someurl/image.jpg"
                        //   }
                        // }
                      };
                      return (
                        formatted
                      )
                    }
                    )} />
                </AccordionDetails>
              </Accordion>
            </>}

          </Box>
        </Paper>
      </Box>
    </TableRow>
  )
}

export default HighLevelCommissionTableData