import React, { useEffect, useState } from 'react'
import MainBody from './MainBody'
import { collection, getDocs, query, where } from 'firebase/firestore';
import { db } from '../firebase-config';
import { TableContainer, Paper, Table, TableHead, TableCell, TableRow, TableBody } from '@mui/material';
import { CommissionDataRow } from '../interfaces';
import HighLevelCommissionTableData from '../components/HighLevelCommissionTableData';

function Commissions({ isAuth, showNSFW, showDone }: { isAuth: boolean, showNSFW: boolean, showDone?: boolean }) {
  const commissionsCollectionRef = collection(db, "commissions");
  const [commissions, setCommissions] = useState<Array<CommissionDataRow>>([]);

  const runUpdate = () => {
    const getCharacters = async () => {
      if (true) { // originall isAuth
        // const data = await getDocs(commissionsCollectionRef);
        const queryForComms = query(commissionsCollectionRef, where("done", "!=", true), where("hide", "==", false));
        const queryForAllComms = query(commissionsCollectionRef, where("hide", "==", false));
        const data = await getDocs(showDone ? queryForAllComms : queryForComms);
        // console.log(data);
        let c = (data.docs.map((doc) => ({ ...doc.data(), id: doc.id })) as Array<CommissionDataRow>);
        // if(!showNSFW){
        //   c = c.filter((i) => !(i.nsfw))
        // }
        c = c.sort((a, b) => a.dateInit.seconds - b.dateInit.seconds);
        setCommissions(c);
      } else { // filter out docs where onlyShowIfNsfw is true
        // const q = query(commissions, where("published", "==", true));
        // const querySnapshot = await getDocs(q);
        // setCharacters(querySnapshot.docs.map((doc) => ({ ...doc.data(), id: doc.id })));
      }
    };
    getCharacters();
  };

  useEffect(() => {
    runUpdate()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <MainBody
      title={"Commission History"}
    >
      {commissions.length === 0 ? <>
        <p>Not waiting on any commissions at the moment (or am I? oooOoooOoooo~)</p>
      </> : <>
        <TableContainer component={Paper}>
          <Table
            // sx={{ minWidth: 650 }}
            aria-label="commission-data"
          >
            <TableHead>
              <TableRow>
                <TableCell>Commission {showDone ? "History" : "Waitlist"}</TableCell>
                {/* <TableCell
              // align="right"
              >
                Value
              </TableCell> */}
              </TableRow>
            </TableHead>
            <TableBody>
              {commissions.filter((i) => {
                if (showNSFW) {
                  return true
                } else {
                  return !i.nsfw
                }
              }).map((commission: CommissionDataRow, commIdx: number) => {
                return (
                  <HighLevelCommissionTableData key={commIdx} commission={commission} commIdx={commIdx} />
                )
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </>}
    </MainBody>
  )
}

export default Commissions